import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Body } from '../../../../components/Body';
import MaterialTable, { Column } from 'material-table';
import Formatter from '../../../../services/Formatter';
import useStore from '../../../../services/hooks/useStore';
//import { useHistory } from 'react-router-dom';
import { newConfig } from '../../../../components/Tabela';
import { aqReports, apiMain } from '../../../../services/utils';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Flex } from '../../../../components/NavBar/styles';
import { Button, makeStyles } from '@material-ui/core';
import { ReportButton } from '../styles';
import Select from '../../../../components/Select';
import { Link } from 'react-router-dom';

type RelLista = {
  data: string;
  documento: string;
  categoria: string;
  quantidade: number;
  valor_total: number;
  valor_unit: number;
};

type RelCompras = {
  data: string;
  lista: Array<RelLista>;
  qtd_total: number;
  total: number;
};

const useInputStyles = makeStyles({
  root: {
    width: '10vw',
    verticalAlign: 'middle',
    fontSize: '14px',
    border: 'solid 1px #303030',
    borderRadius: '5px',
    paddingLeft: '5px',
    margin: '0 15px',
  },
});

const ExtratoComprasClientes: React.FC = () => {
  const store = useStore();

  const [tableData, setTableData] = useState<RelLista[]>([]);
  // @ts-ignore
  const [relData, setRelData] = useState<RelCompras[]>([]);

  const [filtroApply, setFiltroApply] = useState(true);
  const currentDate = new Date();
  const initialDate = new Date();
  initialDate.setDate(1);
  const [filtroDate, setFiltroDate] = useState({
    dataInicial: {
      value: initialDate as MaterialUiPickersDate,
      valueFormatted: Formatter.getData(Formatter.dateToString(initialDate)),
    },
    dataFinal: {
      value: currentDate as MaterialUiPickersDate,
      valueFormatted: Formatter.getData(Formatter.dateToString(currentDate)),
    },
  });
  const [cliente, setCliente] = useState({
    label: '',
    value: '',
    id: '',
  });

  const columns: Column<RelLista>[] = [
    {
      field: 'data',
      title: 'Data',
      type: 'string',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'documento',
      title: 'N. Documento',
      type: 'string',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'categoria',
      title: 'Categoria',
      type: 'string',
      headerStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'descricao',
      title: 'Descrição',
      type: 'string',
      headerStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'biomassa',
      title: 'Biomassa (KG)',
      type: 'numeric',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      render: (rowData) => {
        return Formatter.formatNumber(0, rowData.quantidade);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'valor_unit',
      title: 'Vr. Unit. (R$)',
      type: 'numeric',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.valor_unit);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'valor_total',
      title: 'Valor (R$)',
      type: 'numeric',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.valor_total);
      },
      ...(true && ({ width: 100 } as object)),
    },
  ];

  const inputClasses = useInputStyles();

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Relatório Financeiro'), []);

  useEffect(() => {
    async function syncTable() {
      aqReports
        .get('/financeiro/' + store.propriedade?.uuid + '/extrato-compras-clientes', {
          params: {
            data_inicio: filtroDate.dataInicial.valueFormatted,
            data_fim: filtroDate.dataFinal.valueFormatted,
            cliente: cliente.id !== '' ? cliente.id : undefined,
          },
        })
        .then((res) => {
          let tableList: RelLista[] = [];
          let relList: RelCompras[] = [];
          const results = res.data.results;
          results.forEach((element: any) => {
            element.results.forEach((item: RelLista) => {
              tableList.push(item);
            });
          });
          setTableData(tableList);
          setRelData(relList);
        });

      store.clientes.populate();
    }

    if (filtroApply && store.propriedade) {
      syncTable();
      setFiltroApply(false);
    }

    // eslint-disable-next-line
  }, [store.propriedade, filtroApply]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getPDF() {
    try {
      const pdf = await apiMain.get(`/gerador/pdf/financeiro/${store.propriedade?.id}/extrato-compras-clientes/`, {
        params: {
          data_inicio: filtroDate.dataInicial.valueFormatted,
          data_fim: filtroDate.dataFinal.valueFormatted,
          authori: store.token,
          cliente: cliente.id !== '' ? cliente.id : undefined,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getCSV() {
    try {
      const pdf = await apiMain.get(`/gerador/csv/financeiro/${store.propriedade?.id}/extrato-compras-clientes/`, {
        params: {
          data_inicio: filtroDate.dataInicial.valueFormatted,
          data_fim: filtroDate.dataFinal.valueFormatted,
          authori: store.token,
          cliente: cliente.id !== '' ? cliente.id : undefined,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
    }
  }

  return (
    <>
      <Body style={{ backgroundColor: 'white' }}>
        <Link
          to={'/financeiro/painel/relatorio-financeiro'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>
        <div style={{ fontWeight: 'bold', fontSize: '24px', height: '30px' }}> Extrato de Vendas por Clientes</div>
        <Flex
          style={{
            width: '100%',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={filtroDate.dataInicial.value}
            onChange={(date) => {
              setFiltroDate({
                ...filtroDate,
                dataInicial: {
                  value: date,
                  valueFormatted: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                },
              });
            }}
            InputProps={{ classes: inputClasses }}
          />
          <p>até</p>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={filtroDate.dataFinal.value}
            onChange={(date) => {
              setFiltroDate({
                ...filtroDate,
                dataFinal: {
                  value: date,
                  valueFormatted: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                },
              });
            }}
            InputProps={{ classes: inputClasses }}
          />

          {store.clientes!.arr && (
            <Select
              placeholder="Escolha um Cliente"
              value={cliente}
              options={store.clientes!.arr.map((programa) => {
                return { label: programa.nome, value: programa.uuid, id: programa.id };
              })}
              onChange={(e: any) => {
                setCliente(e);
              }}
              width={true}
            />
          )}

          <ReportButton style={{ marginLeft: '200px' }} onClick={() => setFiltroApply(true)}>
            Gerar Relatório
          </ReportButton>

          <Button className="blue" onClick={getPDF}>
            <Flex>
              <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                Exportar PDF
              </div>
              <img src={'/images/exportPDF.svg'} />
            </Flex>
          </Button>
          <Button className="blue" onClick={getCSV}>
            <Flex>
              <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                Exportar CSV
              </div>
              <img src={'/images/exportCSV.svg'} />
            </Flex>
          </Button>
        </Flex>
      </Body>
      <Body style={{ padding: '0px' }}>
        <MaterialTable
          columns={columns}
          data={tableData}
          options={{
            ...newConfig.options,
            maxBodyHeight: window.innerHeight - 300,
            minBodyHeight: window.innerHeight - 300,
            paging: true,
            sorting: false,
            pageSize: 10,
          }}
          localization={newConfig.localization}
          style={{ ...newConfig.style, backgroundColor: '#F5F6F8' }}
        />
      </Body>
    </>
  );
};

export default observer(ExtratoComprasClientes);
