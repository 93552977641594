import { observer, useLocalStore } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import { apiV2 } from '../../../services/utils';
import React, { useState, useEffect } from 'react';
import { Flex } from '../../../components/NavBar/styles';
import Display from '../../../components/Dashboard/Display';
import Formatter from '../../../services/Formatter';
import { Conta, SelectPropriedade, BarGraph } from './styles';
import ReactApexChart from 'react-apexcharts';
import useModal from '../../../services/hooks/useModal';
import FiltroAvancado from '../../../components/Modais/FiltroAvancado';
import { Divisor } from '../../../components/Modais/styles';
import Select from '../../../components/Select';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ApexOptions } from 'apexcharts';
import { ContainerBody } from '../../../components/Body';

const FormPainelDashboard: React.FC = () => {
  const state2 = useLocalStore(() => ({
    sync: false,
  }));

  const [rendimento, setRendimento] = useState({
    receitas: 0,
    despesas: 0,
    lucro_liquido: 0,
  });
  const [contas, setContas] = useState([
    {
      titulo: '',
      saldo_atual: 0,
      saldo_inicial: 0,
      id: 0,
    },
  ]);
  const [despesaTotal, setDespesaTotal] = useState(1000);
  const [despesas, setDespesas] = useState([
    {
      nome: '',
      valor: 0,
      id: 0,
    },
  ]);

  const [clientesOuProdutos, setClientesOuProdutos] = useState([
    {
      nome: '',
      valor: 0,
      id: 0,
    },
  ]);

  //const [vendas, setVendas] = useState<{ data: number[] }[]>();
  const store = useStore();
  const modalFiltroRendimentos = useModal();
  const modalFiltroDespesas = useModal();
  const modalFiltroVendas = useModal();

  const selectOptions: SelectRow[] = [
    { label: 'Mês Atual', value: 'mes_atual' },
    { label: 'Mês Anterior', value: 'mes_anterior' },
    { label: 'Trimestre', value: 'trimestre' },
    { label: 'Semestre', value: 'semestre' },
    { label: 'Ano Atual', value: 'ano_atual' },
    { label: 'Avançado', value: 'avancado' },
  ];
  const selectOptionsTipoVenda: SelectRow[] = [
    {
      label: 'Clientes',
      value: 'clientes',
    },
    {
      label: 'Produtos',
      value: 'produtos',
    },
  ];
  const [selectChoiceRendimento, setSelectChoiceRendimento] = useState(selectOptions[0].value);
  const [selectChoiceDespesas, setSelectChoiceDespesas] = useState(selectOptions[0].value);
  const [selectChoiceMesVenda, setSelectChoiceMesVenda] = useState(selectOptions[0].value);

  const [filtroAvancadoStatus, setFiltroStatus] = useState(false);
  const [filtrosRendimentos, setFiltrosRendimentos] = useState({
    contaId: '',
    dataInicial: {
      value: null as MaterialUiPickersDate,
      valueStr: '',
    },
    dataFinal: {
      value: null as MaterialUiPickersDate,
      valueStr: '',
    },
  });

  const filtroDespesasInicial = {
    contaId: '',
    dataInicial: {
      value: null as MaterialUiPickersDate,
      valueStr: '',
    },
    dataFinal: {
      value: null as MaterialUiPickersDate,
      valueStr: '',
    },
  };
  const [filtrosDespesas, setFiltrosDespesas] = useState(filtroDespesasInicial);

  const filtroVendasInicial = {
    tipo: 'clientes' as string,
    dataInicial: {
      value: null as MaterialUiPickersDate,
      valueStr: '',
    },
    dataFinal: {
      value: null as MaterialUiPickersDate,
      valueStr: '',
    },
  };
  const [filtrosVendas, setFiltrosVendas] = useState(filtroVendasInicial);
  const [vendasLabel, setVendasLabel] = useState('Clientes');

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Painel Financeiro'), []);

  async function searchLucros() {
    const temFiltroDeDatas = Boolean(filtrosRendimentos.dataInicial.valueStr && filtrosRendimentos.dataFinal.valueStr);
    await apiV2
      .get('/movimentacao/lucros/', {
        params: {
          prop: store.propriedade?.id,
          periodo: temFiltroDeDatas ? 'avancado' : selectChoiceRendimento,
          conta: filtrosRendimentos.contaId || undefined,
          data_inicio: filtrosRendimentos.dataInicial.valueStr || undefined,
          data_fim: filtrosRendimentos.dataFinal.valueStr || undefined,
        },
      })
      .then((res) => {
        setRendimento(res.data);
      });
    state2.sync = true;
  }
  //requisicao lucros obtidos
  useEffect(() => {
    searchLucros();
    // eslint-disable-next-line
  }, [state2, store.propriedade, selectChoiceRendimento, filtroAvancadoStatus]);

  async function searchContas() {
    await apiV2
      .get('/movimentacao/contas/', {
        params: {
          prop: store.propriedade?.id,
        },
      })
      .then((res) => {
        setContas(res.data.results);
      });
    state2.sync = true;
  }
  //requisicao de contas
  useEffect(() => {
    searchContas();
  }, [state2, store.propriedade]);

  async function searchDespesas() {
    const filtro = selectChoiceDespesas === 'avancado' ? filtrosDespesas : filtroDespesasInicial;
    await apiV2
      .get('/movimentacao/despesas/', {
        params: {
          prop: store.propriedade?.id,
          periodo: selectChoiceDespesas,
          data_inicio: filtro.dataInicial.valueStr || undefined,
          data_fim: filtro.dataFinal.valueStr || undefined,
          conta: filtro.contaId || undefined,
        },
      })
      .then((res) => {
        const despesasTop5 = res.data.categorias.slice(0, 5);
        setDespesas(despesasTop5);
        setDespesaTotal(res.data.total);
        setChartProps({
          ...donutChartProps,
          labels: despesasTop5
            ? despesasTop5.map((despesa: any) => {
                const nome = despesa.nome.length > 12 ? `${despesa.nome.slice(0, 16)}...` : despesa.nome;
                return nome;
              })
            : [''],
          tooltip: {
            y: {
              formatter: function (val: any) {
                let porcentagem = (val / res.data.total) * 100;
                return 'R$ ' + Formatter.formatNumber(2, val) + ' ( ' + porcentagem.toFixed(2) + '%)';
              },
              style: {
                fontFamily: 'Roboto',
                fontSize: '10px',
              },
            },
          },
        });
      });
    state2.sync = true;
  }
  //requisicao despesas
  useEffect(() => {
    searchDespesas();
    // eslint-disable-next-line
  }, [state2, store.propriedade, selectChoiceDespesas]);

  async function searchVendas() {
    const filtros = selectChoiceMesVenda === 'avancado' ? filtrosVendas : filtroVendasInicial;
    if (filtros.tipo === 'clientes') {
      await apiV2
        .get('/movimentacao/vendas-cliente/', {
          params: {
            prop: store.propriedade?.id,
            periodo: selectChoiceMesVenda,
            data_inicio: filtros.dataInicial.valueStr || undefined,
            data_fim: filtros.dataFinal.valueStr || undefined,
          },
        })
        .then((res) => {
          const clientesTop5 = res.data.slice(0, 6);
          setClientesOuProdutos(clientesTop5);
          setColummChartProps({
            ...colummChartProps,
            xaxis: {
              categories: clientesTop5
                ? clientesTop5.map((cliente: any) => {
                    return cliente?.nome?.toUpperCase();
                  })
                : [''],
            },
          });
        });
      state2.sync = true;
    } else if (filtros.tipo === 'produtos') {
      await apiV2
        .get('/movimentacao/produtos-cliente/', {
          params: {
            prop: store.propriedade?.id,
            periodo: selectChoiceMesVenda,
            data_inicio: filtros.dataInicial.valueStr || undefined,
            data_fim: filtros.dataFinal.valueStr || undefined,
          },
        })
        .then((res) => {
          const produtosTop5 = res.data.slice(0, 6);
          setClientesOuProdutos(produtosTop5);
          setColummChartProps({
            ...colummChartProps,
            xaxis: {
              categories: produtosTop5
                ? produtosTop5.map((produto: any) => {
                    return produto?.nome?.toUpperCase();
                  })
                : [''],
            },
          });
        });
      state2.sync = true;
    }
  }
  useEffect(() => {
    searchVendas();
  }, [state2, store.propriedade, selectChoiceMesVenda]);

  //Grafico Donut
  const [donutChartProps, setChartProps] = useState({
    chart: {
      type: 'donut',
    },
    labels: [''],
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    colors: ['#e42d0d', '#faec23', '#8b8888', '#4df2f8', '#f008f0', '#74ecc8'],
    legend: {
      position: 'left',
      horizontalAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '10px',
      floating: false,
      width: '10vw',
      height: '120vh',
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          let porcentagem = (despesaTotal / val) * 100;
          return 'R$ ' + Formatter.formatNumber(2, val) + ' ( ' + porcentagem.toFixed(2) + '%)';
        },
        style: {
          fontFamily: 'Roboto',
          fontSize: '10px',
        },
      },
    },
  });
  // grafico Distribuido Exemple Teste
  const [colummChartProps, setColummChartProps] = useState<ApexOptions>({
    chart: {
      type: 'bar',
      height: 350,
    },

    labels: [''],
    colors: ['#FEB019', '#465A65', '#00E396', '#FE196B', '#783C00', '#707070'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '45%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          fontSize: '10px',
        },
        show: true,
        trim: true,
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return 'R$ ' + Formatter.formatNumber(2, val);
        },
      },
    },
    legend: {
      show: false,
    },
  });

  const SelectRendimento = (
    <SelectPropriedade
      placeholder={
        selectChoiceRendimento
          ? selectOptions.find((element) => element.value === selectChoiceRendimento)?.label
          : 'Selecionar'
      }
      value={selectChoiceRendimento}
      options={selectOptions.slice(0, 5)}
      classNamePrefix="navSel"
      onChange={(e: any) => {
        setSelectChoiceRendimento(e.value);
        setFiltrosRendimentos({
          ...filtrosRendimentos,
          dataInicial: {
            value: null as MaterialUiPickersDate,
            valueStr: '',
          },
          dataFinal: {
            value: null as MaterialUiPickersDate,
            valueStr: '',
          },
        });
      }}
      maxMenuHeight={200}
    />
  );

  const SelectDespesas = (
    <SelectPropriedade
      placeholder={
        selectChoiceDespesas
          ? selectOptions.find((element) => element.value === selectChoiceDespesas)?.label
          : 'Selecionar'
      }
      value={selectChoiceDespesas}
      options={selectOptions.slice(0, 5)}
      classNamePrefix="navSel"
      onChange={(e: any) => setSelectChoiceDespesas(e.value)}
      maxMenuHeight={200}
    />
  );

  //select mes venda
  const SelectMesVenda = (
    <SelectPropriedade
      placeholder={
        selectChoiceMesVenda
          ? selectOptions.find((element) => element.value === selectChoiceMesVenda)?.label
          : 'Selecionar'
      }
      value={selectChoiceMesVenda}
      options={selectOptions.slice(0, 5)}
      classNamePrefix="navSel"
      onChange={(e: any) => setSelectChoiceMesVenda(e.value)}
      maxMenuHeight={200}
    />
  );

  function getTimeSpam() {
    const monthNames = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    const currentMonth = new Date().getMonth();
    if (selectChoiceRendimento === selectOptions[0].value) {
      // mes atual
      return monthNames[currentMonth];
    } else if (selectChoiceRendimento === selectOptions[1].value) {
      // mes anterior
      return currentMonth > 0 ? monthNames[currentMonth - 1] : monthNames[11];
    } else if (selectChoiceRendimento === selectOptions[5].value) {
      // avancado
      let dateSpamFormat: string = '';
      if (filtrosRendimentos.dataInicial.valueStr) {
        dateSpamFormat = dateSpamFormat.concat('Desde ', filtrosRendimentos.dataInicial.valueStr);
        if (filtrosRendimentos.dataFinal.valueStr) {
          dateSpamFormat = dateSpamFormat.concat(' até ', filtrosRendimentos.dataFinal.valueStr);
        }
        return dateSpamFormat;
      }
      dateSpamFormat = dateSpamFormat.concat('Até ', filtrosRendimentos.dataFinal.valueStr);
      return dateSpamFormat;
    }

    const currentOption = selectOptions.find((element) => element.value === selectChoiceRendimento);

    return currentOption?.label;
  }

  function filterSync() {
    if (filtrosRendimentos.dataInicial.valueStr || filtrosRendimentos.dataFinal.valueStr) {
      setSelectChoiceRendimento(selectOptions[5].value); // avançado
    }
    setFiltroStatus(!filtroAvancadoStatus);
  }

  function filterClear() {
    if (selectChoiceRendimento === selectOptions[5].value) {
      setSelectChoiceRendimento(selectOptions[0].value); // mes atual
    }
    setFiltrosRendimentos({
      contaId: '',
      dataInicial: {
        value: null as MaterialUiPickersDate,
        valueStr: '',
      },
      dataFinal: {
        value: null as MaterialUiPickersDate,
        valueStr: '',
      },
    });
    setFiltroStatus(!filtroAvancadoStatus);
  }

  function limparFiltrosDespesas() {
    setFiltrosDespesas(filtroDespesasInicial);
    setSelectChoiceDespesas(selectOptions[0].value);
  }

  function limparFiltrosVendas() {
    setFiltrosVendas(filtroVendasInicial);
    setSelectChoiceMesVenda(selectOptions[0].value);
  }

  return (
    <>
      <ContainerBody>
        <Flex style={{ flexWrap: 'wrap', gap: '10px', rowGap: '20px', marginBottom: '150px' }}>
          <Display
            style={{ height: '360px', width: '425px', fontSize: '14px' }}
            overflowY
            title="Lucros ou Prejuízos"
            Select1={SelectRendimento}
            filter={modalFiltroRendimentos.open}
          >
            <FiltroAvancado
              open={modalFiltroRendimentos.state}
              handleClose={modalFiltroRendimentos.close}
              onConfirm={filterSync}
              onClear={filterClear}
            >
              <Divisor>
                <p> Conta </p>
              </Divisor>
              <div style={{ width: '90%' }}>
                <Select
                  value={filtrosRendimentos.contaId as any}
                  placeholder={
                    filtrosRendimentos.contaId
                      ? contas.find((conta) => {
                          return conta.id === parseInt(filtrosRendimentos.contaId);
                        })?.titulo
                      : 'Selecione uma conta'
                  }
                  options={contas.map((conta) => {
                    return {
                      label: conta.titulo,
                      value: conta.id,
                    };
                  })}
                  onChange={(e: any) => {
                    setFiltrosRendimentos({
                      ...filtrosRendimentos,
                      contaId: e.value,
                    });
                  }}
                />
              </div>
              <Divisor>
                <p>Período da Última Atualização</p>
              </Divisor>
              <div style={{ width: '90%' }}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Início"
                  value={filtrosRendimentos.dataInicial.value}
                  onChange={(date) => {
                    setFiltrosRendimentos({
                      ...filtrosRendimentos,
                      dataInicial: {
                        value: date,
                        valueStr: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                      },
                    });
                  }}
                />
              </div>
              <div style={{ width: '90%' }}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Fim"
                  value={filtrosRendimentos.dataFinal.value}
                  onChange={(date) => {
                    setFiltrosRendimentos({
                      ...filtrosRendimentos,
                      dataFinal: {
                        value: date,
                        valueStr: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                      },
                    });
                  }}
                />
              </div>
            </FiltroAvancado>
            <div style={{ height: '10vh' }}>
              <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                R$ {Formatter.formatNumber(2, rendimento.lucro_liquido)}
              </div>
              <div style={{ fontSize: '16px' }}>Lucro Líquido / {getTimeSpam()}</div>
              <div style={{ fontSize: '16px' }}>
                {filtrosRendimentos.contaId
                  ? contas.find((conta) => {
                      return conta.id === parseInt(filtrosRendimentos.contaId);
                    })?.titulo
                  : ''}
              </div>
            </div>
            <Flex style={{ height: '10vh' }}>
              <div style={{ width: '10vw' }}>
                <div style={{ fontSize: '20px', width: '150px', fontWeight: 'bold' }}>
                  R$ {Formatter.formatNumber(2, rendimento.receitas)}
                </div>
                <div style={{ fontSize: '16px' }}>Receitas</div>
              </div>
              <div>
                <BarGraph
                  color="#04D972"
                  scale={
                    (rendimento.receitas === 0
                      ? 0.01
                      : rendimento.receitas / (rendimento.receitas + rendimento.despesas)) * 0.7
                  }
                />
              </div>
            </Flex>
            <Flex style={{ height: '10vh' }}>
              <div style={{ width: '10vw' }}>
                <div style={{ fontSize: '20px', width: '150px', fontWeight: 'bold' }}>
                  R$ {Formatter.formatNumber(2, rendimento.despesas)}
                </div>
                <div style={{ fontSize: '16px' }}>Despesas</div>
              </div>
              <div>
                <BarGraph
                  color="#D9042A"
                  scale={
                    (rendimento.despesas === 0
                      ? 0.01
                      : rendimento.despesas / (rendimento.receitas + rendimento.despesas)) * 0.7
                  }
                />
              </div>
            </Flex>
          </Display>

          {/* Card Despesas */}
          <Display
            style={{ height: '360px', width: '425px', fontSize: '14px' }}
            overflowY
            title="Despesas"
            Select1={SelectDespesas}
            filter={modalFiltroDespesas.open}
          >
            <FiltroAvancado
              open={modalFiltroDespesas.state}
              handleClose={modalFiltroDespesas.close}
              onConfirm={() => {
                if (selectChoiceDespesas === 'avancado') searchDespesas();
                setSelectChoiceDespesas('avancado');
              }}
              onClear={limparFiltrosDespesas}
            >
              <Divisor>
                <p> Conta </p>
              </Divisor>
              <div style={{ width: '90%' }}>
                <Select
                  value={filtrosDespesas.contaId as any}
                  placeholder={
                    filtrosDespesas.contaId
                      ? contas.find((conta) => {
                          return conta.id === parseInt(filtrosDespesas.contaId);
                        })?.titulo
                      : 'Selecione uma conta'
                  }
                  options={contas.map((conta) => {
                    return {
                      label: conta.titulo,
                      value: conta.id,
                    };
                  })}
                  onChange={(e: any) => {
                    setFiltrosDespesas({
                      ...filtrosDespesas,
                      contaId: e.value,
                    });
                  }}
                />
              </div>
              <Divisor>
                <p>Período da Última Atualização</p>
              </Divisor>
              <div style={{ width: '90%' }}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Início"
                  value={filtrosDespesas.dataInicial.value}
                  onChange={(date) => {
                    setFiltrosDespesas({
                      ...filtrosDespesas,
                      dataInicial: {
                        value: date,
                        valueStr: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                      },
                    });
                  }}
                />
              </div>
              <div style={{ width: '90%' }}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Fim"
                  value={filtrosDespesas.dataFinal.value}
                  onChange={(date) => {
                    setFiltrosDespesas({
                      ...filtrosDespesas,
                      dataFinal: {
                        value: date,
                        valueStr: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                      },
                    });
                  }}
                />
              </div>
            </FiltroAvancado>
            <div style={{ height: '8vh' }}>
              <div style={{ fontSize: '24px', color: '#E52427', fontWeight: 'bold' }}>
                R$ {Formatter.formatNumber(2, despesaTotal)}
              </div>
              <div style={{ fontSize: '16px' }}>Despesas Totais</div>
            </div>
            <div style={{ height: '10vh', width: '8vw', justifyContent: 'right' }}>
              {despesaTotal ? (
                <ReactApexChart
                  options={donutChartProps as any}
                  height={220}
                  width={410}
                  series={despesas.map((despesa) => {
                    return despesa.valor;
                  })}
                  type="donut"
                />
              ) : (
                ['']
              )}
            </div>
          </Display>
          {/* Card Vendas por Clientes */}
          <Display
            style={{ height: '360px', width: '425px', fontSize: '14px' }}
            overflowY
            title="Vendas Top 5"
            Select1={SelectMesVenda}
            filter={modalFiltroVendas.open}
          >
            <FiltroAvancado
              open={modalFiltroVendas.state}
              handleClose={modalFiltroVendas.close}
              onConfirm={() => {
                if (selectChoiceMesVenda === 'avancado') searchVendas();
                setSelectChoiceMesVenda('avancado');
                setVendasLabel(filtrosVendas.tipo.charAt(0).toUpperCase() + filtrosVendas.tipo.slice(1));
              }}
              onClear={limparFiltrosVendas}
            >
              <Divisor>
                <p> Tipo </p>
              </Divisor>
              <div style={{ width: '90%' }}>
                <Select
                  value={
                    filtrosVendas.tipo ? selectOptionsTipoVenda.find((e) => e.value === filtrosVendas.tipo) : undefined
                  }
                  placeholder={filtrosVendas.tipo ?? 'Selecione um tipo'}
                  options={selectOptionsTipoVenda}
                  onChange={(e: any) => {
                    setFiltrosVendas({
                      ...filtrosVendas,
                      tipo: e.value,
                    });
                  }}
                />
              </div>
              <Divisor>
                <p>Período da Última Atualização</p>
              </Divisor>
              <div style={{ width: '90%' }}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Início"
                  value={filtrosVendas.dataInicial.value}
                  onChange={(date) => {
                    setFiltrosVendas({
                      ...filtrosVendas,
                      dataInicial: {
                        value: date,
                        valueStr: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                      },
                    });
                  }}
                />
              </div>
              <div style={{ width: '90%' }}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Fim"
                  value={filtrosVendas.dataFinal.value}
                  onChange={(date) => {
                    setFiltrosVendas({
                      ...filtrosVendas,
                      dataFinal: {
                        value: date,
                        valueStr: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                      },
                    });
                  }}
                />
              </div>
            </FiltroAvancado>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  fontSize: '18px',
                  color: '#04D972',
                  fontWeight: '700',
                  marginBottom: '4px',
                }}
              >
                <div style={{ textAlign: 'center', fontWeight: '700', fontSize: '24px', marginTop: '8px' }}>
                  R${' '}
                  {Formatter.formatNumber(
                    2,
                    clientesOuProdutos.reduce((accumulator, currentValue) => {
                      return accumulator + currentValue.valor;
                    }, 0),
                  )}
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '21px',
                    letterSpacing: '0.01em',
                  }}
                >
                  {`Vendas ${vendasLabel} Total`}
                </div>
              </div>

              <ReactApexChart
                options={colummChartProps as any}
                height={200}
                width={410}
                series={[
                  {
                    name: '',
                    data: clientesOuProdutos.map((cliente: any) => {
                      return {
                        x: cliente.nome,
                        y: cliente.valor,
                      };
                    }),
                  },
                ]}
                type="bar"
              />
            </div>
          </Display>
          {/* Card Contas Bancarias */}
          <Display style={{ height: '360px', width: '425px', fontSize: '14px' }} overflowY title="Contas Bancárias">
            {contas
              ? contas.map((conta, indice) => {
                  return (
                    <Conta key={indice} style={{ fontSize: '14px' }}>
                      <b>{conta.titulo}</b>
                      <b style={{ color: conta.saldo_atual < 0 ? 'red' : 'green', fontSize: '24px' }}>
                        R$ {Formatter.formatNumber(2, conta.saldo_atual)}
                      </b>
                    </Conta>
                  );
                })
              : []}
          </Display>
        </Flex>
      </ContainerBody>
    </>
  );
};

export default observer(FormPainelDashboard);
