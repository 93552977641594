/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import AddIcon from '@material-ui/icons/Add';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import Filtro from '../../../components/Filtro';
import ActionButton from '../../../components/Tabela/ActionButton';
import { Body } from '../../../components/Body';
import MaterialTable, { Column } from 'material-table';
import { config } from '../../../components/Tabela';
import useModal from '../../../services/hooks/useModal';
import Confirmar from '../../../components/Modais/Confirmar';
import { MenuList, MenuItem, Tooltip, TextField } from '@material-ui/core';
import Select from '../../../components/Select';
import { tipos } from '../Categoria';
import Display from '../../../components/Tabela/Display';
import { Conta, SaldoInfo, Saldos } from './styles';
import { getRelatorio } from '../../../services/utils/relatorios';
import FormOutras from './FormOutras';
import LargeNumber from '../../../components/LargeNumber';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';
import ModalMovimentacao from './ModalMovimentacao';
import { permissoes } from '../../../services/utils/permissoes';
import X9Logger from '../../../services/X9Logger';
// import FormCompras from './FormCompras';
// import FormProdutos from './FormProdutos';
// import EditarProdutos from './EditarProdutos';
// import FormVenda from './FormVenda';
// import EditarDespesa from './EditarDespesa';

const anoAtual = new Date().getFullYear();

type State = PageState & {
  selectedMovimentacao: number;
  selectedParcela: number;
  selectedTipo: MovimentacaoTipo | null;

  filtroTipo: SelectRow | null;
  filtroCategoria: SelectRow | null;
  filtroStatus: SelectRow | null;
  filtroFornecedor: SelectRow | null;
  filtroDescricao: string;
  filtroDocumento: string;
  filtroDataInicio: MaterialUiPickersDate;
  filtroDataFim: MaterialUiPickersDate;

  filtroRapidoTipo: SelectRow | null;
  filtroRapidoStatus: SelectRow | null;
  movimentacaoProps: Movimentacao;
  contasInativas: boolean;

  movimentacoesPesquisadas: Movimentacao[] | null;

  sync: boolean;
  soParcelas: boolean;

  mostrarContasInativas(): void;
};

const Movimentacoes: React.FC = () => {
  const params: any = useParams();
  const tableRef = useRef();
  const store = useStore();
  const history = useHistory();
  const modalExclusao = useModal();
  const modalProduto = useModal();
  const modalOutras = useModal();
  // const modalcompras = useModal();
  // const modalEditarProduto = useModal();
  const modalCadastrarDespesa = useModal();
  const modalEditarDespesa = useModal();
  const location: any = useLocation();
  const modalEditarVenda = useModal();
  const state = useLocalStore(
    (): State => ({
      pageSize: 0,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },
      setPage(num: number) {
        this.page = num;
      },
      page: location.state ? location.state.page : 0,
      movimentacaoProps: {} as Movimentacao,
      selectedMovimentacao: 0,
      selectedParcela: 0,
      selectedTipo: null,

      filtroTipo: null,
      filtroCategoria: null,
      filtroStatus: null,
      filtroFornecedor: null,
      filtroDescricao: '',
      filtroDocumento: '',
      filtroDataInicio: null,
      filtroDataFim: null,

      filtroRapidoTipo: null,
      filtroRapidoStatus: null,

      contasInativas: false,
      movimentacoesPesquisadas: null,

      sync: false,
      soParcelas: false,

      mostrarContasInativas() {
        this.contasInativas = !this.contasInativas;
      },
    }),
  );

  const permissaoLeitura =
    params.boolean === 'true' ? permissoes.VENDAS_VENDAS_PRODUTOS_LEITURA : permissoes.FINANCEIRO_MOVIMENTACOES_LEITURA;
  const permissaoEscrita =
    params.boolean === 'true' ? permissoes.VENDAS_VENDAS_PRODUTOS_ESCRITA : permissoes.FINANCEIRO_MOVIMENTACOES_ESCRITA;

  store.propriedade && store.validarPermissao(permissaoLeitura, history);
  const temPermissaoEscrita = store.checkPermissions(permissaoEscrita);

  function imprimirRecibo(id: string): void {
    getRelatorio(`movimentacao/recibo/${id}/`, {
      params: {
        prop: store.propriedade?.id,
        authori: store.token,
      },
    });
  }

  function checarExclusao(row: any): boolean {
    if (!row.tipo) {
      return false;
    } else if (
      row.categoria === 'Ração Alevinos' ||
      row.categoria === 'Ração Crescimento' ||
      row.categoria === 'Ração Engorda' ||
      row.tipo === 'vendas' ||
      row.descricao?.includes('Povoamento')
    ) {
      return false;
    } else {
      return true;
    }
  }

  function checarRecibo(row: any): boolean {
    if (!row.tipo) {
      return false;
    } else {
      return true;
    }
  }

  const categoriasOptions = store.categorias?.arr?.map((categoria) => {
    return { label: categoria.nome, value: categoria.id };
  });
  const itemsColumns: Column<DespesaItem>[] = [
    {
      field: 'categoria',
      title: 'Categoria',
      align: 'left',
      ...(true && ({ width: 100 } as object)),
      render: ({ categoria }: any) => {
        return (
          <p style={{ textAlign: 'left' }}>
            {categoriasOptions?.find((cat) => cat.value === categoria)?.label ?? categoria}
          </p>
        );
      },
    },
    {
      field: 'descricao',
      title: 'Descrição',
      align: 'left',
      ...(true && ({ width: 100 } as object)),
      render: ({ descricao }: any) => {
        return <p style={{ textAlign: 'left' }}>{descricao || '-'}</p>;
      },
    },
    {
      field: 'valor_unitario',
      title: 'Valor Unit (R$)',
      align: 'left',
      ...(true && ({ width: 100 } as object)),
      render: ({ valor_unitario }: any) => {
        return <p style={{ textAlign: 'left' }}>{Formatter.formatNumber(2, valor_unitario)}</p>;
      },
    },
    {
      field: 'quantidade',
      title: 'Quantidade',
      align: 'left',
      ...(true && ({ width: 100 } as object)),
      render: ({ quantidade }: any) => {
        return <p style={{ textAlign: 'left' }}>{Formatter.formatNumber(3, quantidade)}</p>;
      },
    },
    {
      field: 'valor_total',
      title: 'Valor Total (R$)',
      align: 'left',
      ...(true && ({ width: 100 } as object)),
      render: ({ valor_total }: any) => {
        return <p style={{ textAlign: 'left' }}>{Formatter.formatNumber(2, valor_total)}</p>;
      },
    },
  ];
  const columns: Column<Movimentacao>[] = [
    {
      field: 'tipo',
      title: 'Tipo',
      render: ({ tipo }: any) => {
        if (tipo === 'despesas') {
          return <span style={{ color: 'red' }}>Despesas</span>;
        } else if (tipo === 'vendas') {
          return <span style={{ color: 'green' }}>Vendas</span>;
        } else if (tipo === 'outras') {
          return <span>Outras Entradas</span>;
        } else if (tipo === 'produtos') {
          return <span style={{ color: 'green' }}>Produtos</span>;
        } else if (tipo === 'compras') {
          return <span style={{ color: 'red' }}>Compras</span>;
        } else return <span>Parcela</span>;
      },
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'acoes',
      disableClick: true,
      render: (row: any) => (
        <ActionButton
          recibo={checarRecibo(row) ? () => imprimirRecibo(row.movID) : undefined}
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedMovimentacao = parseInt(row.movID);
                  state.movimentacaoProps = row.props;
                  if (row.tipo === 'despesas') {
                    row.index ? (state.selectedParcela = row.index) : (state.selectedParcela = 0);
                    modalEditarDespesa.open();
                  } else if (row.tipo === 'compras') {
                    row.index ? (state.selectedParcela = row.index) : (state.selectedParcela = 0);
                    modalEditarDespesa.open();
                  } else if (row.tipo === 'outras') {
                    state.selectedParcela = 0;
                    modalOutras.open();
                  } else if (row.tipo === 'vendas') {
                    // if (row.parcelas !== '0' && row.parcelas) {
                    //   modalEditarVenda.open();
                    // }
                    row.index ? (state.selectedParcela = row.index) : (state.selectedParcela = 0);
                    modalEditarDespesa.open();
                  } else if (row.tipo === 'produtos') {
                    // if (row.parcelas !== '0' && row.parcelas) {
                    //   modalEditarProduto.open();
                    // }
                    row.index ? (state.selectedParcela = row.index) : (state.selectedParcela = 0);
                    modalEditarDespesa.open();
                  }
                }
              : undefined
          }
          parcelas={
            row.tipo !== 'outras'
              ? () => {
                  state.selectedMovimentacao = parseInt(row.movID);
                  state.movimentacaoProps = row.props;
                  row.index ? (state.selectedParcela = row.index) : (state.selectedParcela = 0);
                  state.soParcelas = true;
                  modalEditarDespesa.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita && checarExclusao(row)
              ? () => {
                  state.movimentacaoProps = row.props;
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 35 } as object)),
    },
    // // Fornecedor/Cliente
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'categoria',
      title: 'Categoria',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'documento',
      title: 'Documento',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'valorTotal',
      title: 'Valor Total (R$)',
      type: 'numeric',
      render: ({ valorTotal }: any) => {
        return Formatter.formatNumber(2, valorTotal);
      },
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'status',
      title: 'Status',
      render: ({ status }: any) => {
        if (status === true) {
          return <b style={{ color: 'green' }}>Pago</b>;
        } else {
          return <b style={{ color: 'red' }}>A Pagar</b>;
        }
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'parcelas',
      title: 'N° Parcelas',
      type: 'numeric',
      render: ({ parcelas }: any) => {
        return Formatter.formatNumber(0, parcelas);
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'conta',
      title: 'Conta',
      ...(true && ({ width: 150 } as object)),
    },
  ];
  const rows = (movimentacoes: Movimentacao[]): object[] => {
    if (movimentacoes.length) {
      if (movimentacoes.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(movimentacoes.length);
      }
    } else {
      state.setPageSize(0);
    }

    return movimentacoes.map((movimentacao) => {
      const data = new Date(
        movimentacao.data.substr(3, 2) + '/' + movimentacao.data.substr(0, 2) + '/' + movimentacao.data.substr(6, 4),
      );
      let status = false;

      if (movimentacao.parcelas.every((parcela) => parcela.recebido)) {
        status = true;
      }

      return {
        id: 'm' + movimentacao.id,
        tipo: movimentacao.categoria?.tipo,
        descricao: movimentacao.descricao,
        valorTotal: movimentacao.valor_total,
        data,
        parcelas: movimentacao.parcelas?.length || 0,
        valorParcela: null,
        status,
        documento: movimentacao.documento,
        categoria: movimentacao.categoria?.nome || '',
        conta: movimentacao.conta?.titulo || '',
        movID: movimentacao.id,
        props: movimentacao,
        itens: movimentacao.itens,
      };
    });
  };

  async function sync(): Promise<void> {
    await syncModel.movimentacoes();

    state.sync = true;
  }

  async function syncMovimentacoesCriacao(): Promise<void> {
    await syncModel.movimentacoesCriacao();

    state.sync = true;
  }

  async function filtrar(): Promise<void> {
    store.toggleLoader();

    if (
      state.filtroTipo ||
      state.filtroCategoria ||
      state.filtroStatus ||
      state.filtroFornecedor ||
      state.filtroDescricao ||
      state.filtroDocumento ||
      state.filtroDataInicio ||
      state.filtroDataFim
    ) {
      state.filtroRapidoTipo = null;
      state.filtroRapidoStatus = null;

      (tableRef.current as any).onQueryChange();
    } else {
      sync();
    }

    store.toggleLoader();
  }
  async function limparFiltro(): Promise<void> {
    state.filtroTipo = null;
    state.filtroCategoria = null;
    state.filtroStatus = null;
    state.filtroFornecedor = null;
    state.filtroDescricao = '';
    state.filtroDocumento = '';
    state.filtroDataInicio = null;
    state.filtroDataFim = null;
    state.filtroRapidoStatus = null;
    state.filtroRapidoTipo = null;
    state.movimentacoesPesquisadas = null;
    (tableRef.current as any).onQueryChange();
  }

  async function deletar(): Promise<void> {
    modalExclusao.close();
    store.toggleLoader();
    await store.movimentacoes.excluir(state.movimentacaoProps.id);
    await sync();

    store.toggleLoader();
    state.movimentacaoProps = {} as Movimentacao;
    (tableRef.current as any).onQueryChange();
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Movimentações'), []);

  useEffect(() => {
    if (store.propriedade) {
      sync();
      (tableRef.current as any)?.onQueryChange();
    }

    if (params.boolean === 'true') {
      modalProduto.open();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  // Filtro Rápido
  useEffect(() => {
    if (
      !state.filtroTipo &&
      !state.filtroCategoria &&
      !state.filtroStatus &&
      !state.filtroFornecedor &&
      !state.filtroDescricao &&
      !state.filtroDocumento &&
      !state.filtroDataInicio &&
      !state.filtroDataFim &&
      (state.filtroRapidoTipo || state.filtroRapidoStatus)
    ) {
      (tableRef.current as any).onQueryChange();
    }
    // eslint-disable-next-line
  }, [state.filtroRapidoTipo, state.filtroRapidoStatus]);

  const ExcluirMov: React.FC = () => {
    return (
      <div style={{ width: '100%' }}>
        {state.movimentacaoProps.parcelas.length > 0 && (
          <>
            <b style={{ color: '#fcdb03' }}>Todas as parcelas dessa movimentação serão excluídas!</b>
            <br />
          </>
        )}
        <b>Descrição: </b> {state.movimentacaoProps && state.movimentacaoProps.descricao}
        <br />
        <b>Tipo: </b>{' '}
        {state.movimentacaoProps.categoria &&
          tipos.filter((tipo) => tipo.value === state.movimentacaoProps.categoria.tipo)[0].label}
        <br />
        <b>Data: </b> {state.movimentacaoProps && Formatter.getData(state.movimentacaoProps.data)}
        <br />
        <b>Categoria: </b> {state.movimentacaoProps.categoria && state.movimentacaoProps.categoria.nome}
        <br />
        <b>Conta: </b> {state.movimentacaoProps.conta && state.movimentacaoProps.conta.titulo}
        <br />
        <b>Valor Total: </b> {state.movimentacaoProps && Formatter.formatNumber(2, state.movimentacaoProps.valor_total)}
        <br />
        <b>Parcelas: </b>
        {state.movimentacaoProps && state.movimentacaoProps.parcelas.length
          ? Formatter.formatNumber(0, state.movimentacaoProps.parcelas.length)
          : 0}
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            dropdown
            onClick={() => {
              state.selectedMovimentacao = 0;
              state.selectedParcela = 0;
              state.selectedTipo = 'despesas';
              modalCadastrarDespesa.open();
            }}
            text="NOVA DESPESA"
            Icon={AddIcon}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  state.selectedParcela = 0;
                  state.selectedMovimentacao = 0;
                  state.selectedTipo = 'outras';
                  modalOutras.open();
                }}
              >
                Outras Entradas
              </MenuItem>
              <MenuItem
                onClick={() => {
                  state.selectedParcela = 0;
                  state.selectedMovimentacao = 0;
                  state.selectedTipo = 'compras';
                  modalCadastrarDespesa.open();
                  // modalcompras.open();
                }}
              >
                Compras
              </MenuItem>
              <MenuItem
                onClick={() => {
                  state.selectedParcela = 0;
                  state.selectedMovimentacao = 0;
                  state.selectedTipo = 'produtos';
                  modalCadastrarDespesa.open();
                  // modalProduto.open();
                }}
              >
                Vendas Produtos
              </MenuItem>
            </MenuList>
          </AddBtn>
          <div style={{ width: 200 }}>
            <Select
              value={state.filtroRapidoTipo}
              placeholder="Tipo"
              onChange={(e: any) => (state.filtroRapidoTipo = e)}
              options={tipos}
            />
          </div>
          <div style={{ width: 200, marginLeft: 10 }}>
            <Select
              value={state.filtroRapidoStatus}
              placeholder="Situação"
              onChange={(e: any) => (state.filtroRapidoStatus = e)}
              options={[
                { label: 'A Pagar', value: 'false' },
                { label: 'Pago', value: 'true' },
              ]}
            />
          </div>
        </Flex>
        <Flex>
          <Filtro sync={filtrar} clear={limparFiltro}>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroTipo}
                placeholder="Tipo"
                onChange={(e: any) => (state.filtroTipo = e)}
                options={tipos}
              />
            </div>

            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroCategoria}
                placeholder="Categoria"
                onChange={(e: any) => (state.filtroCategoria = e)}
                options={
                  state.filtroTipo
                    ? store.categorias.getByTipo(state.filtroTipo.value).map((categoria) => {
                        return { label: categoria.nome, value: categoria.id.toString() };
                      })
                    : []
                }
              />
            </div>

            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroStatus}
                placeholder="Situação"
                onChange={(e: any) => (state.filtroStatus = e)}
                options={[
                  { label: 'A Pagar', value: 'false' },
                  { label: 'Pago', value: 'true' },
                ]}
              />
            </div>

            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroFornecedor}
                placeholder="Fornecedor"
                onChange={(e: any) => (state.filtroFornecedor = e)}
                options={store.fornecedores.getSelectRows()}
              />
            </div>

            <div style={{ width: '90%' }}>
              <TextField
                fullWidth
                label="Descrição"
                value={state.filtroDescricao}
                onChange={(e) => (state.filtroDescricao = e.target.value)}
              />
            </div>
            <div style={{ width: '90%' }}>
              <TextField
                fullWidth
                label="Documento"
                value={state.filtroDocumento}
                onChange={(e) => (state.filtroDocumento = e.target.value)}
              />
            </div>

            <div style={{ width: '90%' }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Início"
                value={state.filtroDataInicio}
                onChange={(date) => (state.filtroDataInicio = date)}
              />
            </div>
            <div style={{ width: '90%' }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Fim"
                value={state.filtroDataFim}
                onChange={(date) => (state.filtroDataFim = date)}
              />
            </div>
          </Filtro>
        </Flex>
      </Header>

      <Body gridColumns="2fr 1fr">
        <div style={{ overflowX: 'hidden' }}>
          <Saldos>
            <Tooltip title={'Ano ' + anoAtual}>
              <SaldoInfo cor="#4caf50" variant="contained">
                <LargeNumber
                  bold
                  unidade=""
                  precision={2}
                  numero={store.movimentacoes.receitas ? (store.movimentacoes.receitas!.vendas as number) : 0}
                />
                <p>Vendas Recebidas</p>
              </SaldoInfo>
            </Tooltip>
            <Tooltip title={'Ano ' + anoAtual}>
              <SaldoInfo
                onClick={() => {
                  if (temPermissaoEscrita) {
                    state.selectedMovimentacao = 0;
                    state.selectedParcela = 0;
                    modalCadastrarDespesa.open();
                  }
                }}
                cor="#c10015"
                variant="contained"
              >
                <LargeNumber
                  bold
                  unidade=""
                  precision={2}
                  numero={store.movimentacoes.receitas ? (store.movimentacoes.receitas!.despesas as number) : 0}
                />
                <p>Despesas Pagas</p>
              </SaldoInfo>
            </Tooltip>
            <Tooltip title={'Ano ' + anoAtual}>
              <SaldoInfo
                onClick={() => {
                  if (temPermissaoEscrita) {
                    state.selectedMovimentacao = 0;
                    state.selectedParcela = 0;
                    modalOutras.open();
                  }
                }}
                cor="#9e9e9e"
                variant="contained"
              >
                <LargeNumber
                  bold
                  unidade=""
                  precision={2}
                  numero={store.movimentacoes.receitas ? (store.movimentacoes.receitas!.outras as number) : 0}
                />
                <p>Outras Entradas</p>
              </SaldoInfo>
            </Tooltip>
            <Tooltip title={'Ano ' + anoAtual}>
              <SaldoInfo variant="contained">
                <p className="label">{anoAtual}</p>
                <LargeNumber
                  bold
                  unidade=""
                  precision={2}
                  numero={store.movimentacoes.receitas ? (store.movimentacoes.receitas!.saldo as number) : 0}
                />
                <p>Saldo Total</p>
              </SaldoInfo>
            </Tooltip>
          </Saldos>
          <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={
                state.movimentacoesPesquisadas
                  ? state.movimentacoesPesquisadas
                  : (query) =>
                      new Promise((resolve) => {
                        try {
                          store.propriedade &&
                            apiV2
                              .get('/movimentacao/', {
                                params: {
                                  page_size: 50,
                                  page: query.page + 1,
                                  prop: store.propriedade!.id,
                                  tipo:
                                    state.filtroRapidoTipo == null
                                      ? state.filtroTipo?.value
                                      : state.filtroRapidoTipo?.value,
                                  categoria: state.filtroCategoria?.value,
                                  situacao:
                                    state.filtroRapidoStatus === null
                                      ? state.filtroStatus?.value
                                      : state.filtroRapidoStatus?.value,
                                  fornecedor: state.filtroFornecedor?.value,
                                  descricao: state.filtroDescricao || null,
                                  documento: state.filtroDocumento || null,
                                  dataInicio: state.filtroDataInicio
                                    ? Formatter.dateToString(state.filtroDataInicio, '-')
                                    : null,
                                  dataFim: state.filtroDataFim
                                    ? Formatter.dateToString(state.filtroDataFim, '-')
                                    : null,
                                },
                              })
                              .then((res) => {
                                if (res.data.count) {
                                  if (res.data.count >= 50) {
                                    state.setPageSize(50);
                                  } else {
                                    state.setPageSize(res.data.count);
                                  }
                                } else {
                                  state.setPageSize(0);
                                }

                                resolve({
                                  data: rows(res.data.results) as any,
                                  page: query.page,
                                  totalCount: res.data.count,
                                });
                              });
                        } catch (err) {
                          X9Logger.report(err as Error);
                          store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
                        }
                      })
              }
              options={{ ...config.options, pageSize: 50, maxBodyHeight: window.innerHeight - 275 }}
              localization={config.localization}
              style={config.style}
              detailPanel={(rowData) => <ItemsTable columns={itemsColumns} items={rowData.itens} />}
              onRowClick={(_e, row: any) => {
                state.movimentacaoProps = row.props;

                if (temPermissaoEscrita) {
                  state.selectedMovimentacao = row.index ? parseInt(row.movID) : parseInt(row.id.substr(1));
                  if (row.tipo === 'despesas') {
                    row.index ? (state.selectedParcela = parseInt(row.index)) : (state.selectedParcela = 0);
                    modalEditarDespesa.open();
                  } else if (row.tipo === 'outras') {
                    modalOutras.open();
                  } else if (row.tipo === 'vendas') {
                    if (row.parcelas !== '0' && row.parcelas) {
                      state.selectedParcela = 0;
                      modalEditarVenda.open();
                    }
                  }
                }
              }}
            />
          </div>
        </div>
        <div>
          <Display
            // verTudo={state.mostrarContasInativas}
            style={{ marginBottom: '10px', height: 'calc(50vh - 65px)' }}
            overflowY
            title="Minhas Contas"
          >
            {store.movimentacoes.contas &&
              store.movimentacoes.contas.map((conta) => {
                return (
                  <Conta key={conta?.id}>
                    <b>{conta?.titulo}</b>
                    <b style={{ color: conta?.saldo_atual < 0 ? 'red' : 'green' }}>
                      R$ {Formatter.formatNumber(2, conta?.saldo_atual)}
                    </b>
                  </Conta>
                );
              })}
          </Display>
          <Display
            // verTudo={state.mostrarContasInativas}
            style={{ marginBottom: '10px', height: 'calc(50vh - 65px)' }}
            overflowY
            title="Despesas a pagar"
          >
            {store.movimentacoes.despesas
              ? store.movimentacoes.despesas.map((conta, index) => {
                  return (
                    <Conta key={index}>
                      <b>{conta.conta}</b>
                      <b style={{ color: 'red' }}>R$ {Formatter.formatNumber(2, conta.total)}</b>
                    </Conta>
                  );
                })
              : []}
          </Display>
        </div>
      </Body>

      {modalCadastrarDespesa.state ? (
        <ModalMovimentacao
          open={modalCadastrarDespesa.state}
          handleClose={() => {
            state.movimentacaoProps = {} as Movimentacao;
            modalCadastrarDespesa.close();
          }}
          onConfirm={() => {
            syncMovimentacoesCriacao();
            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
          }}
          tipo={state.selectedTipo || 'compras'}
        />
      ) : null}
      {/* {modalEditarProduto.state ? (
        <EditarProdutos
          open={modalEditarProduto.state}
          handleClose={() => {
            modalEditarProduto.close();
            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
          }}
          despesa={state.movimentacaoProps}
          onConfirm={() => {
            syncMovimentacoesCriacao();
            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
          }}
        />
      ) : null} */}
      {modalEditarDespesa.state && state.movimentacaoProps?.id ? (
        <ModalMovimentacao
          open={modalEditarDespesa.state}
          despesa={state.movimentacaoProps}
          handleClose={() => {
            modalEditarDespesa.close();
            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
            state.soParcelas = false;
            state.selectedTipo = null;
          }}
          onConfirm={() => {
            syncMovimentacoesCriacao();
            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
          }}
          soParcelas={state?.soParcelas}
          tipo={state.selectedTipo || 'compras'}
        />
      ) : null}
      {modalOutras.state ? (
        <FormOutras
          open={modalOutras.state}
          handleClose={() => {
            modalOutras.close();
            state.movimentacaoProps = {} as Movimentacao;
          }}
          entrada={state.movimentacaoProps}
          onConfirm={() => {
            syncMovimentacoesCriacao();

            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
          }}
        />
      ) : null}
      {modalExclusao.state && state.movimentacaoProps ? (
        <Confirmar
          open={modalExclusao.state}
          content={<ExcluirMov />}
          title="Tem certeza que deseja excluir?"
          handleClose={() => {
            modalExclusao.close();
            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
          }}
          onCancel={() => {
            modalExclusao.close();
            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
          }}
          onConfirm={deletar}
        />
      ) : null}
      {/* {modalcompras.state ? (
        <FormCompras
          open={modalcompras.state}
          handleClose={() => {
            state.movimentacaoProps = {} as Movimentacao;
            modalcompras.close();
          }}
          onConfirm={() => {
            syncMovimentacoesCriacao();
            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
          }}
        />
      ) : null} */}

      {/* {modalProduto.state ? (
        <FormProdutos
          onConfirm={() => {
            sync();
            (tableRef.current as any).onQueryChange();
          }}
          open={modalProduto.state}
          handleClose={modalProduto.close}
        />
      ) : null} */}
      {/* {modalEditarVenda.state ? (
        <FormVenda
          open={modalEditarVenda.state}
          handleClose={() => {
            modalEditarVenda.close();
            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
            state.soParcelas = false;
          }}
          venda={state.movimentacaoProps}
          numParcela={state.selectedParcela}
          onConfirm={() => {
            syncMovimentacoesCriacao();

            (tableRef.current as any).onQueryChange();
            state.movimentacaoProps = {} as Movimentacao;
          }}
          soParcelas={state.soParcelas}
        />
      ) : null} */}
    </>
  ) : null;
};

export default observer(Movimentacoes);

type ItemTableProps = {
  items: DespesaItem[];
  columns: Column<DespesaItem>[];
};

const ItemsTable: React.FC<ItemTableProps> = ({ items, columns }) => {
  return (
    <MaterialTable
      columns={columns}
      data={items}
      options={{
        ...config.options,
        paging: false,
        minBodyHeight: 0,
        maxBodyHeight: 600,
        pageSize: items.length + 1,
        headerStyle: {
          background: 'white',
          color: '#2D2D2D',
          height: '40px',
          paddingTop: 10,
          paddingBottom: 10,
          fontSize: 14,
          borderBottom: 'none',
          zIndex: 0,
        },
      }}
      localization={config.localization}
      style={config.style}
    />
  );
};
