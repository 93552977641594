import { IconButton, Tooltip } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CachedIcon from '@material-ui/icons/Cached';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import MaterialTable, { Column } from 'material-table';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Conteudo, NewBody } from '../../../components/Body';
import { Flex } from '../../../components/NavBar/styles';
import { newConfig } from '../../../components/Tabela';
import Formatter from '../../../services/Formatter';
import useModal from '../../../services/hooks/useModal';
import useStore from '../../../services/hooks/useStore';
import { apiMain, aqReports } from '../../../services/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import X9Logger from '../../../services/X9Logger';
import { permissoes } from '../../../services/utils/permissoes';

const TanquesProgramaAlimentar: React.FC = () => {
  // @ts-ignore
  const [options, setOptions] = useState();

  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);
  const tableRef = useRef(null);
  const modalFiltros = useModal();
  const location: any = useLocation();
  const state = useLocalStore(() => ({
    rows: [] as object[],
    sync: false,
    pageSize: 0,

    filtroFase: null as SelectRow | null,
    filtroDataInicio: null as MaterialUiPickersDate,
    filtroDataFim: null as MaterialUiPickersDate,

    filtroRapidoFinalizado: true,
    filtroRapidoFase: null as SelectRow | null,

    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    page: location.state ? location.state.page : 0,
    setPage(num: number) {
      this.page = num;
    },
  }));

  async function sync(): Promise<void> {
    const tabela: any = tableRef.current;
    tabela.onQueryChange();
  }

  useEffect(() => {
    store.setTitulo('Tanques Com Programa Alimentar');
  }, []);

  useEffect(() => {
    async function getThings(): Promise<void> {
      state.sync = true;
    }
    if (store.propriedade) {
      getThings();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  const columns: Column<object>[] = [
    {
      field: 'tanque.nome',
      title: 'Tanque',
      width: 150,
    },
    {
      field: 'lote.nome',
      title: 'Lote',
      type: 'string',
      width: 120,
    },
    {
      field: 'especie',
      title: 'Espécie',
      type: 'string',
      width: 120,
    },
    {
      field: 'previsao',
      title: 'Previsão de Despesca',
      type: 'date',
      width: 140,
    },
    {
      field: 'povoamento',
      title: 'Povoamento',
      type: 'numeric',
      width: 140,
      render: ({ povoamento }: any) => {
        return Formatter.formatNumber(0, povoamento);
      },
    },
    {
      field: 'trans_entrada',
      title: 'Trans. Ent.',
      width: 150,
      render: ({ trans_entrada }: any) => {
        return Formatter.formatNumber(0, trans_entrada);
      },
      type: 'numeric',
    },
    {
      field: 'trans_saida',
      title: 'Trans. Saída',
      type: 'numeric',
      width: 150,
      render: ({ trans_saida }: any) => {
        return Formatter.formatNumber(0, trans_saida);
      },
    },
    {
      field: 'mortes',
      title: 'Mortes',
      type: 'numeric',
      width: 120,
      render: ({ mortes }: any) => {
        return Formatter.formatNumber(0, mortes);
      },
    },
    {
      field: 'vendas',
      title: 'Venda',
      type: 'numeric',
      width: 120,
      render: ({ vendas }: any) => {
        return Formatter.formatNumber(0, vendas);
      },
    },
    {
      field: 'data_ultima_venda',
      title: 'Data Última Venda',
      type: 'date',
      width: 180,
    },
    {
      field: 'estoque',
      title: 'Estoque Atual',
      type: 'numeric',
      width: 140,
      render: ({ estoque }: any) => {
        return Formatter.formatNumber(0, estoque);
      },
    },
    {
      field: 'pm_programa',
      title: 'PM Prog Alimentar (Kg)',
      type: 'numeric',
      width: 150,
      render: ({ pm_programa }: any) => {
        return Formatter.formatNumber(3, pm_programa);
      },
    },
    {
      field: 'biomassa_programa',
      title: 'Biomassa Prog Alimentar (kg)',
      type: 'numeric',
      width: 180,
      render: ({ biomassa_programa }: any) => {
        return Formatter.formatNumber(3, biomassa_programa);
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getCSV() {
    try {
      const csv = await apiMain.get(`/gerador/csv/relatorio/tanques/${store.propriedade?.id}/programa_alimentar`, {
        params: {
          authori: store.token,
        },
      });
      window.open(csv.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getPDF() {
    try {
      const pdf = await apiMain.get(`/gerador/pdf/relatorio/tanques/${store.propriedade?.id}/programa_alimentar`, {
        params: {
          authori: store.token,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Link
          to={'/relatorios/relatorios-zootecnicos'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto', marginRight: '10px' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>
        <Flex>
          <Tooltip title="Exportar para CSV">
            <IconButton className="blue" onClick={getCSV}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filtro Avançado">
            <IconButton className="blue" onClick={modalFiltros.open}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton className="blue" onClick={sync}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <Conteudo style={{ padding: 0 }}>
        {store.propriedade && (
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={(query) =>
              new Promise((resolve, reject) => {
                aqReports
                  .get(`/relatorio/tanques/${store.propriedade?.uuid}/programa_alimentar`, {
                    params: {
                      page: query.page + 1,
                      item_count: 50,
                    },
                  })
                  .then((res) => {
                    setOptions(res.data.results);
                    let data = res.data.results.filter((item: any) => item.estoque_atual !== 0);

                    // sort por nome do tanque
                    data = data.sort((a: any, b: any) => {
                      if (a.tanque.nome < b.tanque.nome) {
                        return -1;
                      }
                      if (a.tanque.nome < b.tanque.nome) {
                        return 1;
                      }
                      return 0;
                    });

                    state.rows = data;

                    resolve({
                      data: data,
                      page: res.data.page - 1,
                      totalCount: res.data.count_total,
                    });
                  })
                  .catch((err) => {
                    X9Logger.report(err);
                    store.notificar('Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                    reject();
                  });
              })
            }
            options={{ ...newConfig.options, pageSize: 50 }}
            localization={newConfig.localization}
            style={newConfig.style}
          />
        )}
      </Conteudo>
    </NewBody>
  ) : null;
};

export default observer(TanquesProgramaAlimentar);
