/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from './styles';
import ReactInputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';
import CPFCNPJInput from '../CPFCNPJInput';
import { LabelCheched, LoginChecked, BotoesContainer } from '../../pages/Login/styles';
import { Flex } from '../NavBar/styles';
import NovaPropriedade from './NovaPropriedade';
import useModal from '../../services/hooks/useModal';
import { observer, useLocalStore } from 'mobx-react-lite';
import { apiV2, baseApi } from '../../services/utils';
import Botao from '../Botoes/Botao';
import useStore from '../../services/hooks/useStore';
import Select from '../Select';
import ModalResetarSenha from './ResetarSenha';

type State = { perfil: SelectRow | null };

const CadastrarClientes: React.FC<ModalProps> = ({ open, handleClose }) => {
  const store = useStore();
  const state = useLocalStore((): State => ({ perfil: null }));

  function closeModais(): void {
    localStorage.removeItem('dadosCadastro');
    handleClose();
    modal.cliente.close();
    modal.confirmar.close();
    modal.propridade.close();
  }

  const [type, setType] = useState('password');

  const [client, setClient] = useState({
    name: '',
    inscricaoFederal: '',
    email: '',
    tel: '',
    password: '',
    perfil: '',
    terms: false,
  });

  const [options, setOptions] = useState({
    estados: [],
    cidades: [],
    perfis: [
      { id: 2, nome: 'Produtor' },
      { id: 35, nome: 'Fornecedor' },
      { id: 37, nome: 'Engenheiro / Técnico' },
      { id: 36, nome: 'Estudante' },
      { id: 34, nome: 'Outros' },
    ],
  });

  const modal = {
    propridade: useModal(),
    confirmar: useModal(),
    recuperar: useModal(),
    cliente: useModal(),
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setClient({
      ...client,
      [e.target.name]: e.target.value,
    });
  }

  async function getOptions(): Promise<void> {
    const requestStates = await baseApi.get('/estados/');
    const requestCities = await baseApi.get('/cidades/');

    setOptions({
      ...client,
      estados: requestStates!.data,
      cidades: requestCities!.data,
      perfis: [
        { id: 2, nome: 'Produtor' },
        { id: 35, nome: 'Fornecedor' },
        { id: 37, nome: 'Engenheiro / Técnico' },
        { id: 36, nome: 'Estudante' },
        { id: 34, nome: 'Outros' },
      ],
    });
  }

  async function nextModal(): Promise<void> {
    let check = true;
    const checkField: any = client;
    store.toggleLoader();

    // checar se os campos estão preenchidos
    for (let field in checkField) {
      if (!checkField[field]) {
        check = false;

        if (field === 'terms') {
          store.notificar('Aceite os termos');
        } else {
          store.notificar('Insira os dados corretamente');
        }

        break;
      }
    }

    if (check) {
      await apiV2
        .get('/usuario/verificacao/', {
          params: {
            email: client.email,
            inscricao_federal: client.inscricaoFederal,
          },
        })
        .then(async () => {
          await getOptions();
          modal.propridade.open();
        })
        .catch((e) => {
          e.response.data.erro === 'Usuario com inscricao_federal ja cadastrada'
            ? store.notificar('CPF/CNPJ já cadastrado! Recure sua senha ou entre em contato conosco.')
            : store.notificar('E-mail já cadastrado, verifique se está correto ou informe outro!');
        });
    }
    store.toggleLoader();
  }

  return (
    <Modal onClose={handleClose} open={open} style={{ display: modal.cliente.state ? 'none' : 'block' }}>
      <ModalHeader>Novo Usuário</ModalHeader>
      <ModalBody>
        <TextField
          fullWidth
          label="Nome do Usuário"
          variant="standard"
          name="name"
          value={client.name}
          onChange={handleChange}
        />

        <CPFCNPJInput value={client.inscricaoFederal} name="inscricaoFederal" onChange={handleChange} />

        <TextField
          fullWidth
          label="E-mail"
          variant="standard"
          type="email"
          name="email"
          value={client.email}
          onChange={handleChange}
        />
        <ReactInputMask mask="(99) 99999-9999" maskChar="" value={client.tel} onChange={handleChange}>
          {() => <TextField fullWidth label="Telefone/Whatsapp" variant="standard" name="tel" />}
        </ReactInputMask>
        <TextField
          fullWidth
          label="Senha"
          variant="standard"
          type={type}
          name="password"
          value={client.password}
          onChange={handleChange}
        />
        <BotoesContainer>
          <LabelCheched style={{ marginBottom: 15 }}>
            <LoginChecked
              type="checkbox"
              onClick={() => (type === 'password' ? setType('text') : setType('password'))}
            />
            <span>Mostrar senha</span>
          </LabelCheched>
          <Select
            value={state.perfil}
            placeholder="Qual o seu perfil"
            onChange={(e: any) => {
              state.perfil = e;
              setClient({
                ...client,
                perfil: e.value,
              });
            }}
            options={options!.perfis.map((perfil: { nome: any; id: any }) => {
              return {
                label: perfil.nome,
                value: `${perfil.id}`,
              };
            })}
          />
          <LabelCheched style={{ marginTop: 6 }}>
            <LoginChecked
              type="checkbox"
              name="terms"
              checked={client.terms}
              onChange={(e) => setClient({ ...client, [e.target.name]: !client.terms })}
            />
            <span>Li e aceito</span>
          </LabelCheched>
          <Flex style={{ justifyContent: 'space-around', fontSize: '.7em', marginTop: 10 }}>
            <a
              style={{ color: '#575757' }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://aquabit.com.br/termos"
            >
              Termos de Uso
            </a>
            <a
              style={{ color: '#575757' }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://aquabit.com.br/politica-de-privacidade"
            >
              Política de Privacidade
            </a>
            <a
              style={{ color: '#575757' }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://aquabit.com.br/politica-de-cookies"
            >
              Políticas de Cookies
            </a>
          </Flex>
          <Flex style={{ justifyContent: 'space-around', marginTop: 22 }}>
            <Botao variant="contained" cor="#FC7467" onClick={handleClose}>
              Cancelar
            </Botao>
            <Botao variant="contained" cor="#059DE7" onClick={nextModal}>
              Avançar
            </Botao>
          </Flex>
        </BotoesContainer>
      </ModalBody>
      <NovaPropriedade open={modal.propridade.state} handleClose={closeModais} data={client} options={options} />
      <ModalResetarSenha open={modal.recuperar.state} handleClose={() => modal.recuperar.close()} />
    </Modal>
  );
};

export default observer(CadastrarClientes);
