/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import { Flex } from '../../../components/NavBar/styles';
import { NewBody, Conteudo } from '../../../components/Body';
import { IconButton, Tooltip, Grid, Checkbox } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import useStore from '../../../services/hooks/useStore';
import { Link, useHistory } from 'react-router-dom';
import { useLocalStore, observer } from 'mobx-react-lite';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import syncModel from '../../../services/models/Sync';
import FilterListIcon from '@material-ui/icons/FilterList';
import useModal from '../../../services/hooks/useModal';
import FiltroAvancado from '../../../components/Modais/FiltroAvancado';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import X9Logger from '../../../services/X9Logger';
import { Divisor } from '../../../components/Modais/styles';
import { aqReports, apiMain } from '../../../services/utils';
import InputNumero from '../../../components/InputNumero';
import ActionButton from '../../../components/Tabela/ActionButton';
import Formatter from '../../../services/Formatter';
import { FormControlLabel } from '@mui/material';
import { permissoes } from '../../../services/utils/permissoes';

const ResumoTanques: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);
  const tableRef = useRef(null);
  const modalFiltros = useModal();
  const [mostrarTodosTanques, setMostrarTodosTanques] = useState(false);
  const state = useLocalStore(() => ({
    pageSize: 0,
    rows: [] as object[],
    sync: false,

    setPageSize(value: number) {
      this.pageSize = value + 1;
    },

    filtroCA: [0, 0],
    filtroBiomassa: [0, 0],
    filtroDataInicio: null as MaterialUiPickersDate,
    filtroDataFim: null as MaterialUiPickersDate,
  }));

  async function sync(): Promise<void> {
    const tabela: any = tableRef.current;
    tabela.onQueryChange();
  }

  function limparFiltros(): void {
    state.filtroCA = [0, 0];
    state.filtroBiomassa = [0, 0];
    state.filtroDataInicio = null;
    state.filtroDataFim = null;
    sync();
  }
  function refreshTable() {
    (tableRef.current as any)?.onQueryChange();
  }

  useEffect(() => {
    store.setTitulo('Resumo de Tanques');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getThings(): Promise<void> {
      await syncModel.desempenhoLotes();
      state.sync = true;
    }
    if (store.propriedade) {
      getThings();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  async function getResumoIndividual(
    _e: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData: any,
  ): Promise<void> {
    store.toggleLoader();

    try {
      const pdf = await apiMain.get(`/gerador/pdf/resumo/${store.propriedade?.id}/tanques/${rowData.lotetanque.id}/`, {
        params: {
          authori: store.token,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }

    store.toggleLoader();
  }

  const columns: Column<object>[] = [
    {
      field: 'tanque',
      title: 'Viveiro/Tanque',
      width: 180,

      render: (rowData: any) => {
        return rowData.tanque.nome;
      },
    },
    {
      field: 'acoes',
      disableClick: true,
      width: 100,
      render: (rowData: any) => (
        <ActionButton
          relatorio={() => {
            getResumoIndividual(undefined, rowData);
          }}
        />
      ),
    },
    {
      field: 'tamanho',
      title: 'Tamanho (M2/M3)',
      width: 140,
      render: ({ tamanho }: any) => {
        return Formatter.formatNumber(0, tamanho);
      },
    },
    {
      field: 'lote',
      title: 'Lote',
      width: 150,

      render: (rowData: any) => {
        return rowData.lote.nome;
      },
    },
    {
      field: 'fase',
      title: 'Fase',
      width: 140,
      render: (rowData: any) => {
        return rowData.fase;
      },
    },
    {
      field: 'data_aloj',
      title: 'Data Alojamento',
      type: 'date',
      width: 140,
      render: ({ data_alojamento }: any) => {
        return data_alojamento;
      },
    },
    {
      field: 'qtde_alojado',
      title: 'Qtde Alojada',
      type: 'numeric',
      width: 140,
      render: ({ qtde_alojado }: any) => {
        return Formatter.formatNumber(0, qtde_alojado);
      },
    },
    {
      field: 'qtde_mortes',
      title: 'Qtde Mortes',
      width: 140,
      align: 'right',
      render: ({ qtde_mortes }: any) => {
        return Formatter.formatNumber(0, qtde_mortes);
      },
    },
    {
      field: 'qtd_transf_entrada',
      title: 'Qtde Transf Entrada',
      width: 180,
      align: 'right',
      render: ({ qtd_transf_entrada }: any) => {
        return Formatter.formatNumber(0, qtd_transf_entrada);
      },
    },
    {
      field: 'qtde_transferida',
      title: 'Qtde Transf Saída',
      width: 180,
      align: 'right',
      render: ({ qtde_transferida }: any) => {
        return Formatter.formatNumber(0, qtde_transferida);
      },
    },
    {
      field: 'qtde_vendida',
      title: 'Qtde Vendida',
      type: 'numeric',
      width: 140,
      align: 'right',
      render: ({ qtde_vendida }: any) => {
        return Formatter.formatNumber(0, qtde_vendida);
      },
    },
    {
      field: 'estoque_atual',
      title: 'Estoque Atual',
      type: 'numeric',
      width: 140,
      render: ({ estoque_atual }: any) => {
        return Formatter.formatNumber(0, estoque_atual);
      },
    },
    {
      field: 'ciclo',
      title: 'Ciclo (Dias)',
      type: 'numeric',
      width: 140,
      render: ({ ciclo }: any) => {
        return Formatter.formatNumber(0, ciclo);
      },
    },
    {
      field: 'prev_despesca',
      title: 'Prev Despesca',
      type: 'date',
      width: 140,
      render: ({ prev_despesca }: any) => {
        return prev_despesca;
      },
    },
    {
      field: 'pm_inicial',
      title: 'PM Inicial (g)',
      type: 'numeric',
      width: 140,
      render: ({ pm_inicial }: any) => {
        return Formatter.formatNumber(3, pm_inicial);
      },
    },
    {
      field: 'ultimo_pm',
      title: 'Último PM (g)',
      type: 'numeric',
      width: 140,
      align: 'right',
      render: ({ ultimo_pm }: any) => {
        return Formatter.formatNumber(3, ultimo_pm);
      },
    },
    {
      field: 'biomassa',
      title: 'Biomassa (kg)',
      width: 140,
      align: 'right',
      render: ({ biomassa }: any) => {
        return Formatter.formatNumber(3, biomassa);
      },
    },
    {
      field: 'biomassa_vendida',
      title: 'Bio Vendida (Kg)',
      width: 140,
      align: 'right',
      render: ({ biomassa_vendida }: any) => {
        return Formatter.formatNumber(3, biomassa_vendida);
      },
    },
    {
      field: 'gp',
      title: 'GP (g)',
      width: 140,
      align: 'right',
      render: ({ gp }: any) => {
        return Formatter.formatNumber(3, gp);
      },
    },
    {
      field: 'gpd',
      title: 'GPD (g)',
      width: 140,
      align: 'right',
      render: ({ gpd }: any) => {
        return Formatter.formatNumber(3, gpd);
      },
    },
    {
      field: 'racao_consumida',
      title: 'Ração Consumida (Kg)',
      width: 140,
      align: 'right',
      render: ({ racao_consumida }: any) => {
        return Formatter.formatNumber(3, racao_consumida);
      },
    },
    {
      field: 'custo_racao',
      title: 'Custo Ração (R$)',
      width: 140,
      align: 'right',
      render: ({ custo_racao }: any) => {
        return Formatter.formatNumber(2, custo_racao);
      },
    },
    {
      field: 'ca',
      title: 'CA',
      width: 140,
      align: 'right',
      render: ({ ca }: any) => {
        return Formatter.formatNumber(3, ca);
      },
    },
    {
      field: 'produtividade',
      title: 'Produtividade(KG/M2 ou M3)',
      width: 140,
      align: 'right',
      render: ({ produtividade }: any) => {
        return Formatter.formatNumber(3, produtividade);
      },
    },
  ];

  async function getPDF(): Promise<void> {
    try {
      const pdf = await apiMain.get(`/gerador/pdf/resumo/${store.propriedade?.id}/tanques/`, {
        params: {
          authori: store.token,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  async function getCSV(): Promise<void> {
    try {
      const csv = await apiMain.get(`/gerador/csv/resumo/${store.propriedade?.id}/tanques/`, {
        params: {
          authori: store.token,
        },
      });

      window.open(csv.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
    }
  }

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Link
          to={'/relatorios/relatorios-zootecnicos'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto', marginRight: '10px' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>

        <Flex>
          <FormControlLabel
            style={{ marginLeft: 15 }}
            control={
              <Checkbox
                checked={mostrarTodosTanques}
                onChange={(e: any) => {
                  setMostrarTodosTanques(e.target.checked);
                  refreshTable();
                }}
                color="primary"
              />
            }
            label="Mostrar Todos"
          />
          <Tooltip title="Exportar para Excel">
            <IconButton className="blue" onClick={getCSV}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filtro Avançado">
            <IconButton
              className="blue"
              onClick={() => null}
              //  onClick={modalFiltros.open}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton className="blue" onClick={sync}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <Conteudo style={{ padding: 0 }}>
        {store.propriedade && (
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            title={'Resumo de Tanques'}
            data={(query) =>
              new Promise((resolve, reject) => {
                aqReports
                  .get('/resumo/' + store.propriedade?.uuid + '/tanques', {
                    params: {
                      page: query.page + 1,
                      item_count: 25,
                    },
                  })
                  .then((res) => {
                    if (res.data.count) {
                      if (res.data.count >= 25) {
                        state.setPageSize(25);
                      } else {
                        state.setPageSize(res.data.count);
                      }
                    } else {
                      state.setPageSize(0);
                    }

                    let data = res.data.results?.filter((row: any) => row.estoque_atual > 0);

                    if (mostrarTodosTanques) {
                      data = res.data.results;
                    }

                    data = data.sort((a: any, b: any) => {
                      if (a.tanque.nome < b.tanque.nome) {
                        return -1;
                      }
                      if (a.tanque.nome < b.tanque.nome) {
                        return 1;
                      }
                      return 0;
                    });

                    state.rows = data;

                    resolve({
                      data,
                      page: res.data.page - 1,
                      totalCount: res.data.count_total,
                    });
                  })
                  .catch((err) => {
                    X9Logger.report(err);
                    store.notificar('Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                    reject();
                  });
              })
            }
            onRowClick={getResumoIndividual}
            options={{
              ...newConfig.options,
              pageSize: 25,
              toolbar: true,
            }}
            localization={newConfig.localization}
            style={newConfig.style}
          />
        )}
      </Conteudo>

      <FiltroAvancado
        open={modalFiltros.state}
        handleClose={modalFiltros.close}
        onConfirm={sync}
        onClear={limparFiltros}
      >
        <Divisor>
          <p>CA (g)</p>
        </Divisor>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputNumero
              value={state.filtroCA[0]}
              onChange={(value) => (state.filtroCA[0] = value)}
              precision="2"
              label="Valor Mínimo"
            />
          </Grid>
          <Grid item xs={6}>
            <InputNumero
              value={state.filtroCA[1]}
              onChange={(value) => (state.filtroCA[1] = value)}
              precision="2"
              label="Valor Máximo"
            />
          </Grid>
        </Grid>
        <Divisor>
          <p>Biomassa (kg)</p>
        </Divisor>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputNumero
              value={state.filtroBiomassa[0]}
              onChange={(value) => (state.filtroBiomassa[0] = value)}
              precision="2"
              label="Valor Mínimo"
            />
          </Grid>
          <Grid item xs={6}>
            <InputNumero
              value={state.filtroBiomassa[1]}
              onChange={(value) => (state.filtroBiomassa[1] = value)}
              precision="2"
              label="Valor Máximo"
            />
          </Grid>
        </Grid>
        <Divisor>
          <p>Data de finalização</p>
        </Divisor>
        <div style={{ width: '90%' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Início"
            value={state.filtroDataInicio}
            onChange={(date) => (state.filtroDataInicio = date)}
          />
        </div>
        <div style={{ width: '90%' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Fim"
            value={state.filtroDataFim}
            onChange={(date) => (state.filtroDataFim = date)}
          />
        </div>
      </FiltroAvancado>
    </NewBody>
  ) : null;
};

export default observer(ResumoTanques);
