import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import Botao from '../../../components/Botoes/Botao';
import QuantidadeModal from '../Povoamento/QuantidadeModal';
import useModal from '../../../services/hooks/useModal';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';
import { useLocalStore } from 'mobx-react-lite';
import { LoteTanqueInfo } from '../../../components/LoteTanqueInfo';
import { Tooltip, FormControlLabel, Checkbox, TextField } from '@material-ui/core';

type Props = ModalProps & {
  cancelar(): void;
  addBancada(tanqueID: number, quantidade: string, plsGrama: number, biomassa: number): void;
  selectedBancadas: SelectedTanque[];
  especie?: string;
};
const SelecionarBancada: React.FC<Props> = ({ open, handleClose, selectedBancadas, cancelar, addBancada, especie }) => {
  const store = useStore();
  const [codigo, setCodigo] = useState<string>('');
  const [tanques, setBancadas] = useState<Tanque[]>([] as Tanque[]);
  const [filtro, setFiltro] = useState<boolean>(false);
  const [selectedBancada, setSelectedBancada] = useState<Tanque | null>(null);
  const modalQuantidade = useModal();
  const state = useLocalStore(() => ({
    sync: false,
    filtroRapidoFinalizado: false,
  }));

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchBancada() {
      store.toggleLoader();
      await apiV2
        .get('/tanque/modals/', {
          params: {
            prop: store.propriedade!.id,
            nome: codigo,
            bancadas: true,
          },
        })
        .then((res) => {
          setBancadas(res.data.results);
        });

      state.sync = true;
      store.toggleLoader();
    }
    searchBancada();

    // eslint-disable-next-line
  }, [selectedBancadas, codigo, setCodigo]);

  const BancadasDisponiveis: React.FC = () => (
    <>
      {tanques?.length > 0 &&
        tanques.map((tanque: any) => {
          const selected = selectedBancadas?.some((obj) => tanque?.id === obj?.item?.id);
          return (
            <LoteTanqueInfo
              selected={selected}
              onClick={() => {
                setSelectedBancada(tanque);
                modalQuantidade.open();
              }}
              key={tanque.id}
            >
              <p className="left">{tanque.nome || tanque.codigo}</p>
              <p className="right">
                {tanque.lotes && tanque.lotes.length > 0 ? (
                  <Tooltip
                    title={tanque.lotes.map((lote: any) => {
                      return `${lote.codigo || lote.nome}, `;
                    })}
                  >
                    <span>{tanque.lotes[0].codigo + '...'}</span>
                  </Tooltip>
                ) : (
                  ''
                )}{' '}
                ({Formatter.formatNumber(0, tanque.estoque)})
              </p>
              <b className="left" style={{ color: '#00C853' }}>
                {Formatter.formatNumber(3, tanque.biomassa)} Kg
              </b>
              <p className="right" style={{ color: '#00C853' }}>
                {tanque.lotes && tanque.lotes.length > 0 ? tanque.lotes[0].fase_lote.nome : tanque.situacao_tanque.nome}
                {Formatter.formatNumber(3, tanque.estoque ? tanque.biomassa : 0)} Kg
              </p>

              {selected && (
                <>
                  <b className="left" style={{ color: '#C80000' }}>
                    Povoamento
                  </b>
                  <b className="right" style={{ color: '#C80000' }}>
                    +
                    {Formatter.formatNumber(
                      0,
                      selectedBancadas.filter((obj) => tanque.id === obj.item.id)[0].quantidade,
                    )}{' '}
                    un
                  </b>
                </>
              )}
            </LoteTanqueInfo>
          );
        })}
    </>
  );
  const BancadasDisponiveisFiltrado: React.FC = () => (
    <>
      {tanques?.length > 0 &&
        tanques.map((tanque: any) => {
          const selected = selectedBancadas?.some((obj) => tanque?.id === obj?.item?.id);
          return tanque.estoque === 0 ? (
            <LoteTanqueInfo
              selected={selected}
              onClick={() => {
                setSelectedBancada(tanque);
                modalQuantidade.open();
              }}
              key={tanque.id}
            >
              <p className="left">{tanque.nome || tanque.codigo}</p>
              <p className="right">
                {tanque.lotes && tanque.lotes.length > 0 ? (
                  <Tooltip
                    title={tanque.lotes.map((lote: any) => {
                      return `${lote.codigo || lote.nome}, `;
                    })}
                  >
                    <span>{tanque.lotes[0].codigo + '...'}</span>
                  </Tooltip>
                ) : (
                  ''
                )}{' '}
                ({Formatter.formatNumber(0, tanque.estoque)})
              </p>
              <b className="left" style={{ color: '#00C853' }}>
                {Formatter.formatNumber(3, tanque.biomassa)} Kg
              </b>
              <p className="right" style={{ color: '#00C853' }}>
                {tanque.situacao_tanque.nome}
              </p>

              {selected && (
                <>
                  <b className="left" style={{ color: '#C80000' }}>
                    Povoamento
                  </b>
                  <b className="right" style={{ color: '#C80000' }}>
                    +
                    {Formatter.formatNumber(
                      0,
                      selectedBancadas.filter((obj) => tanque.id === obj.item.id)[0].quantidade,
                    )}{' '}
                    un
                  </b>
                </>
              )}
            </LoteTanqueInfo>
          ) : null;
        })}
    </>
  );

  return state.sync ? (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Selecionar Bancadas
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setFiltro(e.target.checked);
              }}
              color="primary"
            />
          }
          label="Mostrar Todos"
        />
      </ModalHeader>
      <ModalBody>
        <div
          style={{ color: '#707070', width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-between' }}
        >
          <TextField
            label="Pesquisa Pelo Nome"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
          />
        </div>

        {filtro ? <BancadasDisponiveis /> : <BancadasDisponiveisFiltrado />}
      </ModalBody>
      <div style={{ width: '100%', display: 'flex', padding: '10px', minWidth: '490px' }}>
        <Botao cor="gray" onClick={cancelar}>
          Voltar
        </Botao>
        <Botao cor="#00C853" onClick={handleClose}>
          Confirmar
        </Botao>
      </div>
      {modalQuantidade.state ? (
        <QuantidadeModal
          especie={especie}
          onConfirm={addBancada}
          tanque={selectedBancada}
          quantidadeDefault={
            selectedBancadas.filter((tanque) => tanque.item.id === selectedBancada?.id).length > 0
              ? selectedBancadas.filter((tanque) => tanque.item.id === selectedBancada?.id)[0].quantidade
              : undefined
          }
          open={modalQuantidade.state}
          handleClose={modalQuantidade.close}
        />
      ) : null}
    </Modal>
  ) : null;
};

export default SelecionarBancada;
