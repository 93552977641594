/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Body } from '../../components/Body';
import Display from '../../components/Tabela/Display';
import useStore from '../../services/hooks/useStore';
import Botao from '../../components/Botoes/Botao';
import EditarTanque from './EditarTanque';
import { returnEspecie, returnFase } from '../../services/utils/propriedade';
import { Info } from '../../components/Historico/styles';
import Historico from '../../components/HistoricoV2';
import useModal from '../../services/hooks/useModal';
import Confirmar from '../../components/Modais/Confirmar';
import { useLocalStore, observer } from 'mobx-react-lite';
import syncModel from '../../services/models/Sync';
import Formatter from '../../services/Formatter';
import { evaluate } from 'mathjs';
import { apiV2 } from '../../services/utils';
import { permissoes } from '../../services/utils/permissoes';

const HistoricoTanque: React.FC = () => {
  const { id }: { id: string } = useParams();

  const location: any = useLocation();
  const store = useStore();
  const history = useHistory();
  const state = useLocalStore(() => ({
    tanque: store.tanques.arr ? store.tanques.get(parseInt(id)) : null,
    historico: {} as Historico2,
    historico2: {},
    sync: false,
  }));
  const modalExclusao = useModal();
  const modalEditar = useModal();
  const temPermissaoEscrita = store.checkPermissions(permissoes.PROPRIEDADE_ESCRITA);

  async function sync(): Promise<void> {
    await syncModel.historicoTanque();
    state.sync = true;
    store.toggleLoader();

    await apiV2
      .get(`/tanque/list-historico/${id}/`, {
        params: {
          propriedade: store.propriedade!.id,
        },
      })
      .then((res: any) => {
        state.tanque = res.data;
      });
    if (state.tanque) {
      state.historico2 = (await store.tanques.getHistorico2(parseInt(id), store.propriedade!.id)) as never;
    } else {
      history.push('/propriedade/tanques');
    }

    store.toggleLoader();
  }

  React.useEffect(() => {
    if (store.propriedade) {
      sync();
    }
  }, []);

  // Filtra so lotes finalizado para mostrar o peso médio do tanque
  const lote = state.tanque?.lotes.filter((lote) => lote.fase_lote.slug !== 'finalizado' && lote.estoque);

  return state.sync ? (
    <Body gridColumns="350px 1fr">
      <div>
        {state.tanque && state.tanque.lotes.length ? (
          <Display
            back={() =>
              history.push({
                pathname: '/propriedade/tanques',
                state: { page: location.state ? location.state.page : null },
              })
            }
            title={state.tanque?.nome || ''}
          >
            <Info>
              <b>Nome:</b> {state.tanque?.nome ? state.tanque.nome : 'Sem nome'}
            </Info>
            <Info>
              <b>Tamanho (m²/m³):</b> {Formatter.formatNumber(2, state.tanque?.tamanho) || 0}
            </Info>
            <Info>
              <b>Capacidade (Kg):</b> {Formatter.formatNumber(3, state.tanque?.capacidade) || 0}
            </Info>
            <Info>
              <b>Origem da água:</b> {state.tanque?.agua_origem.nome}
            </Info>
            <Info>
              <b>Tipo de tanque:</b> {state.tanque?.tanque_tipo.nome}
            </Info>
            <Info>
              <b>Espécie:</b> {state.tanque && returnEspecie(state.tanque.estoque, state.tanque.lotes)}
            </Info>
            <Info>
              <b>Lote:</b>{' '}
              {state.tanque && state.tanque.lotes.length
                ? state.tanque.lotes.map((lote) => lote.codigo).join(', ')
                : 'Sem Lote'}
            </Info>
            <Info>
              <b>Fase:</b> {state.tanque && returnFase(state.tanque.estoque, state.tanque.lotes)}
            </Info>
            <Info>
              <b>Peso médio (g):</b>{' '}
              {state.tanque &&
                Formatter.formatNumber(
                  3,
                  state.tanque
                    ? lote?.reduce((prev, next: any) => {
                        return prev + next.peso_medio;
                      }, 0)
                    : 0,
                )}
            </Info>
            <Info>
              <b>Qtd de animais:</b> {state.tanque ? Formatter.formatNumber(0, state.tanque.estoque) : 0}
            </Info>
            <Info>
              <b>Biomassa (Kg):</b>{' '}
              {state.tanque &&
                Formatter.formatNumber(
                  3,
                  state.tanque.estoque
                    ? lote?.reduce((prev, next: any) => {
                        return prev + evaluate(`(${next.peso_medio} * ${state.tanque?.estoque}) / 1000`);
                      }, 0)
                    : 0,
                )}
            </Info>
          </Display>
        ) : (
          <Display
            back={() =>
              history.push({
                pathname: '/propriedade/tanques',
                state: { page: location.state ? location.state.page : null },
              })
            }
            title={state.tanque?.nome || state.tanque?.codigo || ''}
          >
            <Info>
              <b>Nome:</b> {state.tanque?.nome ? state.tanque.nome : 'Sem nome'}
            </Info>
            <Info>
              <b>Tamanho (m²/m³):</b> {Formatter.formatNumber(2, state.tanque?.tamanho) || 0}
            </Info>
            <Info>
              <b>Capacidade (Kg):</b> {Formatter.formatNumber(3, state.tanque?.capacidade) || 0}
            </Info>
            <Info>
              <b>Origem da água:</b> {state.tanque?.agua_origem.nome}
            </Info>
            <Info>
              <b>Tipo de tanque:</b> {state.tanque?.tanque_tipo.nome}
            </Info>
            <Info>
              <b>Espécie:</b> {state.tanque && returnEspecie(state.tanque.estoque, state.tanque.lotes)}
            </Info>
            <Info>
              <b>Lote:</b>{' '}
              {state.tanque && state.tanque.lotes.length
                ? state.tanque.lotes.map((lote) => lote.codigo).join(', ')
                : 'Sem Lote'}
            </Info>
            <Info>
              <b>Fase:</b> {state.tanque && returnFase(state.tanque.estoque, state.tanque.lotes)}
            </Info>
            <Info>
              <b>Peso médio (g):</b>{' '}
              {state.tanque &&
                Formatter.formatNumber(
                  3,
                  state.tanque
                    ? lote?.reduce((prev, next: any) => {
                        return prev + next.peso_medio;
                      }, 0)
                    : 0,
                )}
            </Info>
            <Info>
              <b>Qtd de animais:</b> {state.tanque ? Formatter.formatNumber(0, state.tanque.estoque) : 0}
            </Info>
            <Info>
              <b>Biomassa (Kg):</b>{' '}
              {state.tanque &&
                Formatter.formatNumber(
                  3,
                  state.tanque.estoque
                    ? lote?.reduce((prev, next: any) => {
                        return prev + evaluate(`(${next.peso_medio} * ${state.tanque?.estoque}) / 1000`);
                      }, 0)
                    : 0,
                )}
            </Info>
          </Display>
        )}

        <div style={{ margin: '0 10px', display: 'flex' }}>
          <Botao disabled={!temPermissaoEscrita} onClick={() => modalExclusao.open()} variant="contained" cor="#FC7467">
            Excluir
          </Botao>
          <Botao disabled={!temPermissaoEscrita} onClick={modalEditar.open} variant="contained" cor="#00C853">
            Editar
          </Botao>
        </div>
      </div>

      <Display heightOffset="80px" title="Histórico do tanque" sync={sync}>
        {state.historico2 && Object.keys(state.historico2).length ? (
          <Historico historico={state.historico2 as Historico2} sync={sync} />
        ) : (
          <p style={{ width: '100', textAlign: 'center', margin: '10px' }}>
            Não existe nenhum registro para esse lote!
          </p>
        )}
      </Display>

      <Confirmar
        title="Tem certeza que deseja excluir?"
        open={modalExclusao.state}
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={() => {
          modalExclusao.close();
          store.tanques.excluir(parseInt(id));
        }}
      />
      {state.tanque && <EditarTanque open={modalEditar.state} tanque={state.tanque} handleClose={modalEditar.close} />}
    </Body>
  ) : null;
};

export default observer(HistoricoTanque);
