import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Body } from '../../../components/Body';
import PainelNavigationTab from './PainelNavigationTab';
import MaterialTable, { Column } from 'material-table';
import Formatter from '../../../services/Formatter';
import useStore from '../../../services/hooks/useStore';
//import { useHistory } from 'react-router-dom';
import { newConfig } from '../../../components/Tabela';
import { aqReports } from '../../../services/utils';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Flex } from '../../../components/NavBar/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { IconButton, Tooltip } from '@material-ui/core';

type RelLista = {
  data: string;
  documento: string;
  nome: string;
  quantidade: number;
  valor_total: number;
  valor_unitario: number;
};

type RelCompras = {
  data: string;
  lista: Array<RelLista>;
  qtd_total: number;
  total: number;
};

const FormRelatorioRacoes: React.FC = () => {
  const store = useStore();
  //const history = useHistory();
  //const tableRef = useRef(null);

  const [tableData, setTableData] = useState<RelLista[]>([]);
  // @ts-ignore
  const [relData, setRelData] = useState<RelCompras[]>([]);

  const [filtroApply, setFiltroApply] = useState(false);
  const [filtroDate, setFiltroDate] = useState({
    dataInicial: {
      value: null as MaterialUiPickersDate,
      valueFormatted: '',
    },
    dataFinal: {
      value: null as MaterialUiPickersDate,
      valueFormatted: '',
    },
  });

  const columns: Column<RelLista>[] = [
    {
      field: 'nome',
      title: 'Cliente',
      type: 'string',
      headerStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'quantidade',
      title: 'Quant.',
      type: 'numeric',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
      },
      render: (rowData) => {
        return Formatter.formatNumber(0, rowData.quantidade);
      },
      ...(true && ({ width: 60 } as object)),
    },
    {
      field: 'valor_unitario',
      title: 'Preço de Venda',
      type: 'numeric',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
      },
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.valor_unitario);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'valor_total',
      title: 'Valor (R$)',
      type: 'numeric',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
      },
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.valor_total);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'documento',
      title: 'Núm.',
      type: 'string',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'data',
      title: 'Data',
      type: 'string',
      headerStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
      },
      ...(true && ({ width: 100 } as object)),
    },
  ];

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Relatório Financeiro'), []);

  useEffect(() => {
    async function syncTable() {
      aqReports
        .get('/financeiro/' + store.propriedade?.uuid + '/compra-racao', {
          params: {
            data_inicio: filtroDate.dataInicial.valueFormatted,
            data_fim: filtroDate.dataFinal.valueFormatted,
          },
        })
        .then((res) => {
          let tableList: RelLista[] = [];
          let relList: RelCompras[] = [];
          const results = res.data.results;
          results.forEach((element: RelCompras) => {
            relList.push(element);
            element.lista.forEach((lista: RelLista) => {
              tableList.push(lista);
            });
          });
          setTableData(tableList);
          setRelData(relList);
        });
    }

    if (filtroApply && store.propriedade) {
      syncTable();
      setFiltroApply(false);
    }

    // eslint-disable-next-line
  }, [store.propriedade, filtroApply]);

  return (
    <>
      <Body>
        <PainelNavigationTab />
        <Flex style={{ width: '100vw', height: '10vh' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Inícial"
            value={filtroDate.dataInicial.value}
            onChange={(date) => {
              setFiltroDate({
                ...filtroDate,
                dataInicial: {
                  value: date,
                  valueFormatted: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                },
              });
              setFiltroApply(true);
            }}
          />
          <div style={{ fontSize: '18px', textAlign: 'center', width: '5vw', height: '10vh', padding: '7vh 0' }}>
            até
          </div>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Final"
            value={filtroDate.dataFinal.value}
            onChange={(date) => {
              setFiltroDate({
                ...filtroDate,
                dataFinal: {
                  value: date,
                  valueFormatted: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                },
              });
              setFiltroApply(true);
            }}
          />
          <div style={{ justifyContent: 'right' }}>
            <Tooltip title="Exportar para Excel">
              <IconButton className="blue" onClick={() => store.notificar('Em Breve')}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Baixar PDF">
              <IconButton className="blue" onClick={() => store.notificar('Em Breve')}>
                <AssignmentIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Flex>
        <MaterialTable
          columns={columns}
          data={tableData}
          options={{
            ...newConfig.options,
            paging: true,
            sorting: false,
            pageSize: 10,
          }}
          localization={newConfig.localization}
          style={newConfig.style}
        />
      </Body>
    </>
  );
};

export default observer(FormRelatorioRacoes);
