import { apiMain, getPdfV2Recibos } from '../utils';
import { stateContext } from '../hooks/useStore';

export const relatoriosProd = [
  { label: 'Visão geral', value: 'visao-geral' },
  { label: 'Desempenho dos lotes', value: 'desempenho-lotes' },
  { label: 'Resumo de Lotes Finalizados', value: 'resumo-lotes' },
  { label: 'Resumo de Tanques', value: 'resumo-tanques' },
  { label: 'Estoque de Tanques', value: 'estoque-tanques' },
  { label: 'Tanques Com Programa Alimentar', value: 'tanques-programa-alimentar' },
  { label: 'Consumo de Ração', value: 'total-racoes' },
  { label: 'Resumo Consumo de Ração', value: 'resumo-total-racoes' },
  { label: 'Resumo Consumo de Insumos', value: 'resumo-total-insumos' },
  { label: 'Curva de Crescimento', value: 'crescimento' },
  { label: 'Mortalidades', value: 'listagem-mortalidade' },
  { label: 'Ração por Tanque', value: 'racao-tanque' },
];

export const relatoriosVendas = [
  { label: 'Resumo de Vendas', value: 'resumo-vendas' },
  { label: 'Listagem de Vendas', value: 'listagem-vendas' },
];



export async function getRelatorio(endpoint: string, options: object): Promise<void> {
  stateContext.state.toggleLoader();

  await getPdfV2Recibos
    .get(endpoint, { ...options })
    .then((res) => {
      const relatorio = res.data;

      const win = window.open('', 'Print', 'left=200, top=200, width=750, height=500, toolbar=0, resizable=0');
      win && (win.document.body.innerHTML = relatorio);
      setTimeout(() => {
        win?.print();
      }, 1000);
    })
    .catch(() => stateContext.state.notificar('Um erro ocorreu ao gerar o relatório'));

  stateContext.state.toggleLoader();
}
export async function getRelatorio2(endpoint: string,prop:any,token:any): Promise<void> {
  stateContext.state.toggleLoader();

  await apiMain
    .get(endpoint,{
      params:{
        propriedade:prop,
        authori:token,
      }
    })
    .then((res:any) => {
      const relatorio = res.data;

      const win = window.open('', 'Print', 'left=200, top=200, width=750, height=500, toolbar=0, resizable=0');
      win && (win.document.body.innerHTML = relatorio);
      setTimeout(() => {
        win?.print();
      }, 1000);
    })
    .catch(() => stateContext.state.notificar('Um erro ocorreu ao gerar o relatório'));

  stateContext.state.toggleLoader();
}
