import React, { useEffect } from 'react';
//componentes e botoes
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import Display from '../../../components/Tabela/Display';

import { Body } from '../../../components/Body';
import ActionButton from '../../../components/Tabela/ActionButton';
import Confirmar from '../../../components/Modais/Confirmar';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { Checkbox, FormControlLabel } from '@mui/material';
import MaterialTable, { Column } from 'material-table';
import { config } from '../../../components/Tabela';

import { MenuList, MenuItem } from '@material-ui/core';

//forms
import FormCompra from '../Racao/FormCompra';
import FormRacao from '../Racao/FormRacao';

//api
import { api } from '../../../services/utils';

//modais
import useModal from '../../../services/hooks/useModal';

//hooks
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import RacaoPageState from '../Insumos/models/racaoPageState';
import AjusteEstoque from '../Racao/AjusteEstoque';
import { CompraInfo } from '../Insumos/styles';
import { permissoes } from '../../../services/utils/permissoes';

type State = PageState & {
  filtroRapidoRacao: SelectRow | null;
  filtroRacao: SelectRow | null;
  filtroFornecedor: SelectRow | null;
  filtroDataInicio: MaterialUiPickersDate;
  filtroDataFim: MaterialUiPickersDate;

  selectedRacao: number;
  selectedCompra: number;

  sync: boolean;

  /** Novos Filtros */
  filtroRapidoFinalizado: boolean;
  ativo: number;
  racao: Racao;
  filterUrlString: string;
  filterUrlArray: string[];
};
const Estoque: React.FC = () => {
  const modalCompra = useModal();
  const modalExclusao = useModal();
  const modalAjuste = useModal();
  const modalRacao = useModal();
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.CONTROLE_RACAO_ESTOQUE_LEITURA, history);

  const state = useLocalStore(
    (): State => ({
      pageSize: 0,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },
      filtroRapidoRacao: null,

      filtroRacao: null,
      filtroFornecedor: null,
      filtroDataInicio: null,
      filtroDataFim: null,

      selectedRacao: 0,
      selectedCompra: 0,

      sync: false,

      //novos filtros
      filtroRapidoFinalizado: false,
      ativo: 0,
      racao: {} as Racao,
      filterUrlString: '',
      filterUrlArray: [],
    }),
  );
  //colunas
  const columns: Column<object>[] = [
    {
      field: 'nome',
      title: 'Ração',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          editar={
            store.checkPermissions(permissoes.CONTROLE_RACAO_ESTOQUE_ESCRITA)
              ? () => {
                state.selectedRacao = rowData.id;
                modalRacao.open();
              }
              : undefined
          }
          excluir={
            store.checkPermissions(permissoes.CONTROLE_RACAO_ESTOQUE_ESCRITA)
              ? () => {
                state.selectedRacao = rowData.id;
                modalExclusao.open();
              }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'marca',
      title: 'Marca',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'tamanho',
      title: 'Tamanho (mm)',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'proteina_bruta',
      title: 'Proteína (%)',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'status',
      title: 'Ativa',
      type: 'string',
      render({ ativo }: any) {
        return ativo;
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'data',
      title: 'Data Inicio',
      type: 'date',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'estoque_atual',
      title: 'Estoque Atual (SC)',
      type: 'numeric',
      render: ({ estoque_atual, controla_sc, quantidade_kg_sc }: any) => {

        return controla_sc ? Formatter.formatNumber(0, estoque_atual / quantidade_kg_sc) : '-';
      },
      ...(true && ({ width: 180 } as object)),
    },


    {
      field: 'estoque_atual',
      title: 'Estoque Atual (kg)',
      type: 'numeric',
      render: ({ estoque_atual, controla_sc }: any) => {
        return controla_sc ? Formatter.formatNumber(2, estoque_atual) : Formatter.formatNumber(2, estoque_atual);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'estoque_minimo',
      title: 'Estoque Minimo (SC)',
      type: 'numeric',
      render: ({ estoque_minimo, controla_sc, quantidade_kg_sc }: any) => {
        return controla_sc ? Formatter.formatNumber(0, estoque_minimo / quantidade_kg_sc) : '-';
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'estoque_minimo',
      title: 'Estoque Minimo (kg)',
      type: 'numeric',
      render: ({ estoque_minimo, controla_sc }: any) => {
        return controla_sc ? Formatter.formatNumber(2, estoque_minimo) : Formatter.formatNumber(2, estoque_minimo);
      },
      ...(true && ({ width: 180 } as object)),
    },
  ];
  //linhas
  const tableListRows = (_store: any, state: any, racoes: Racao[]): object[] => {
    ajusteTamanhoTabela(state, racoes);
    return racoes
      .filter((racao) => {
        if (state.filtroRapidoFinalizado) return racao; // mostra todos
        return racao.estoque > 0; // mostra só os que tem estoque maior que zero
      })
      .map((racao) => {
        const marcaDado = store.marcas.arr.find((m) => m.uuid === racao.marca);

        return {
          controla_sc: racao.controla_sc,
          peso_saco: racao.peso_saco,
          id: racao.id,
          nome: racao.nome,
          marca: marcaDado?.nome ?? '-',
          tamanho: racao.tamanho,
          proteina_bruta: racao.proteina_bruta,
          estoque: racao.estoque + ' ' + racao.tipo_unidade,
          estoque_atual: racao.estoque,
          estoque_minimo: racao.estoque_minimo,
          quantidade_kg_sc: racao.quantidade_kg_sc,
          data: racao.data_ini_estoque ? racao.data_ini_estoque.split(' ')[0] : '',
          row: racao,
          ativo: racao.ativo ? 'Sim' : 'Nao',
        };
      });
  };
  const ajusteTamanhoTabela = (state: State, racoes: Racao[]): void => {
    if (racoes.length) {
      if (racoes.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(racoes.length);
      }
    } else {
      state.setPageSize(0);
    }
  };

  async function sync(state: RacaoPageState): Promise<void> {
    await syncModel.compraDeRacoes(state.ativo);
    store.toggleLoader();
    await store.comprasDeRacoes.populate();
    state.sync = true;
    store.toggleLoader();
  }

  async function deletar(): Promise<void> {
    const racao = store.racoes!.get(state.selectedRacao);
    modalExclusao.close();
    store.toggleLoader();

    await api
      .delete(`/racao/${racao.id}/?propriedade=${store.propriedade!.id}`)
      .then(() => {
        store.notificar('Racao excluída com sucesso!');
      })
      .catch(() => store.notificar('Não foi possível excluir pois existem registros para esse produto!'));

    await sync(state);
    store.toggleLoader();
  }

  useEffect(() => store.setTitulo('Estoque de Ração'), []);

  useEffect(() => {
    if (state.filtroRapidoRacao) {
      state.filterUrlArray.push(`&racao=${state.filtroRapidoRacao.value}`);
      state.filterUrlString = state.filterUrlArray.join('');
      store.racoes.getPaginated(0, 25, store.propriedade!.id, state.filterUrlString);
    }
  }, [state.filtroRapidoRacao]);

  useEffect(() => {
    store.propriedade && sync(state);
  }, [store.propriedade]);

  const ExcluirRegistro: React.FC = () => {
    const registro = store.racoes.get(state.selectedRacao);
    return (
      <div style={{ width: '100%' }}>
        <br />
        <b>Ração: </b> {registro && registro.nome}
        <br />
        <b>Estoque: </b>
        {registro && registro.estoque} kg
        <br />
        {registro.data_ini_estoque ? (
          <b>Data Inicial Estoque : {registro.data_ini_estoque}</b>
        ) : (
          'Sem data inicial de estoque !'
        )}
        <br />
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!store.checkPermissions(permissoes.CONTROLE_RACAO_ESTOQUE_ESCRITA)}
            dropdown
            onClick={() => {
              state.selectedRacao = 0;
              modalRacao.open();
            }}
            text="NOVA RAÇÃO"
            Icon={AddIcon}
          >
            <MenuList>
              <MenuItem onClick={modalAjuste.open}>Ajuste de estoque</MenuItem>
            </MenuList>
          </AddBtn>

          {/* Checkbox */}
          <FormControlLabel
            style={{ marginLeft: 15 }}
            control={
              <Checkbox
                checked={state.filtroRapidoFinalizado}
                onChange={(_e, checked) => {
                  if (state.filtroRapidoFinalizado) {
                    state.filtroRapidoFinalizado = false;
                    state.ativo = 0;
                    sync(state);
                  } else {
                    state.filtroRapidoFinalizado = checked;
                    state.ativo = 1;
                    sync(state);
                  }
                }}
                color="primary"
              />
            }
            label="Mostrar Todos"
          />
        </Flex>
      </Header>

      <Body gridColumns="1fr 500px">
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.racoes.arr && (
            <>
              <MaterialTable
                columns={columns}
                onChangePage={(page: number, pageSize: number) => {
                  if (store.propriedade) {
                    store.racoes.getPaginated(page, pageSize, store.propriedade.id, state.filterUrlString);
                  }
                }}
                //
                data={store.racoes.arr ? tableListRows(store, state, store.racoes.arr) : []}
                options={{ ...config.options, pageSize: state.pageSize }}
                localization={config.localization}
                style={config.style}
              />
            </>
          )}
        </div>
        <div>
          <Display
            verTudo={() => {
              history.push('/producao/racao');
            }}
            style={{ marginBottom: '10px', height: '55vh' }}
            overflowY
            title="Histórico de Compras/Ajustes"
          >
            {store.comprasDeRacoes?.map((compraRacao) => {
              const stringData = compraRacao.data.split(' ')[0];
              const valorUnitario =
                compraRacao.valor_compra !== 0 ? compraRacao.valor_compra / compraRacao.total_kg : 0;

              return (
                <CompraInfo
                  key={compraRacao.id}
                  onClick={() => {
                    state.selectedCompra = compraRacao.id;
                    modalCompra.open();
                  }}
                >
                  <p className="left">{stringData}</p>
                  <p className="right">Vr. Total: R$ {compraRacao.valor_compra}</p>
                  <p className="left">{compraRacao?.racao.nome}</p>
                  <p className="right">Qtde: {compraRacao.total_kg}</p>
                  <p className="left">
                    {compraRacao.ajuste_estoque ? `Ajuste de Estoque - ${compraRacao.tipo === 'entrada' ? "Entrada" : "Saída"}` : 'Compras'}
                  </p>
                  <p className="right">Vr. Unit: R$ {Formatter.formatNumber(2, valorUnitario)}</p>
                </CompraInfo>
              );
            })}
          </Display>
        </div>
      </Body>

      {modalCompra.state ? (
        <FormCompra open={modalCompra.state} handleClose={modalCompra.close} compraID={state.selectedCompra} />
      ) : null}
      {modalAjuste.state ? <AjusteEstoque open={modalAjuste.state} handleClose={modalAjuste.close} /> : null}
      {modalRacao.state ? (
        <FormRacao open={modalRacao.state} handleClose={modalRacao.close} racaoID={state.selectedRacao} />
      ) : null}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirRegistro />}
        title="Tem certeza que deseja excluir essa raçao?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
    </>
  ) : null;
};

export default observer(Estoque);
