/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import useStore from '../../services/hooks/useStore';
import Header from '../../components/Header';
import { ParametroNome, FlexList, HeaderList, MinValor, MaxValor } from './styles';
import { Body } from '../../components/Body';
import { Flex, SelectPropriedade } from '../../components/NavBar/styles';
import { config } from '../../components/Tabela';
import AddBtn from '../../components/Botoes/AddBtn';
import AddIcon from '@material-ui/icons/Add';
import useModal from '../../services/hooks/useModal';
import Display from '../../components/Tabela/Display';
import { HeaderButton } from '../../components/Tabela/styles';
import MaterialTable, { Column } from 'material-table';
import { useHistory } from 'react-router-dom';
import { useLocalStore, observer } from 'mobx-react-lite';
import Filtro from '../../components/Filtro';
import EditarQualidade from './EditarQualidade';
import ListaTodosParametros from './ListaTodosParametros';
import CadastraQualidade from './CadastraQualidade';
import CadastrarQualidadeMuitosTanques from './CadastrarQualidadeMuitosTanques';
import Select from 'react-select';
import ReactApexChart from 'react-apexcharts';
import syncModel from '../../services/models/Sync';
import ActionButton from '../../components/Tabela/ActionButton';
import { returnTanque } from '../../services/utils/propriedade';
import Confirmar from '../../components/Modais/Confirmar';
import { api } from '../../services/utils';
import Formatter from '../../services/Formatter';
import X9Logger from '../../services/X9Logger';
import { ValorTabela } from './ValorTabela';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { permissoes } from '../../services/utils/permissoes';
import ApexCharts from 'apexcharts';
type State = PageState & {
  filtroTanque: SelectRow | null;
  filtroParametro: SelectRow | null;
  selectedAnalise: number;
  parametroSelected: SelectRow | null;
  tanqueSelected: SelectRow | null;
  parametroSelect: SelectRow[];
  filtroDataInicio: MaterialUiPickersDate;
  filtroDataFim: MaterialUiPickersDate;

  rows: QualidadeDaAgua[];
  showParametros: boolean;
  sync: boolean;
  colunas: Column<object>[];
};

const Qualidade: React.FC = () => {
  const store = useStore();
  const modalCadastro = useModal();
  const modalCadastroParaMuitosTanques = useModal();
  const modalEditar = useModal();
  const modalExclusao = useModal();
  const modalVisualizar = useModal();
  const history = useHistory();
  const state = useLocalStore(
    (): State => ({
      pageSize: 0,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },

      rows: [],
      filtroTanque: null,
      filtroParametro: null,

      selectedAnalise: 0,
      parametroSelected: null,
      tanqueSelected: null,
      parametroSelect: [{ label: 'Sem parâmetros ativos', value: '' }],
      filtroDataInicio: null,
      filtroDataFim: null,

      showParametros: false,
      sync: false,

      colunas: [],
    }),
  );

  store.propriedade && store.validarPermissao(permissoes.QUALIDADE_E_SANIDADE_QUALIDADE_DA_AGUA_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_QUALIDADE_DA_AGUA_ESCRITA);

  const tableRef: any = React.createRef();

  useEffect(() => {
    tableRef.current?.onQueryChange();
  }, [store.qualidadeDaAgua.arr, tableRef]);

  useEffect(() => {
    if (store.propriedade) {
      store.qualidadeDaAgua.getPaginated({ page: 0, page_size: 25, propriedade: store.propriedade.id });
    }
  }, [store.propriedade, store.qualidadeDaAgua]);

  async function syncQualidade(): Promise<void> {
    try {
      await syncModel.qualidadeDaAgua();

      state.sync = true;
    } catch (error) {
      store.notificar('Ocorreu um erro no carregamento dos seus dados, atualize a página!');
    }
  }

  useEffect(() => {
    store.propriedade && syncQualidade();
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    if (store.qualidadeDaAgua.arr?.length && state.sync) {
      let data: { x: string; y: number }[] = [];

      let unidade = '';

      store.qualidadeDaAgua.map((item: QualidadeDaAgua) => {
        return item.parametros_utilizados.map((parametro: ParametrosUtilizados) => {
          const tanqueId = state.tanqueSelected?.value;
          const parametroNome = state.parametroSelected?.label;
          if (parametro.parametro.nome === parametroNome && item.tanque.id.toString() === tanqueId) {
            data.push({
              x: item.data.split(' ')[0],
              y: parametro.value,
            });
            unidade = parametro.parametro.simbolo;
          }
          return null;
        });
      });

      try {
        data = data.slice(0, 6);
        ApexCharts.exec('qualidadeGraph', 'updateOptions', {
          yaxis: {
            labels: {
              formatter: (val: string) => {
                return Formatter.formatNumber(3, val) + ' ' + unidade;
              },
            },
          },
        });
        ApexCharts.exec('qualidadeGraph', 'updateSeries', [
          {
            data: data.reverse(),
          },
        ]);
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line
  }, [store.qualidadeDaAgua.arr, state.parametroSelected, state.tanqueSelected]);

  useEffect(() => {
    if (store.parametroValor.arr) {
      state.colunas = [
        {
          field: 'nome',
          title: 'Tanque',
          ...(true && ({ width: 140 } as object)),
        },
        {
          field: 'acoes',
          disableClick: true,
          render: (rowData: any) => (
            <ActionButton
              editar={
                temPermissaoEscrita
                  ? () => {
                      state.selectedAnalise = rowData.id;
                      modalEditar.open();
                    }
                  : undefined
              }
              excluir={
                temPermissaoEscrita
                  ? () => {
                      state.selectedAnalise = rowData.id;
                      modalExclusao.open();
                    }
                  : undefined
              }
            />
          ),
          ...(true && ({ width: 25 } as object)),
        },
        {
          field: 'data',
          title: 'Data',
          type: 'date',
          ...(true && ({ width: 100 } as object)),
        },
      ];

      store.parametroValor.map((item) => {
        if (item.used) {
          const valorMinimo = item.parametro.valor_minimo;
          const valorMaximo = item.parametro.valor_maximo;
          state.colunas.push({
            field: item.parametro.uuid,
            title: `${item.parametro.nome} (${item.parametro.simbolo})`,
            render: (rowData: any) => (
              <ValorTabela value={rowData[item.parametro.uuid]} min={valorMinimo} max={valorMaximo} />
            ),
            type: 'numeric',
            ...(true && ({ width: 200 } as object)),
          });
        }

        return null;
      });
    }
    // eslint-disable-next-line
  }, [store.parametroValor.arr]);

  const graficoQualidade = useLocalStore(() => ({
    series: [
      {
        name: 'Quantidade atual',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        id: 'qualidadeGraph',
        zoom: {
          enable: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(3, val) + ' ppm';
          },
        },
      },
    },
  }));

  function limparFiltro(): void {
    state.filtroTanque = null;
    state.filtroParametro = null;
    state.filtroDataInicio = null;
    state.filtroDataFim = null;
    syncQualidade();
  }

  async function deletar(): Promise<void> {
    modalExclusao.close();
    store.toggleLoader();
    await api
      .delete(`/analise-agua/${state.selectedAnalise}/?propriedade=${store.propriedade!.id}`)
      .then(() => {
        store.notificar('A análise foi excluída!');
      })
      .catch((error) => {
        console.log(error);
        store.notificar('Ocorreu um erro na exclusão, tente novamente!');
      });
    store.toggleLoader();

    syncQualidade();
  }

  async function filter(): Promise<void> {
    store.toggleLoader();
    await store.qualidadeDaAgua.getPaginated({
      page: 0,
      page_size: 25,
      propriedade: store.propriedade?.id ?? 0,
      tanque: state.filtroTanque?.value,
      parametro_valor: state.filtroParametro?.value,
      data_inicial: state.filtroDataInicio ? Formatter.dateToISOString(state.filtroDataInicio) : null,
      data_final: state.filtroDataFim ? Formatter.dateToISOString(state.filtroDataFim) : null,
    });
    store.toggleLoader();
  }

  const ExcluirQualidade: React.FC = observer(() => {
    const analises = store.qualidadeDaAgua.get(state.selectedAnalise);
    return (
      <div style={{ width: '100%' }}>
        <b>Tanque: </b> {analises && returnTanque(analises.tanque)}
        <br />
        <b>Data: </b>{' '}
        {analises && analises.data.substr(3, 2) + '/' + analises.data.substr(0, 2) + '/' + analises.data.substr(6, 4)}
        <br />
        {analises?.parametros_utilizados.map((item) => {
          return (
            <>
              <b>{item && item.parametro.nome}: </b> {item && Formatter.formatNumber(3, item.value)}
              <br />
            </>
          );
        })}
      </div>
    );
  });

  const SelectParametro = (
    <SelectPropriedade
      value={state.parametroSelected}
      options={state.parametroSelect}
      classNamePrefix="navSel"
      onChange={(e: any) => (state.parametroSelected = e)}
      maxMenuHeight={200}
    />
  );
  const SelectTanques = (
    <SelectPropriedade
      value={state.tanqueSelected}
      options={store.tanques.arr?.map((item) => {
        return {
          label: returnTanque(item),
          value: item.id.toString(),
        };
      })}
      classNamePrefix="navSel"
      onChange={(e: any) => (state.tanqueSelected = e)}
      maxMenuHeight={200}
    />
  );

  const OpenParametros = <HeaderButton onClick={() => modalVisualizar.open()}>Ver Todos</HeaderButton>;

  useEffect(() => {
    if (store.parametroValor.arr !== null) {
      const returner: SelectRow[] = [];

      store.parametroValor?.map((item) => {
        if (item.used) {
          returner.push({
            label: item.parametro.nome,
            value: item.parametro.id.toString(),
          });
        }
        return null;
      });

      state.parametroSelect = returner;
    }
    // eslint-disable-next-line
  }, [store.parametroValor.arr]);

  useEffect(() => {
    if (state.parametroSelect.length > 0) {
      state.parametroSelected = {
        label: state.parametroSelect[0].label,
        value: state.parametroSelect[0].value,
      };
    } else {
      state.parametroSelected = {
        label: 'Sem parâmetro ativo',
        value: '',
      };
    }

    if (store.tanques.arr?.length) {
      const tanque = store.tanques.arr![0];

      state.tanqueSelected = {
        label: returnTanque(tanque),
        value: tanque.id.toString(),
      };
    }
    // eslint-disable-next-line
  }, [store.tanques.arr, state.parametroSelect]);

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Qualidade da Água'), []);

  const ListaParametros: React.FC = () => (
    <>
      <HeaderList>
        <span>MIN</span>
        <span>MÁX</span>
      </HeaderList>
      {store.parametroValor?.map((parametro) => {
        if (parametro?.used) {
          return (
            <FlexList key={parametro?.id}>
              <ParametroNome>{parametro?.parametro?.nome}</ParametroNome>
              <MinValor>{Formatter.formatNumber(3, parametro?.valor_minimo)}</MinValor>
              <MaxValor>{Formatter.formatNumber(3, parametro?.valor_maximo)}</MaxValor>
            </FlexList>
          );
        }
      })}
    </>
  );

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn onClick={modalCadastro.open} text="Nova Análise" Icon={AddIcon} />
          <AddBtn onClick={modalCadastroParaMuitosTanques.open} text="Análise por Hora" Icon={AddIcon} />
        </Flex>
        <Flex>
          <Filtro sync={filter} clear={limparFiltro}>
            <div style={{ width: '90%', marginTop: 10 }}>
              <Select
                value={state.filtroTanque}
                classNamePrefix="sel"
                placeholder="Selecione o Tanque"
                onChange={(e: any) => (state.filtroTanque = e)}
                options={store.tanques?.map((tanque) => {
                  return {
                    label: returnTanque(tanque),
                    value: tanque.id,
                  };
                })}
              />
            </div>
            <div style={{ width: '90%', marginTop: 10 }}>
              <Select
                value={state.filtroParametro}
                classNamePrefix="sel"
                placeholder="Selecione o Parâmetro"
                onChange={(e: any) => (state.filtroParametro = e)}
                options={state.parametroSelect}
              />
            </div>
            <div style={{ width: '90%', marginTop: 10 }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Início"
                value={state.filtroDataInicio}
                maxDate={state.filtroDataFim}
                onChange={(date) => (state.filtroDataInicio = date)}
              />
            </div>
            <div style={{ width: '90%', marginTop: 10 }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Fim"
                value={state.filtroDataFim}
                minDate={state.filtroDataInicio}
                onChange={(date) => (state.filtroDataFim = date)}
              />
            </div>
          </Filtro>
        </Flex>
      </Header>
      <Body gridColumns="1fr 500px">
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.qualidadeDaAgua.arr && (
            <MaterialTable
              tableRef={tableRef}
              columns={state.colunas}
              onChangePage={(page: number, pageSize: number) => {
                if (store.propriedade) {
                  store.qualidadeDaAgua.getPaginated({
                    page,
                    page_size: pageSize,
                    propriedade: store.propriedade?.id,
                    tanque: state.filtroTanque?.value,
                    parametro_valor: state.filtroParametro?.value,
                    data_inicial: state.filtroDataInicio ? Formatter.dateToISOString(state.filtroDataInicio) : null,
                    data_final: state.filtroDataFim ? Formatter.dateToISOString(state.filtroDataFim) : null,
                  });
                }
              }}
              data={(query) =>
                new Promise((resolve) => {
                  try {
                    resolve({
                      data: rows(store.qualidadeDaAgua.arr as any),
                      page: query.page,
                      totalCount: store.qualidadeDaAgua.totalRes,
                    });
                  } catch (err: any) {
                    X9Logger.report(err);
                    store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
                  }
                })
              }
              options={{ ...config.options, pageSize: 25 }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
        <div>
          {store.qualidadeDaAgua.arr && store.parametroValor.arr && store.tanques && (
            <Display
              style={{ marginBottom: '10px', height: '40vh' }}
              overflowY={false}
              Select1={SelectParametro}
              Select2={SelectTanques}
            >
              <ReactApexChart
                height="100%"
                options={graficoQualidade.options as any}
                series={graficoQualidade.series}
                type="line"
              />
            </Display>
          )}
          {store.parametroValor.arr ? (
            <Display style={{ height: '40vh' }} overflowY title="Parâmetros" HeaderButtonEdit={OpenParametros}>
              <ListaParametros />
            </Display>
          ) : null}
        </div>

        {modalCadastro.state ? (
          <CadastraQualidade
            parametroValor={store.parametroValor.arr}
            open={modalCadastro.state}
            handleClose={modalCadastro.close}
          />
        ) : null}
        {modalCadastroParaMuitosTanques.state && (
          <CadastrarQualidadeMuitosTanques
            parametroValor={store.parametroValor.arr}
            open={modalCadastroParaMuitosTanques.state}
            handleClose={modalCadastroParaMuitosTanques.close}
          />
        )}

        {modalEditar.state ? (
          <EditarQualidade
            open={modalEditar.state}
            qualidadeID={state.selectedAnalise}
            handleClose={modalEditar.close}
          />
        ) : null}
        {modalVisualizar.state && store.parametroValor.arr ? (
          <ListaTodosParametros open={modalVisualizar.state} handleClose={modalVisualizar.close} />
        ) : null}
      </Body>
      <Confirmar
        open={modalExclusao.state}
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
        content={<ExcluirQualidade />}
        title="Tem certeza que deseja excluir?"
      />
    </>
  ) : null;
};

export default observer(Qualidade);

// ----------------------------------------------

const rows = (qualidades: QualidadeDaAgua[]): object[] => {
  return qualidades.map((qualidade) => {
    const data = new Date(
      qualidade.data.substr(3, 2) + '/' + qualidade.data.substr(0, 2) + '/' + qualidade.data.substr(6, 4),
    );

    const row: { [key: string]: any } = {
      id: qualidade.id,
      uuid: qualidade.uuid,
      nome: returnTanque(qualidade?.tanque),
      data,
    };

    try {
      qualidade.parametros_utilizados.map((parametro) => {
        row[parametro?.parametro?.uuid] = parametro.value;

        return null;
      });
    } catch (error) {
      console.log(error);
    }

    return row;
  });
};
