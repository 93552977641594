import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';

import useStore from '../../../services/hooks/useStore';
import { Conteudo, NewBody } from '../../../components/Body';
import PainelZootecnicoTab from './PainelZootecnicoTab';
import { DadosCompactos, GridDados } from '../../../components/Dados';
import Formatter from '../../../services/Formatter';
import { calcularLamina } from '../../../services/utils/propriedade';
import syncModel from '../../../services/models/Sync';
import { permissoes } from '../../../services/utils/permissoes';
import ApexCharts from 'apexcharts';
//typagem
type State = VisaoGeralManejo & {
  sync: boolean;
};

const PainelZootecnico: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);

  //inicializando estados
  const state = useLocalStore(
    (): State => ({
      qtd_tanques: 0,
      lamina: 0,
      capacidade: 0,
      biomassa: 0,
      estoque_por_racao: [],
      ganho_peso: [],
      sync: false,
      mortalidade: [],
    }),
  );
  //cores usadas no grafico
  const colors = ['#049CE7', '#FFE600', '#FE196B'];
  const graficoEstoque = useLocalStore(() => ({
    series: [
      {
        name: 'Estoque',
        data: [10000],
      },
    ],
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        height: 180,
        type: 'bar',
        id: 'estoque',
        events: {},
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(3, val) + ' kg';
          },
        },
      },
    },

    update() {
      ApexCharts.exec('estoque', 'updateSeries', [
        {
          data: store.racoes
            .arr!.sort((a, b) => (a.estoque < b.estoque ? 1 : b.estoque < a.estoque ? -1 : 0))
            .slice(0, 6)
            .map((racao) => {
              const estoque = racao.estoque < 0 ? 0 : racao.estoque;
              return { x: racao.nome, y: estoque };
            }),
        },
      ]);
    },
  }));

  //requisicoes?
  async function sync(): Promise<void> {
    await syncModel.painelZootecnico();

    state.sync = true;

    const lamina = calcularLamina(store.tanques.arr);

    state.biomassa = store.tanques.producaoTotal;
    state.qtd_tanques = store.tanques.length;
    state.capacidade = store.tanques.capacidadeTotal;
    state.lamina = lamina.m2 + lamina.m3;

    graficoEstoque.update();
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Painel Zootécnico'), []);
  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return (
    <>
      <NewBody>
        {/* Painel Abas  */}
        <PainelZootecnicoTab />

        {/* Cards Iniciais com dados */}
        <GridDados>
          <DadosCompactos>
            <p>Quantidade Tanques</p>
            <h1>{Formatter.formatNumber(0, state.qtd_tanques)}</h1>
          </DadosCompactos>
          <DadosCompactos>
            <p>Lâmina Total (m²/m³)</p>
            <h1>{Formatter.formatNumber(2, state.lamina)}</h1>
          </DadosCompactos>
          <DadosCompactos>
            <p>Capacidade Total (ton)</p>
            <h1>{Formatter.formatNumber(3, state.capacidade)}</h1>
          </DadosCompactos>
          <DadosCompactos>
            <p>Biomassa Atual (ton)</p>
            <h1>{Formatter.formatNumber(3, state.biomassa)}</h1>
          </DadosCompactos>
        </GridDados>

        <Conteudo>
          <h2>Estoque de Ração (kg)</h2>
          <ReactApexChart
            options={graficoEstoque.options as any}
            height={300}
            series={graficoEstoque.series}
            type="bar"
          />
        </Conteudo>
      </NewBody>
    </>
  );
};

export default observer(PainelZootecnico);
