import React, { useMemo, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import { Modal, ModalHeader, ModalBody, Lote } from '../../../components/Modais/styles';
import { Categoria, Categorias, Dados, Parcela } from './styles';
import { Flex } from '../../../components/NavBar/styles';
import { apiV2 } from '../../../services/utils';
import Botao from '../../../components/Botoes/Botao';
import useModal from '../../../services/hooks/useModal';
import SelecionarTanque from './SelecionarTanque';
import { TextField, Checkbox, IconButton, Tooltip, FormControlLabel } from '@material-ui/core';

import { returnTanque } from '../../../services/utils/propriedade';
import Select from '../../../components/Select';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import InputNumero from '../../../components/InputNumero';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormCliente from '../Clientes/FormCliente';
import AddIcon from '@material-ui/icons/Add';
import FinalizarLote from '../../../components/Modais/FinalizarLote';
import Formatter from '../../../services/Formatter';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NewSelect from '../../../components/Select/newSelect';
import FinalizarTanque from '../../../components/Modais/FinalizarTanque';

type State = {
  etapa: number;
  categoria: string;
  lote: number;
  tanques: {
    bonificacao?: boolean;
    bonificacao_qtde?: number;
    bonificacao_porc?: number;
    item: Tanque;
    lote_tanque: number;
    quantidade?: number;
    biomassa: number;
    peso_medio: number;
    valorUnitario?: number;
    valorTotal?: number;
    use_qtde: boolean;
    lancarMortalidadeTanque?: boolean;
  }[];
  cliente: number;
  data: MaterialUiPickersDate;
  valor_unitario: number;
  valor_total: number;
  pesoMedio: number;
  dataEntrega: MaterialUiPickersDate;
  conta: SelectRow;
  financeiro: boolean;
  documento: string;

  pagamento: SelectRow;
  qtdParcelas: number;
  parcelas: Parcela[];

  quantidade: number;
  biomassa: number;

  finalizarLote: boolean;
  saldoTanque: boolean;
  finalizarTanque: boolean;
  bonificacao: boolean;
  bonificacaoPorc: number;
  bonificacaoQtde: number;
  adicionar: boolean;
  collapse: boolean;
  sync: boolean;

  gerarVenda(): Promise<void>;
  somaParcelas(): number;
  setPagamento(tipo: SelectRow): void;
  setQtdParcelas(value: number): void;
  valorTotal(): number;
  voltar(): void;
  avancar(): Promise<void>;
};

type Props = ModalProps & {
  onConfirm(): void;
};

const FormVenda: React.FC<Props> = ({ open, handleClose, onConfirm }) => {
  const store = useStore();
  const modalTanque = useModal();
  const modalCliente = useModal();
  const modalFinalizarLote = useModal();
  const modalFinalizarTanque = useModal();
  const etapas = ['Selecione a categoria', 'Selecione o cliente', 'Detalhes da venda', 'Gerar financeiro'];
  const [pesquisaCliente, setPesquisaCliente] = useState('');
  const contaPadrao = store
    .contas!.filter((conta) => conta.conta_padrao)
    .map((conta) => {
      return {
        label: conta.titulo,
        value: conta.id.toString(),
      };
    });
  const state = useLocalStore(
    (): State => ({
      etapa: 0,
      sync: false,
      categoria: '',
      lote: 0,
      tanques: [],
      cliente: 0,
      data: new Date(),
      valor_unitario: 0,
      pesoMedio: 0,
      valor_total: 0,
      dataEntrega: new Date(),
      conta: contaPadrao[0],
      financeiro: true,
      documento: '',

      pagamento: store.tiposDePagamento.getSelectRows()[0],
      qtdParcelas: 1,
      parcelas: [],

      quantidade: 0,
      biomassa: 0,

      saldoTanque: false,

      bonificacao: true,
      bonificacaoPorc: 0,
      bonificacaoQtde: 0,
      finalizarLote: false,
      finalizarTanque: false,
      adicionar: false,
      collapse: false,

      setPagamento(tipo) {
        this.pagamento = tipo;
        this.parcelas.map((parcela) => {
          return (parcela.tipo = tipo);
        });
      },
      //gerando as parcelas
      setQtdParcelas(value) {
        this.qtdParcelas = value;
        this.parcelas = [];

        for (let parcela = 0; parcela < this.qtdParcelas; parcela++) {
          const data = new Date(this.data!);
          data.setMonth(data.getMonth() + parcela);

          let valor = Number(state.valorTotal() / this.qtdParcelas);

          if (parcela + 1 == this.qtdParcelas) {
            const valorTotal = this.parcelas.map((par) => par.valor).reduce((prv, crr) => (prv += crr));
            valor = Number((state.valorTotal() - valorTotal).toFixed(2));
          }

          this.parcelas.push({
            data,
            pago: false,
            tipo: this.pagamento,
            conta_id: this.conta,
            valor,
          });
        }
      },

      //setando valor total
      valorTotal() {
        if (this.categoria === 'Camarão') {
          return Number(this.valor_total.toFixed(2));
        } else {
          return Number(this.valor_total.toFixed(2));
        }
      },
      //somando as parcelas
      somaParcelas() {
        return this.parcelas.reduce((valorAcumulado, parcela) => {
          return valorAcumulado + Number(parcela.valor);
        }, 0);
      },

      voltar() {
        if (this.etapa !== 0) this.etapa -= 1;
        else handleClose();
      },

      async avancar() {
        if (this.etapa === 0) {
          if (this.tanques.length === 0) {
            store.notificar('Informe os tanques');
          } else {
            this.etapa += 1;
          }
        } else if (this.etapa === 1) {
          if (!this.cliente) {
            store.notificar('Informe o cliente');
          } else this.etapa += 1;
        } else if (this.etapa === 2) {
          if (!this.valor_unitario) {
            store.notificar('Informe o valor unitário');
          } else {
            this.parcelas = [
              {
                data: this.data,
                pago: false,
                conta_id: store.contas.getSelectRows()[0],
                tipo: store.tiposDePagamento.getSelectRows()[0],
                valor: this.valorTotal(),
              },
            ];
            if (state.financeiro) {
              this.etapa += 1;
            } else {
              if (state.finalizarLote) {
                await this.gerarVenda();
                modalFinalizarLote.open();
              } else if (!state.finalizarLote && state.tanques.some((tanque) => tanque.lancarMortalidadeTanque)) {
                await this.gerarVenda();
                modalFinalizarTanque.open();
              } else {
                await this.gerarVenda();
                handleClose();
              }
            }
          }
        } else if (this.etapa === 3) {
          if (!this.conta) {
            store.notificar('Informe a conta');
          } else if (this.parcelas.some((parcela) => !parcela.valor)) {
            store.notificar('O valor de uma parcela não pode ser 0 ou nulo');
          } else if (this.somaParcelas().toFixed(2) !== this.valorTotal().toFixed(2)) {
            store.notificar('A soma das parcelas deve coincidir com o valor total da venda');
          } else {
            if (state.finalizarLote) {
              await this.gerarVenda();
              modalFinalizarLote.open();
            } else if (!state.finalizarLote && state.tanques.some((tanque) => tanque.lancarMortalidadeTanque)) {
              await this.gerarVenda();
              modalFinalizarTanque.open();
            } else {
              await this.gerarVenda();
              handleClose();
            }
          }
        }
      },

      async gerarVenda() {
        store.toggleLoader();

        const parcelas = this.parcelas.map((parcela, index) => {
          return {
            num_parcela: index + 1,
            data_pagamento: Formatter.dateToString(parcela.data),
            recebido: parcela.pago,
            pagamento_tipo_id: parseInt(parcela.tipo.value),
            valor: parcela.valor,
            conta_id: parseInt(parcela.conta_id.value),
          };
        });

        const cliente = store.clientes.get(this.cliente).nome;

        const data: any = {
          lote_tanque_vendas: this.tanques.map((item) => {
            return {
              lote_tanque_id: item.lote_tanque,
              biomassa: Number((item.biomassa * 1000).toFixed(3)),
              quantidade: item.quantidade!,
              peso_medio: Number((item.peso_medio * 1000).toFixed(3)),
              valor_unit: item.valorUnitario,
              valor_total: item.valorTotal,
              bonificacao: item.bonificacao,
              bonificacao_porc: item.bonificacao_porc || undefined,
              bonificacao_qtde: item.bonificacao_qtde || undefined,
              use_qtde: item.use_qtde,
            };
          }),
          cliente: this.cliente,
          peso: Number((this.biomassa * 1000).toFixed(2)),
          valor_total: this.valorTotal(),
          data: Formatter.dateToString(this.data),
          valor_por_quilo: Number((this.valorTotal() / this.biomassa).toFixed(2)),
          data_entrega: Formatter.dateToString(this.dataEntrega),
          pagamento_tipo: parseInt(this.pagamento.value),
          quantidade: this.quantidade,
          propriedade: store.propriedade?.id,
          descricao: 'Vendas para ' + cliente,
          conta: parseInt(this.conta!.value),
          categoria: store.categorias.getByTipo('vendas').filter((item) => item.nome === state.categoria)[0].id,
          desconto: 0,
          valor_unitario: this.valor_unitario,
          observacao: '',
          situacao: false,
          financeiro: this.financeiro,
          documento: this.documento,
          parcelas,
        };

        await apiV2.post(`/venda/?propriedade=${store.propriedade?.id}`, data).then(async () => {
          // await Promise.all([store.vendas.populate(), store.contas.populate()]).then(() => {
          store.notificar('Venda cadastrada com sucesso!');
          onConfirm();
          // });
        });

        store.toggleLoader();
      },
    }),
  );

  const placeholderProviderInput = useMemo(() => {
    if (window.location.pathname === '/vendas') return 'Cliente';
    else return 'Fornecedor';
  }, [window.location.pathname]);

  // eslint-disable-next-line

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>
        {state.etapa === 2 ? (
          <Flex style={{ justifyContent: 'space-between' }}>
            <span>{etapas[state.etapa]}</span>

            <Tooltip title="Adicionar cliente">
              <IconButton style={{ background: 'rgba(0,0,0,0.2)' }} onClick={modalCliente.open}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Flex>
        ) : (
          etapas[state.etapa]
        )}
      </ModalHeader>
      <ModalBody
        style={{
          minWidth: state.etapa === 3 ? '950px' : '488px',
        }}
      >
        {state.etapa === 0 ? (
          <Categorias>
            {store.categorias.arr &&
              store.categorias.getByTipo('vendas').map((categoria) => (
                <Categoria
                  onClick={() => {
                    state.categoria = categoria.nome;
                    state.etapa = 0;
                    modalTanque.open();
                  }}
                  key={categoria.id}
                >
                  <div>
                    {categoria.nome === 'Camarão' ? (
                      <img src="/images/camarao2.png" alt={categoria.nome} />
                    ) : categoria.nome === 'Peixe Vivo' ? (
                      <img src="/images/peixe.svg" alt={categoria.nome} />
                    ) : (
                      <img src="/images/alevinos.png" alt={categoria.nome} />
                    )}
                  </div>
                  <p>{categoria.nome}</p>
                </Categoria>
              ))}
          </Categorias>
        ) : null}

        {modalTanque.state ? (
          <SelecionarTanque
            adicionarOutro={state.adicionar}
            categoria={state.categoria}
            open={modalTanque.state}
            handleClose={() => {
              modalTanque.close();

              if (state.adicionar === false) {
                state.etapa = 1;
              } else {
                state.etapa = 2;
              }
            }}
            selectedTanques={state.tanques}
            setLote={(loteID) => (state.lote = loteID)}
            exclude={[]}
            cancelar={() => {
              if (state.adicionar === false) {
                state.tanques = [];
              }
              modalTanque.close();
            }}
            addTanque={(
              tanqueID,
              quantidade,
              biomassa,
              pesoMedio,
              saldoTanque,
              bonificacao,
              bonificacaoPorc,
              bonificacaoQtde,
              valorUnitario,
              valorTotal,
              lancarMortalidadeTanque,
            ) => {
              if (
                state.tanques.filter((tanque) => tanque.item.id === tanqueID).length > 0 &&
                state.adicionar === false
              ) {
                state.tanques.filter((tanque) => tanque.item.id === tanqueID)[0].quantidade = quantidade;
                state.tanques.filter((tanque) => tanque.item.id === tanqueID)[0].biomassa = biomassa;
                state.tanques.filter((tanque) => tanque.item.id === tanqueID)[0].peso_medio = pesoMedio;
                state.tanques.filter((tanque) => tanque.item.id === tanqueID)[0].bonificacao_porc = bonificacaoPorc;
                state.tanques.filter((tanque) => tanque.item.id === tanqueID)[0].bonificacao_qtde = bonificacaoQtde;
                state.tanques.filter((tanque) => tanque.item.id === tanqueID)[0].bonificacao = bonificacao;
                state.tanques.filter((tanque) => tanque.item.id === tanqueID)[0].lancarMortalidadeTanque =
                  lancarMortalidadeTanque;
              } else {
                const tanque = {
                  item: store.tanques.get(tanqueID)!,
                  quantidade,
                  biomassa,
                  peso_medio: pesoMedio,
                  lote_tanque: store.loteTanque.getByLoteTanque(state.lote, tanqueID).id,
                  bonificacao_porc: bonificacaoPorc,
                  bonificacao_qtde: bonificacaoQtde,
                  bonificacao: bonificacao,
                  valorUnitario: valorUnitario,
                  valorTotal: valorTotal,
                  use_qtde: state.categoria === 'Alevinos' ? true : false,
                  lancarMortalidadeTanque: lancarMortalidadeTanque || false,
                };
                state.saldoTanque = saldoTanque || false;
                state.tanques.push(tanque);
                state.quantidade += quantidade;
                state.valor_unitario += valorUnitario as number;
                state.pesoMedio += pesoMedio as number;
                state.valor_total += valorTotal as number;
                state.biomassa += biomassa;

                if (state.adicionar === true) {
                  modalTanque.close();
                }
              }
            }}
          />
        ) : null}

        {state.etapa === 1 ? (
          <Flex style={{ flexDirection: 'column', width: '100%' }}>
            <TextField
              label="Pesquisa Pelo Nome"
              value={pesquisaCliente}
              onChange={(e) => {
                setPesquisaCliente(e.target.value);
              }}
            />

            {store.clientes
              .sort((a: Clientes, b: Clientes) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0))
              .filter((cliente: Clientes) => {
                if (pesquisaCliente)
                  return cliente.nome.toLocaleLowerCase().includes(pesquisaCliente.toLocaleLowerCase());
                else return cliente;
              })
              .map((cliente: any) => {
                return (
                  <Lote
                    onClick={() => {
                      state.cliente = cliente.id;
                      state.avancar();
                    }}
                    key={cliente.id}
                  >
                    <b>{cliente.nome}</b>
                  </Lote>
                );
              })}
          </Flex>
        ) : null}
        {modalCliente.state ? (
          <FormCliente open={modalCliente.state} handleClose={modalCliente.close} clienteID={0} />
        ) : null}

        {state.etapa === 2 ? (
          <div style={{ width: '100%' }}>
            {/* <div style={{ width: '100%', marginTop: 10 }}>
              {store.lotes.arr && (
                <Select
                  disabled
                  placeholder="Lote"
                  value={{ label: store.lotes.get(state.lote).codigo, value: '0' }}
                  options={[]}
                />
              )}
            </div> */}
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p>Tanque Selecionados</p>

              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  modalTanque.open();
                  state.adicionar = true;
                }}
              >
                <AddCircleIcon htmlColor="#059DE7" />
              </div>
            </div>
            {state.tanques.map((tanque) => {
              const estoque = store.tanques.getEstoqueByID(tanque.item.id);
              return (
                <div
                  key={tanque.item.id}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                    margin: '10px 0',
                    borderRadius: '5px',
                    border: '1px solid #56C323BF',
                    borderBottom: 'none',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <b className="left">{returnTanque(tanque.item)}</b>
                    <p>{Formatter.formatNumber(0, estoque)}un</p>
                    <p className="right" style={{ color: '#00C853' }}>
                      {Formatter.formatNumber(2, estoque ? store.tanques.get(tanque.item.id).biomassa : 0)} Kg
                    </p>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <b className="left" style={{ color: '#C80000' }}>
                      Nova venda
                    </b>
                    <p style={{ color: '#C80000' }}>
                      {Formatter.formatNumber(0, tanque.quantidade)}un {Formatter.formatNumber(2, tanque.valorUnitario)}
                      R$/{state.categoria === 'Alevinos' ? 'Un' : 'Kg'}
                    </p>
                    <p className="right" style={{ color: '#C80000' }}>
                      {Formatter.formatNumber(3, tanque.biomassa)} kg
                    </p>
                  </div>
                </div>
              );
            })}
            <div style={{ width: '100%', marginTop: 10 }}>
              {store.clientes && (
                <Select
                  disabled
                  placeholder="Cliente"
                  value={{
                    label: store.clientes.get(state.cliente).nome,
                    value: '0',
                  }}
                  options={[]}
                />
              )}
            </div>
            <div style={{ width: '100%', marginTop: 10 }}>
              {store.categorias.arr && (
                <Select
                  disabled
                  placeholder="Categoria"
                  value={{
                    label: state.categoria,
                    value: '0',
                  }}
                  options={[]}
                />
              )}
            </div>

            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Data"
              value={state.data}
              onChange={(date) => {
                state.data = date;
                state.dataEntrega = date;
              }}
            />

            <InputNumero
              disabled
              value={state.biomassa}
              onChange={(value) => (state.valor_unitario = value)}
              precision="2"
              label="Biomassa para venda (kg)"
            />
            <InputNumero
              disabled
              value={state.biomassa / state.quantidade}
              onChange={(value) => (state.valor_unitario = value)}
              precision="3"
              label="Peso Médio Vendido (kg)"
            />

            <InputNumero
              disabled
              value={
                state.categoria === 'Alevinos'
                  ? state.valor_total / state.quantidade
                  : state.valor_total / state.biomassa
              }
              onChange={(value) => (state.valor_unitario = value)}
              precision="2"
              label="Valor Unitário (R$)"
            />

            <Dados>
              <div>
                <h2>Valor total</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.valorTotal())}</h1>
              </div>
              <div>
                <h2>Quantidade</h2>
                <h1 style={{ color: '#42a5f5' }}>{Formatter.formatNumber(0, state.quantidade)}</h1>
              </div>
            </Dados>

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.financeiro}
                  onChange={(e) => (state.financeiro = e.target.checked)}
                  color="primary"
                />
              }
              label="Gerar financeiro"
            />

            <KeyboardDateTimePicker
              autoOk
              variant="inline"
              margin="normal"
              ampm={false}
              label="Data da entrega"
              value={state.dataEntrega}
              onChange={(date) => {
                state.dataEntrega = date;
              }}
              onError={console.log}
              format="dd/MM/yyyy HH:mm"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.finalizarLote}
                  onChange={(e) => (state.finalizarLote = e.target.checked)}
                  color="primary"
                />
              }
              label="Finalizar Lote"
            />
          </div>
        ) : null}

        {state.etapa === 3 ? (
          <div style={{ width: '100%' }}>
            <InputNumero disabled value={state.valorTotal()} precision="2" label="Valor Total (R$)" />
            <TextField
              type="number"
              disabled
              value={Formatter.formatNumber(0, state.quantidade)}
              label="Quantidade"
              style={{
                marginTop: '5px',
              }}
            />

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Valor Unitário</span>
              <p style={{ color: '#059DE7', fontSize: '30px' }}>
                R$
                {Formatter.formatNumber(
                  2,
                  state.categoria === 'Alevinos'
                    ? state.valor_total / state.quantidade
                    : state.valor_total / state.biomassa,
                )}
              </p>
            </div>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Data"
              value={state.data}
              onChange={(date) => {
                state.data = date;
                state.setQtdParcelas(state.qtdParcelas);
              }}
              style={{
                marginTop: '5px',
              }}
            />
            <TextField
              value={state.documento}
              onChange={(e) => (state.documento = e.target.value)}
              label="Número do documento"
              style={{
                marginTop: '-5px',
              }}
            />
            <div style={{ width: '100%', marginTop: 10 }}>
              {store.clientes.arr && (
                <Select
                  placeholder={placeholderProviderInput}
                  value={{
                    label: store.clientes.get(state.cliente).nome,
                    value: '0',
                  }}
                  options={[]}
                />
              )}
            </div>

            <div style={{ width: '100%', marginTop: 10 }}>
              {store.contas.arr && (
                <Select
                  value={state.conta}
                  onChange={(e: any) => (state.conta = e)}
                  placeholder="Conta Bancaria"
                  options={store.contas.getSelectRows()}
                />
              )}
            </div>
            <div style={{ width: '100%', marginTop: 10 }}>
              {store.tiposDePagamento.arr && (
                <Select
                  value={state.pagamento}
                  onChange={(e: any) => state.setPagamento(e)}
                  placeholder="Forma de pagamento"
                  options={store.tiposDePagamento.getSelectRows()}
                />
              )}
            </div>
            <TextField
              value={state.qtdParcelas}
              onChange={(e) => state.setQtdParcelas(parseInt(e.target.value))}
              type="number"
              label="Número de parcelas"
              inputProps={{ min: 1 }}
              onBlur={() => !state.qtdParcelas && state.setQtdParcelas(1)}
            />

            {/* Parcelas */}
            {state.parcelas.map((parcela, index) => (
              <Parcela key={index}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data"
                  value={parcela.data}
                  onChange={(date) => (state.parcelas[index].data = date)}
                />

                <InputNumero
                  value={typeof parcela.valor === 'string' ? Number(parcela.valor) : Number(parcela.valor)}
                  onChange={(value) => {
                    state.parcelas[index].valor = value;
                  }}
                  precision="2"
                  label="Valor"
                  id={'parcela-' + index}
                />
                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  {store.contas.arr && (
                    <NewSelect
                      value={parcela.conta_id}
                      onChange={(e: any) => (state.parcelas[index].conta_id = e)}
                      placeholder="Conta Banco"
                      options={store.contas.getSelectRows()}
                    />
                  )}
                  {store.tiposDePagamento.arr && (
                    <NewSelect
                      value={parcela.tipo}
                      onChange={(e: any) => (state.parcelas[index].tipo = e)}
                      placeholder="Forma de pagamento"
                      options={store.tiposDePagamento.getSelectRows()}
                      menuPlacement={index + 1 === state.parcelas.length ? 'top' : 'bottom'}
                    />
                  )}
                </div>
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Checkbox
                      checked={parcela.pago}
                      onChange={(e) => (state.parcelas[index].pago = e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Pago"
                />
              </Parcela>
            ))}
          </div>
        ) : null}

        {modalFinalizarLote.state ? (
          <FinalizarLote
            povoamento={false}
            open={modalFinalizarLote.state}
            handleClose={modalFinalizarLote.close}
            onConfirm={handleClose}
            async={() => onConfirm()}
            loteID={state.lote}
          />
        ) : null}

        {modalFinalizarTanque.state ? (
          <FinalizarTanque
            open={modalFinalizarTanque.state}
            handleClose={modalFinalizarTanque.close}
            onConfirm={handleClose}
            async={() => onConfirm()}
            tanques={state.tanques.filter((tanque) => tanque.lancarMortalidadeTanque)}
          />
        ) : null}

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={state.voltar} variant="contained" cor="gray">
            Voltar
          </Botao>
          <Botao onClick={state.avancar} variant="contained" cor="#00C853">
            {!state.financeiro && state.etapa === 2 ? 'Salvar' : state.etapa === 3 ? 'Salvar' : 'Avançar'}
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormVenda);
