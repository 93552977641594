import React from 'react';
import Header from '../../../components/Header';
import PainelZootecnicoTab from './PainelZootecnicoTab';
import { Body } from '../../../components/Body';
import { NavbarLink } from '../../Financeiro/Painel/styles';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import { observer } from 'mobx-react-lite';
import AssignmentIcon from '@mui/icons-material/Assignment';

type RelList = {
  relatorioName: string;
  relatorioInfo: string;
  link: string;
};

const FormRelatoriosZootecnico: React.FC = () => {
  //listagem - Link para os relatorios disponiveis
  const tableDataRelatorios: RelList[] = [
    {
      relatorioName: 'Consumo de Ração ',
      relatorioInfo: 'Arraçoamento',
      link: '/relatorios/total-racoes',
    },
    {
      relatorioName: 'Desempenho dos Lotes',
      relatorioInfo: 'Produção',
      link: '/relatorios/desempenho-lotes',
    },
    {
      relatorioName: 'Estoque de Tanques',
      relatorioInfo: 'Produção',
      link: '/relatorios/estoque-tanques',
    },
    {
      relatorioName: 'Listagem de Ração por Tanque',
      relatorioInfo: 'Arraçoamento',
      link: '/relatorios/racao-tanque',
    },
    {
      relatorioName: 'Mortalidades',
      relatorioInfo: 'Mortalidades',
      link: '/relatorios/listagem-mortalidade',
    },
    {
      relatorioName: 'Resumo Consumo de Ração',
      relatorioInfo: 'Arraçoamento',
      link: '/relatorios/resumo-total-racoes',
    },
    {
      relatorioName: 'Resumo Consumo de Insumos',
      relatorioInfo: 'Arraçoamento',
      link: '/relatorios/resumo-total-insumos',
    },
    {
      relatorioName: 'Resumo de Lotes Finalizados',
      relatorioInfo: 'Produção',
      link: '/relatorios/resumo-lotes',
    },
    {
      relatorioName: 'Resumo de Tanques',
      relatorioInfo: 'Produção',
      link: '/relatorios/resumo-tanques',
    },
    {
      relatorioName: 'Tanques Com Programa Alimentar',
      relatorioInfo: 'Programa Alimentar',
      link: '/relatorios/tanques-programa-alimentar',
    },
    {
      relatorioName: 'Arraçoamento Previsto X Realizado',
      relatorioInfo: 'Arraçoamento Previsto X Realizado',
      link: '/relatorios/arracoamento-previsto-x-realizado',
    },
  ];

  // Formatar os Links
  function linkFormatter(rowData: RelList, text: string) {
    return (
      <NavbarLink
        to={rowData.link}
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'black',
          textDecoration: 'none',
          fontFamily: 'Roboto',
          fontSize: 'inherit',
          fontWeight: 400,
        }}
      >
        <AssignmentIcon style={{ marginRight: '2px' }} />
        {text}
      </NavbarLink>
    );
  }

  //listando as colunas
  const columns: Column<RelList>[] = [
    {
      field: 'relatorioName',
      type: 'string',
      headerStyle: {
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      render: (rowData) => linkFormatter(rowData, rowData.relatorioName),
      ...(true && ({ width: '40%' } as object)),
    },
    {
      field: 'relatorioInfo',
      type: 'string',
      headerStyle: {
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
      },
      render: (rowData) => (
        <NavbarLink
          to={rowData.link}
          style={{ textDecoration: 'none', color: '#000', fontSize: 'inherit', fontWeight: 400 }}
        >
          {rowData.relatorioInfo}
        </NavbarLink>
      ),
      ...(true && ({ width: '80%' } as object)),
    },
  ];

  return (
    <>
      <Header style={{ fontWeight: 'bold', fontSize: '24px' }}>Relatórios Zootécnicos</Header>
      <PainelZootecnicoTab />
      <Body style={{ display: 'flex' }}>
        <MaterialTable
          columns={columns}
          data={tableDataRelatorios}
          options={{
            ...newConfig.options,
            paging: false,
            sorting: false,
            toolbar: false,
            tableLayout: 'fixed',
            headerStyle: {
              height: '1px',
              paddingTop: 0,
              paddingBottom: 0,
              fontSize: 0,
            },
          }}
          style={{
            ...newConfig.style,
            backgroundColor: '#F5F6F8',
          }}
          localization={newConfig.localization}
        />
      </Body>
    </>
  );
};
export default observer(FormRelatoriosZootecnico);
