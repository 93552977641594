import React from 'react';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { TextField, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Botao from '../../../components/Botoes/Botao';
import InputNumero from '../../../components/InputNumero';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Formatter from '../../../services/Formatter';

type Props = ModalProps & {
  contaID: number;
};
const FormConta: React.FC<Props> = ({ open, handleClose, contaID }) => {
  const store = useStore();
  const conta = contaID ? store.contas.get(contaID) : null;
  const state = useLocalStore(() => ({
    titulo: conta ? conta.titulo : '',
    saldo: conta ? conta.saldo_atual : 0,
    saldo_inicio: conta ? conta.saldo_inicial : 0,
    padrao: conta ? conta.conta_padrao : false,
    inativo: conta ? conta.inativo : false,
    data: conta
      ? conta.data_inicio
        ? new Date(
          conta.data_inicio.substr(3, 2) +
          '/' +
          conta.data_inicio.substr(0, 2) +
          '/' +
          conta.data_inicio.substr(6, 4),
        )
        : new Date(
          conta.created_at.substr(3, 2) + '/' + conta.created_at.substr(0, 2) + '/' + conta.created_at.substr(6, 4),
        )
      : new Date(),
  }));

  async function cadastrar(): Promise<void> {
    if (!state.titulo) {
      store.notificar('Informe o título da conta');
    } else if (state.padrao && store.contas.arr!.some((conta) => conta.conta_padrao)) {
      store.notificar('Você já possui uma conta padrão!');
    } else {
      handleClose();
      store.toggleLoader();

      let data = {
        propriedade: store.propriedade!.id,
        titulo: state.titulo,
        saldo_inicial: state.saldo_inicio,
        saldo_atual: state.saldo,
        conta_padrao: state.padrao,
        inativo: state.inativo,
        data_inicio: Formatter.dateToString(state.data),
      };
      if (!contaID) {
        data.saldo_inicial = state.saldo;
      }

      contaID ? await store.contas.editar(contaID, data) : await store.contas.criar(data);

      store.toggleLoader();
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{contaID ? 'Editar' : 'Novo'} conta</ModalHeader>
      <ModalBody>
        <div style={{ width: 400 }}>
          <TextField value={state.titulo} onChange={(e) => (state.titulo = e.target.value)} label="Título" />
          {contaID ? (

            <InputNumero

              value={state.saldo_inicio}
              onChange={(value) => (state.saldo_inicio = value)}
              precision="2"
              label={"Saldo Inicial"}
              allowNegative
            />
          ) : null}
          <InputNumero
            disabled={!!contaID}
            value={state.saldo}
            onChange={(value) => (state.saldo = value)}
            precision="2"
            label={contaID ? "Saldo Atual" : "Saldo de Início"}

            allowNegative
          />
          <KeyboardDatePicker
            disableToolbar
            autoOk
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data de Início"
            value={state.data}
            onChange={(date) => (state.data = date!)}
          />
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox checked={state.padrao} onChange={(e) => (state.padrao = e.target.checked)} color="primary" />
              }
              label="Conta Padrão"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.inativo}
                  onChange={(e) => (state.inativo = e.target.checked)}
                  color="primary"
                />
              }
              label="Inativo"
            />
          </FormGroup>

          <div style={{ width: 400, display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormConta);
