import React, { useEffect } from 'react';
import { Flex } from '../../../components/NavBar/styles';
import { NewBody, Conteudo } from '../../../components/Body';
import { DadosCompactos, GridDados } from '../../../components/Dados';
import { IconButton } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import ReactApexChart from 'react-apexcharts';
import Select from '../../../components/Select';
import pt from 'apexcharts/dist/locales/pt.json';
import { useHistory } from 'react-router-dom';
import { relatoriosProd } from '../../../services/utils/relatorios';
import { calcularLamina } from '../../../services/utils/propriedade';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import { permissoes } from '../../../services/utils/permissoes';
import ApexCharts from 'apexcharts';
type Elemento = {
  data: string;
  historico_amostragens: Amostragem[];
  peso: number | null;
};

type State = VisaoGeralManejo & {
  sync: boolean;
};

const Manejo: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PROPRIEDADE_LEITURA, history);

  const state = useLocalStore(
    (): State => ({
      qtd_tanques: 0,
      lamina: 0,
      capacidade: 0,
      biomassa: 0,
      ganho_peso: [],
      estoque_por_racao: [],
      mortalidade: [],

      sync: false,
    }),
  );

  const colors = ['#049CE7', '#FFE600', '#FE196B', '#465A65'];
  const graficoMortalidade = useLocalStore(() => ({
    series: [
      {
        name: 'Mortes',
        data: [],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        id: 'mortalidade',
        events: {},
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(0, val);
          },
        },
      },
    },

    update() {
      const data: { x: string; y: number }[] = [];

      store.tiposDeMorte.arr!.map((tipo) => {
        let mortes = 0;

        store.mortalidades.getByTipo(tipo.id).map((mortalidade) => {
          return (mortes += mortalidade.quantidade);
        });

        if (mortes) return data.push({ x: tipo.nome, y: mortes });
        else return null;
      });

      ApexCharts.exec('mortalidade', 'updateSeries', [
        {
          data,
        },
      ]);
      // ApexCharts.exec('mortalidade', 'updateSeries', [
      //   {
      //     data: state.mortalidade.map((mortalidade) => {
      //       return { x: mortalidade.tipo, y: mortalidade.quantidade };
      //     }),
      //   },
      // ]);
    },
  }));
  const graficoEstoque = useLocalStore(() => ({
    series: [
      {
        name: 'Estoque',
        data: [10000],
      },
    ],
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        height: 180,
        type: 'bar',
        id: 'estoque',
        events: {},
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(3, val) + ' kg';
          },
        },
      },
    },

    update() {
      ApexCharts.exec('estoque', 'updateSeries', [
        {
          data: store.racoes
            .arr!.sort((a, b) => (a.estoque < b.estoque ? 1 : b.estoque < a.estoque ? -1 : 0))
            .slice(0, 6)
            .map((racao) => {
              const estoque = racao.estoque < 0 ? 0 : racao.estoque;
              return { x: racao.nome, y: estoque };
            }),
        },
      ]);
      // ApexCharts.exec('estoque', 'updateSeries', [
      //   {
      //     data: state.estoque_por_racao.map((estoque) => {
      //       return { x: estoque.nome, y: estoque.estoque_atual };
      //     }),
      //   },
      // ]);
    },
  }));

  async function getHistorico(lote: Lote): Promise<Elemento[] | null> {
    if (lote) {
      let historico = await store.lotes.getHistorico(lote.id);
      historico = historico.filter((historico) => historico.tipo === 'biometria' || historico.tipo === 'povoamento');

      const datas: string[] = [...new Set(historico.reverse().map((item) => item.data))];

      const arr: Elemento[] = [historico.filter((historico) => historico.tipo === 'povoamento')[0]];

      datas.map((data) => {
        const elemento: Elemento = {
          data,
          historico_amostragens: [],
          peso: null,
        };
        const historicoData = historico.filter((item) => item.data === data && item.tipo !== 'povoamento');

        if (historicoData.length === 1) {
          elemento.historico_amostragens = historicoData[0].historico_amostragens;
          return arr.push(elemento);
        } else if (historicoData.length > 1) {
          historicoData.map((historico) =>
            historico.historico_amostragens.map((amostragem) => elemento.historico_amostragens.push(amostragem)),
          );
          return arr.push(elemento);
        } else return null;
      });

      return arr;
    } else {
      return null;
    }
  }
  const graficoGanhoPeso = useLocalStore(() => ({
    filtro: 0,
    series: [
      {
        name: 'Peso Médio (g)',
        data: [],
      },
    ],
    options: {
      title: {},
      chart: {
        locales: [pt],
        defaultLocale: 'pt',
        type: 'area',
        id: 'ganhoPeso',
        stacked: false,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(3, val) + ' g';
          },
        },
      },
      xaxis: {
        type: 'datetime',
      },
      tooltip: {
        shared: false,
      },
    },

    update: async () => {
      store.toggleLoader();

      // Attempt to verify if object already exists
      const lote = store.lotes.get(graficoGanhoPeso.filtro || store.biometrias.arr![0]?.lote.id);
      if (lote) {
        ApexCharts.exec('ganhoPeso', 'updateOptions', {
          ...graficoGanhoPeso.options,
          title: {
            text: lote.codigo,
            align: 'left',
          },
        });

        const historicoArr = await getHistorico(lote);
        ApexCharts.exec('ganhoPeso', 'updateSeries', [
          {
            data: historicoArr?.map((historico) => {
              const arr = [3, 4, 2, 0, 1, 5, 6, 7, 8, 9, 10];
              const date = arr.map((index) => historico.data[index]).join('');

              let media = 0;

              if (historico.historico_amostragens.length > 0) {
                historico.historico_amostragens.map((amostragem) => (media += amostragem.media));
                media = media / historico.historico_amostragens.length;
              } else {
                if (historico.peso) media = historico.peso;
              }

              return { x: date, y: media };
            }),
          },
        ]);
      }
      store.toggleLoader();
    },

    filtrar: async (e: any) => {
      // Attempt to verify if object already exists
      graficoGanhoPeso.filtro = parseInt(e?.value);
      graficoGanhoPeso.update();
    },
  }));

  async function sync(): Promise<void> {
    await syncModel.painelManejo();

    state.sync = true;

    const lamina = calcularLamina(store.tanques.arr);

    state.biomassa = store.tanques.producaoTotal;
    state.capacidade = store.tanques.capacidadeTotal;
    state.lamina = lamina.m2 + lamina.m3;
    state.qtd_tanques = store.tanques.length;

    graficoMortalidade.update();
    graficoEstoque.update();
    graficoGanhoPeso.filtrar(
      store.lotes?.map((lote) => {
        return { label: lote.codigo, value: lote.id };
      })[0],
    );
  }

  // async function sync(): Promise<void> {
  //   store.toggleLoader();
  //   await aqRelatorios
  //     .get('/manejo/' + store.propriedade?.uuid + '/visao-geral')
  //     .then((res) => {
  //       const data: VisaoGeralManejo = res.data;

  //       state.qtd_tanques = data.qtd_tanques;
  //       state.lamina = data.lamina;
  //       state.capacidade = data.capacidade;
  //       state.biomassa = data.biomassa;
  //       state.ganho_peso = data.ganho_peso;
  //       state.estoque_por_racao = data.estoque_por_racao;
  //       state.mortalidade = data.mortalidade;

  //       graficoMortalidade.update();
  //       graficoEstoque.update();

  //       const firstLote = { label: data.ganho_peso[0].lote.codigo, value: data.ganho_peso[0].lote.uuid };
  //       graficoGanhoPeso.filtrar(firstLote);
  //     })
  //     .catch(() => store.notificar('Ocorreu um erro ao reunir os dados'));

  //   store.toggleLoader();
  // }

  // useEffect(() => {
  //   if (store.sync) {
  //     sync();
  //   }
  //   // eslint-disable-next-line
  // }, [store.sync]);

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Produção'), []);
  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Flex>
          <div style={{ width: 250, marginRight: 15 }}>
            <Select
              placeholder="Relatório"
              options={relatoriosProd}
              defaultValue={relatoriosProd[0]}
              onChange={(e: any) => {
                history.push('/relatorios/' + e.value);
              }}
            />
          </div>
          <h1 style={{ color: '#383838' }}>Painel de Manejo</h1>
        </Flex>
        <div>
          <IconButton className="blue" onClick={sync}>
            <CachedIcon />
          </IconButton>
        </div>
      </Flex>

      <GridDados>
        <DadosCompactos>
          <p>Quantidade Tanques</p>
          <h1>{Formatter.formatNumber(0, state.qtd_tanques)}</h1>
        </DadosCompactos>
        <DadosCompactos>
          <p>Lâmina Total (m²/m³)</p>
          <h1>{Formatter.formatNumber(2, state.lamina)}</h1>
        </DadosCompactos>
        <DadosCompactos>
          <p>Capacidade Total (ton)</p>
          <h1>{Formatter.formatNumber(3, state.capacidade)}</h1>
        </DadosCompactos>
        <DadosCompactos>
          <p>Biomassa Atual (ton)</p>
          <h1>{Formatter.formatNumber(3, state.biomassa)}</h1>
        </DadosCompactos>
      </GridDados>

      <Conteudo>
        <h2>Mortalidade por tipo</h2>
        <ReactApexChart
          options={graficoMortalidade.options as any}
          height={300}
          series={graficoMortalidade.series}
          type="bar"
        />
      </Conteudo>
      <Conteudo>
        <h2>Estoque de Ração (kg)</h2>
        <ReactApexChart
          options={graficoEstoque.options as any}
          height={300}
          series={graficoEstoque.series}
          type="bar"
        />
      </Conteudo>
      <Conteudo>
        <Flex>
          <h2>Ganho de peso por lote</h2>
          <div style={{ width: 200, marginLeft: 15 }}>
            {state.sync && (
              <Select
                defaultValue={
                  store.lotes?.map((lote) => {
                    return { label: lote.codigo, value: lote.id };
                  })[0]
                }
                options={store.lotes?.map((lote) => {
                  return { label: lote.codigo, value: lote.id };
                })}
                onChange={graficoGanhoPeso.filtrar}
                placeholder="Selecione o lote"
              />
            )}
          </div>
        </Flex>
        <ReactApexChart
          options={graficoGanhoPeso.options as any}
          height={300}
          series={graficoGanhoPeso.series}
          type="area"
        />
      </Conteudo>
    </NewBody>
  );
};

export default observer(Manejo);
