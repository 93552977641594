import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Flex } from '../../../components/NavBar/styles';
import { NewBody, Conteudo } from '../../../components/Body';
import { IconButton, Tooltip } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import useStore from '../../../services/hooks/useStore';
import Select from '../../../components/Select';
import { useHistory } from 'react-router-dom';
import { useLocalStore, observer } from 'mobx-react-lite';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import { GridDados, DadosCompletos } from '../../../components/Dados';
import ReactApexChart from 'react-apexcharts';
import RelatorioFluxoDeCaixa from '../templates/FluxoCaixa';
import html2pdf from 'html3pdf';
import { aqReports } from '../../../services/utils';
import Formatter from '../../../services/Formatter';
import { permissoes } from '../../../services/utils/permissoes';
import ApexCharts from 'apexcharts';
const FluxoDeCaixa: React.FC = () => {
  const MIN_HEIGHT = 800;
  const store = useStore();

  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.FINANCEIRO_PAINEL_FINANCEIRO_LEITURA, history);
  const tableRef = useRef(null);

  const state = useLocalStore(() => ({
    heightOffset: window.innerHeight > MIN_HEIGHT ? 385 : 150,

    rows: [] as any[],

    total_vendas: 0,
    total_despesas: 0,
    total_outras: 0,
    total_saldo: 0,

    ano: new Date().getFullYear(),

    getAnos: () => {
      let anos: number[] = store.movimentacoes.map((mov) => {
        return mov;
      });

      anos = [...new Set(anos)];

      return anos
        .map((ano) => {
          return { label: ano.toString(), value: ano.toString() };
        })
        .reverse();
    },
  }));

  const graficoVendas = useLocalStore(() => ({
    series: [
      {
        name: 'Total (R$)',
        data: [{ x: 'Janeiro', y: 100 }],
      },
    ],
    options: {
      colors: ['#3ECC44'],
      chart: {
        id: 'vendas',
        height: 120,
        width: '100%',
        type: 'area',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: false,
      },
    },
    update: async (data: { x: string; y: number }[]) => {
      ApexCharts.exec('vendas', 'updateSeries', [
        {
          data,
        },
      ]);
    },
  }));
  const graficoDespesas = useLocalStore(() => ({
    series: [
      {
        name: 'Total (R$)',
        data: [{ x: 'Janeiro', y: 100 }],
      },
    ],
    options: {
      colors: ['#E71313'],
      chart: {
        id: 'despesas',
        height: 120,
        width: '100%',
        type: 'area',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: false,
      },
    },
    update: async (data: { x: string; y: number }[]) => {
      ApexCharts.exec('despesas', 'updateSeries', [
        {
          data,
        },
      ]);
    },
  }));
  const graficoOutras = useLocalStore(() => ({
    series: [
      {
        name: 'Total (R$)',
        data: [{ x: 'Janeiro', y: 100 }],
      },
    ],
    options: {
      colors: ['#484848'],
      chart: {
        id: 'outras',
        height: 120,
        width: '100%',
        type: 'area',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: false,
      },
    },
    update: async (data: { x: string; y: number }[]) => {
      ApexCharts.exec('outras', 'updateSeries', [
        {
          data,
        },
      ]);
    },
  }));
  const graficoSaldo = useLocalStore(() => ({
    series: [
      {
        name: 'Total (R$)',
        data: [{ x: 'Janeiro', y: 100 }],
      },
    ],
    options: {
      chart: {
        id: 'saldo',
        height: 120,
        width: '100%',
        type: 'area',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: false,
      },
    },
    update: async (data: { x: string; y: number }[]) => {
      ApexCharts.exec('saldo', 'updateSeries', [
        {
          data,
        },
      ]);
    },
  }));

  const columns: Column<FluxoRow>[] = [
    {
      field: 'name',
      title: 'Categorias',
      type: 'string',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'janeiro',
      title: 'Janeiro',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.janeiro);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'fevereiro',
      title: 'Fevereiro',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.fevereiro);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'março',
      title: 'Março',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.março);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'abril',
      title: 'Abril',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.abril);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'maio',
      title: 'Maio',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.maio);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'junho',
      title: 'Junho',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.junho);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'julho',
      title: 'Julho',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.julho);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'agosto',
      title: 'Agosto',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.agosto);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'setembro',
      title: 'Setembro',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.setembro);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'outubro',
      title: 'Outubro',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.outubro);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'novembro',
      title: 'Novembro',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.novembro);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'dezembro',
      title: 'Dezembro',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.dezembro);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'total',
      title: 'Total',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.total);
      },
      ...(true && ({ width: 140 } as object)),
    },
  ];

  function getPDF(): void {
    const htmlString = ReactDOMServer.renderToString(
      <RelatorioFluxoDeCaixa propriedade={store.propriedade!.nome} rows={state.rows} ano={state.ano} />,
    );

    const date = Formatter.dateToString(new Date(), '/');

    const opt = {
      margin: 0.2,
      filename: `Aquabit_${date}_FluxoDeCaixa_${state.ano}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
    };

    html2pdf().set(opt).from(htmlString).save();
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Fluxo de Caixa'), []);

  useEffect(() => {
    async function sync(): Promise<void> {
      store.toggleLoader();
      await store.movimentacoes.ano();
      (tableRef.current as any).onQueryChange();
      store.toggleLoader();
    }

    if (store.propriedade) {
      sync();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  return (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <div style={{ width: 200, marginRight: 15 }}></div>
        <Flex>
          <div style={{ width: 180, marginRight: 15 }}>
            {state.rows.length
              ? store.movimentacoes.arr && (
                  <Select
                    placeholder="Período"
                    options={state.getAnos()}
                    defaultValue={state.getAnos().reverse()[0]}
                    onChange={(e: any) => {
                      state.ano = parseInt(e.value);
                      (tableRef.current as any).onQueryChange();
                    }}
                  />
                )
              : null}
          </div>
          <Tooltip title="Exportar para Excel">
            <IconButton className="blue" onClick={() => store.notificar('Em Breve')}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton
              className="blue"
              onClick={() => {
                (tableRef.current as any).onQueryChange();
              }}
            >
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      {window.innerHeight > MIN_HEIGHT && (
        <GridDados>
          <DadosCompletos>
            <ReactApexChart
              options={graficoVendas.options as any}
              series={graficoVendas.series}
              type="area"
              height={120}
            />
            <p>Total de vendas (R$)</p>
            <h1 style={{ color: '#3ECC44' }}>{Formatter.formatNumber(2, state.total_vendas)}</h1>
          </DadosCompletos>
          <DadosCompletos>
            <ReactApexChart
              options={graficoDespesas.options as any}
              series={graficoDespesas.series}
              type="area"
              height={120}
            />
            <p>Total de despesas (R$)</p>
            <h1 style={{ color: '#E71313' }}>{Formatter.formatNumber(2, state.total_despesas)}</h1>
          </DadosCompletos>
          <DadosCompletos>
            <ReactApexChart
              options={graficoOutras.options as any}
              series={graficoOutras.series}
              type="area"
              height={120}
            />
            <p>Total de outras entradas (R$)</p>
            <h1 style={{ color: '#484848' }}>{Formatter.formatNumber(2, state.total_outras)}</h1>
          </DadosCompletos>
          <DadosCompletos>
            <ReactApexChart
              options={graficoSaldo.options as any}
              series={graficoSaldo.series}
              type="area"
              height={120}
            />
            <p>Saldo Total (R$)</p>
            <h1>{Formatter.formatNumber(2, state.total_saldo)}</h1>
          </DadosCompletos>
        </GridDados>
      )}

      <Conteudo style={{ padding: 0 }}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={(_) =>
            new Promise((resolve, reject) => {
              store.propriedade &&
                aqReports
                  .get('/relatorio/caixa/' + store.propriedade?.uuid, {
                    params: {
                      periodo: state.ano,
                    },
                  })
                  .then((res) => {
                    function getDisplayData(data: any): { x: string; y: number }[] {
                      return [
                        'janeiro',
                        'fevereiro',
                        'março',
                        'abril',
                        'maio',
                        'junho',
                        'julho',
                        'agosto',
                        'setembro',
                        'outubro',
                        'novembro',
                        'dezembro',
                      ].map((mes: string) => {
                        return {
                          x: mes.charAt(0).toUpperCase() + mes.slice(1),
                          y: data[mes],
                        };
                      });
                    }

                    state.total_vendas = res.data['vendas'].total;
                    state.total_despesas = res.data['despesas'].total;
                    state.total_outras = res.data['outras'].total;
                    state.total_saldo = res.data['saldo total'].total;

                    const totalCount = Object.keys(res.data).length;
                    const data: FluxoRow[] = [];

                    Object.keys(res.data).map((categoria) => {
                      res.data[categoria].name = categoria;

                      return null;
                    });

                    res.data['saldo inicial'].name = 'Saldo Inicial';
                    res.data['outras'].name = 'Outras Entradas';
                    res.data['vendas'].name = 'Total Receitas';
                    res.data['despesas'].name = 'Total Despesas';
                    res.data['saldo periodo'].name = 'Saldo Período';
                    res.data['saldo total'].name = 'Saldo Total';

                    data.push(res.data['saldo inicial']);

                    Object.keys(res.data).map((categoria) => {
                      if (res.data[categoria].tipo === 'outras') {
                        data.push(res.data[categoria]);
                      }
                      return null;
                    });

                    data.push(res.data['outras']);

                    Object.keys(res.data).map((categoria) => {
                      if (res.data[categoria].tipo === 'vendas') {
                        data.push(res.data[categoria]);
                      }
                      return null;
                    });

                    data.push(res.data['vendas']);

                    Object.keys(res.data).map((categoria) => {
                      if (res.data[categoria].tipo === 'despesas') {
                        data.push(res.data[categoria]);
                      }
                      return null;
                    });

                    data.push(res.data['despesas']);
                    data.push(res.data['saldo periodo']);
                    data.push(res.data['saldo total']);

                    function updateCharts(): void {
                      const displayDespesas = getDisplayData(res.data['despesas']);
                      graficoDespesas.update(displayDespesas);
                      const displayVendas = getDisplayData(res.data['vendas']);
                      graficoVendas.update(displayVendas);
                      const displayOutras = getDisplayData(res.data['outras']);
                      graficoOutras.update(displayOutras);
                      const displaySaldo = getDisplayData(res.data['saldo total']);
                      graficoSaldo.update(displaySaldo);
                    }
                    window.innerHeight > MIN_HEIGHT && updateCharts();

                    state.rows = data;

                    resolve({
                      data: data as any,
                      page: 0,
                      totalCount,
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                    reject();
                  });
            })
          }
          options={{
            ...newConfig.options,
            maxBodyHeight: window.innerHeight - state.heightOffset,
            minBodyHeight: window.innerHeight - state.heightOffset,
            paging: false,
            sorting: false,
            rowStyle: (rowData): any => {
              if (rowData.name === 'Outras Entradas') return { backgroundColor: 'hsla(0,0%,67.8%,.4)' };
              if (rowData.name === 'Total Receitas') return { backgroundColor: 'rgba(0,165,0,.4)' };
              if (rowData.name === 'Total Despesas') return { backgroundColor: 'rgba(206,0,0,.4)' };
              if (rowData.name === 'Saldo Período') return { backgroundColor: 'rgba(230,228,0,.4)' };
              if (rowData.name === 'Saldo Total') return { backgroundColor: 'rgba(0,204,230,.4)' };
            },
          }}
          localization={newConfig.localization}
          style={newConfig.style}
        />
      </Conteudo>
    </NewBody>
  );
};

export default observer(FluxoDeCaixa);
