/* eslint-disable react/display-name */
//libs
import React, { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useLocalStore, observer } from 'mobx-react-lite';
import MaterialTable, { Column } from 'material-table';
import { MenuList, MenuItem } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useHistory } from 'react-router-dom';
import ReactSelect from 'react-select';

//components
import Header from '../../../components/Header';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Body } from '../../../components/Body';
import { config } from '../../../components/Tabela';
import Display from '../../../components/Tabela/Display';
import ActionButton from '../../../components/Tabela/ActionButton';
import Confirmar from '../../../components/Modais/Confirmar';
import AjusteEstoque from './AjusteEstoque';
import FormInsumos from './FormInsumos';
// import FormCompra from './FormCompra';

//functions
import useModal from '../../../services/hooks/useModal';
import useStore from '../../../services/hooks/useStore';
import { apiV2 } from '../../../services/utils';
import sync from './helpers/sync';
import Formatter from '../../../services/Formatter';
import getAjuste from './helpers/getAjuste';
import deletarInsumo from './helpers/deletarInsumo';
import { Insumo } from '../../../@types/Insumo';
import dataValorUltimaCompra from './helpers/dataValorUltimaCompra';

//medias & styles
import { CompraInfo } from './styles';
import { Flex } from '../../../components/NavBar/styles';
import { Checkbox, FormControlLabel } from '@mui/material';
import { permissoes } from '../../../services/utils/permissoes';
import { RightButton } from '../../../components/Botoes/styles';
import ModalCompraInsumo from './ComprasInsumos/ModalCompraInsumo';

type State = PageState & {
  filtroRapidoInsumo: SelectRow | null;
  filtroRapidoMostrarTodos: boolean;
  filtroInsumo: SelectRow | null;
  filtroFornecedor: SelectRow | null;
  filtroDataInicio: MaterialUiPickersDate;
  filtroDataFim: MaterialUiPickersDate;
  selectedInsumo: number;
  selectedCompra: number;
  insumo: Insumo;
  filterUrlArray: string[];
  filterUrlString: string;
  sync: boolean;
};

const Insumos: React.FC = () => {
  const modalCompra = useModal();
  const modalExclusao = useModal();
  const modalAjuste = useModal();
  const modalInsumo = useModal();
  const store = useStore();
  const history = useHistory();
  const state = useLocalStore(startConfig.state);
  const tableRef: any = React.createRef();
  store.propriedade && store.validarPermissao(permissoes.CONTROLE_DE_INSUMOS_ESTOQUE_DE_INSUMOS_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.CONTROLE_DE_INSUMOS_ESTOQUE_DE_INSUMOS_ESCRITA);
  const compra = store.comprasDeInsumos.comprasDeInsumos ? store.comprasDeInsumos.get(state.selectedCompra) : null;

  const tableListColumns: Column<object>[] = [
    {
      field: 'nome',
      title: 'Insumos',
      ...(true && ({ width: 170 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          ajuste_estoque={() => {
            state.selectedCompra = rowData.id;
            state.insumo = rowData.row;
            modalAjuste.open();
          }}
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedInsumo = rowData.id;

                  modalInsumo.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita
              ? () => {
                  state.selectedInsumo = rowData.id;
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'tipo',
      title: 'Tipo',
      render: (rowData: any) => {
        if (rowData.tipo === 'Compras') {
          return <b>{rowData.tipo}</b>;
        } else {
          return <b style={{ color: '#42a5f5' }}>{rowData.tipo}</b>;
        }
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'ativo',
      title: 'Ativo',
      render: (rowData: any) => {
        return rowData.ativo ? 'Sim' : 'Não';
      },
      ...(true && ({ width: 140 } as object)),
    },

    {
      field: 'data_ini_estoque',
      title: 'Data Início',
      type: 'date',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'estoque',
      title: 'Estoque Atual',
      ...(true && ({ width: 140 } as object)),
      align: 'right',
    },
    {
      field: 'estoque_minimo',
      title: 'Estoque Mínimo',
      ...(true && ({ width: 140 } as object)),
      align: 'right',
    },
    {
      field: 'valor_total',
      title: 'Vr. Total',
      type: 'numeric',
      ...(true && ({ width: 120 } as object)),
    },
  ];

  useEffect(() => {
    if (state.filtroRapidoInsumo) {
      state.filterUrlArray.push(`&insumo=${state.filtroRapidoInsumo.value}`);
    }
    if (typeof state.filtroRapidoMostrarTodos === 'boolean') {
      state.filterUrlArray.push(`&mostrar_todos=${state.filtroRapidoMostrarTodos ? 'true' : ''}`);
    }
    if (state.filterUrlArray.length > 0 && store.propriedade) {
      state.filterUrlString = state.filterUrlArray.join('');
      store.insumos.getPaginated(0, 25, store.propriedade!.id, state.filterUrlString);
      state.filterUrlArray = [];
    }
    // eslint-disable-next-line
  }, [state.filtroRapidoInsumo, state.filtroRapidoMostrarTodos]);

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Estoque de Insumos'), []);

  async function syncInsumo() {
    // await store.insumos.getPaginated(0, 25, store.propriedade!.id, state.filterUrlString);
    const mostrarTodos = true;
    await store.insumos.populate(mostrarTodos);
    await store.comprasDeInsumos.populate();
    await store.fornecedores.populate();
    state.sync = true;
  }

  useEffect(() => {
    // store.propriedade && sync(state);
    if (store.propriedade) {
      syncInsumo();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  function limparFiltro(): void {
    state.filtroInsumo = null;
    state.filtroFornecedor = null;
    state.filtroDataFim = null;
    state.filtroDataInicio = null;
    state.filtroRapidoMostrarTodos = false;

    state.filterUrlArray = [];
    state.filterUrlString = '';
    store.insumos.getPaginated(0, 25, store.propriedade!.id);
  }

  async function recarregarTabela() {
    // (tableRef.current as any).onQueryChange();
    if (store.propriedade) await store.insumos.getPaginated(0, 25, store.propriedade!.id, state.filterUrlString);
  }

  const ExcluirRegistro: React.FC = () => {
    const registro = store.insumos.get(state.selectedInsumo);

    return (
      <div style={{ width: '100%' }}>
        <b>Tipo:</b> {registro && registro.tipo_produto ? 'Ajuste de Estoque' : 'Insumo'}
        <br />
        <b>Insumo: </b> {registro && registro.nome}
        <br />
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          {/* botão de adição de nova compra e menu de compra */}
          <AddBtn
            disabled={!temPermissaoEscrita}
            dropdown
            onClick={() => {
              state.selectedCompra = 0;
              modalCompra.open();
            }}
            text="NOVA COMPRA"
            Icon={AddIcon}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  state.selectedInsumo = 0;

                  modalInsumo.open();
                }}
              >
                Novo Insumo
              </MenuItem>
              <MenuItem onClick={modalAjuste.open}>Ajuste de estoque</MenuItem>
            </MenuList>
          </AddBtn>

          {/* select do nome de ração */}
          <div style={{ width: 200 }}>
            <ReactSelect
              value={state.filtroRapidoInsumo}
              classNamePrefix="sel"
              placeholder="Insumo"
              onChange={(e: any) => (state.filtroRapidoInsumo = e)}
              options={store.insumos.getSelectRows()}
            />
          </div>

          <FormControlLabel
            style={{ marginLeft: 15 }}
            control={
              <Checkbox
                checked={state.filtroRapidoMostrarTodos}
                onChange={(_e, checked) => {
                  if (state.filtroRapidoMostrarTodos) {
                    state.filtroRapidoMostrarTodos = false;
                  } else {
                    state.filtroRapidoMostrarTodos = checked;
                  }
                }}
                color="primary"
              />
            }
            label="Mostrar Todos"
          />
        </Flex>
        <Flex>
          {/* filtros e limpa filtro */}
          <RightButton cor="#e57373" onClick={limparFiltro}>
            Limpar Filtro
          </RightButton>
        </Flex>
      </Header>

      <Body gridColumns="1fr 500px">
        {/* tabela de listagem de insumos */}
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {
            <MaterialTable
              tableRef={tableRef}
              columns={tableListColumns}
              onChangePage={(page: number, pageSize: number) => {
                if (store.propriedade) {
                  store.insumos.getPaginated(page, pageSize, store.propriedade.id, state.filterUrlString);
                }
              }}
              data={store.insumos.insumos ? tableListRows(store, state, store.insumos.insumos as any) : []}
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          }
        </div>
        <div>
          {/* lista de minhas compras */}
          <Display
            verTudo={() => {
              history.push('/producao/insumos/compras');
            }}
            style={{ marginBottom: '10px', height: '55vh' }}
            overflowY
            title="Histórico de Compras/Ajustes"
          >
            {store.comprasDeInsumos?.map((compraDeInsumo) => {
              const stringData = compraDeInsumo.data.split(' ')[0];
              const valorUnitario = compraDeInsumo.valor_compra / compraDeInsumo.total_kg;

              return (
                <CompraInfo
                  key={compraDeInsumo.id}
                  onClick={() => {
                    state.selectedCompra = compraDeInsumo.id;
                    modalCompra.open();
                  }}
                >
                  <p className="left">{stringData}</p>
                  <p className="right">Vr. Total: R$ {Formatter.formatNumber(2, compraDeInsumo.valor_compra)}</p>
                  <p className="left">{compraDeInsumo.insumo.nome}</p>
                  <p className="right">Qtde: {Formatter.formatNumber(3, compraDeInsumo.total_kg)}</p>
                  <p className="left">
                    {compraDeInsumo.fornecedor.label !== null
                      ? compraDeInsumo.fornecedor.label
                      : compraDeInsumo.total_kg > 0
                      ? 'Ajuste de Estoque - Entrada'
                      : 'Ajuste de Estoque - Saída'}
                  </p>
                  <p className="right">Vr. Unit: R$ {Formatter.formatNumber(2, valorUnitario)}</p>
                </CompraInfo>
              );
            })}
          </Display>
        </div>
      </Body>
      {/* {modalCompra.state ? (
        <FormCompra open={modalCompra.state} handleClose={modalCompra.close} compraID={state.selectedCompra} />
      ) : null} */}
      {modalCompra.state && (
        <ModalCompraInsumo
          open={modalCompra.state}
          handleClose={modalCompra.close}
          onConfirm={() => sync(state)}
          compra={compra}
        />
      )}

      {modalAjuste.state ? (
        <AjusteEstoque
          open={modalAjuste.state}
          handleClose={modalAjuste.close}
          ajuste={getAjuste(store, state)}
          insumo={state.insumo}
        />
      ) : null}
      {modalInsumo.state ? (
        <FormInsumos
          open={modalInsumo.state}
          handleClose={modalInsumo.close}
          insumoID={state.selectedInsumo}
          handleUpdate={recarregarTabela}
        />
      ) : null}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirRegistro />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={async () => {
          await deletarInsumo(store, state, modalExclusao, sync, apiV2);
          await recarregarTabela();
        }}
      />
    </>
  ) : null;
};

export default observer(Insumos);

// ---------------------------------

const startConfig = {
  state: (): State => ({
    filtroRapidoMostrarTodos: false,
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    filtroRapidoInsumo: null,
    insumo: {} as Insumo,
    filtroInsumo: null,
    filtroFornecedor: null,
    filtroDataInicio: null,
    filtroDataFim: null,

    selectedInsumo: 0,
    selectedCompra: 0,

    filterUrlArray: [],
    filterUrlString: '',

    sync: false,
  }),
};

const tableListRows = (store: any, state: any, insumos: Insumo[]): object[] => {
  ajusteTamanhoTabela(state, insumos);

  const infoCompras = dataValorUltimaCompra(store);

  // mapeamento dos itens do array para  montar a tabela
  return insumos.map((insumo) => {
    const insumoData = infoCompras.find((insumoDataValor) => {
      return insumoDataValor.insumoId === insumo.id;
    });

    const objetoProduto = Object.assign({}, insumo.tipo_produto);
    return {
      id: insumo.id,
      nome: insumo.nome,
      tipo: Object.values(objetoProduto)[2],
      estoque: Formatter.formatNumber(3, insumo.estoque) + ' ' + insumo.tipo_unidade,
      estoque_minimo: Formatter.formatNumber(3, insumo.estoque_minimo) + ' ' + insumo.tipo_unidade,
      tipo_unidade: insumo.tipo_unidade,
      estoque_inicial: 0,
      tipo_produto: insumo.tipo_produto,
      data_ini_estoque: insumo.data_ini_estoque,
      valor_total: Formatter.formatNumber(2, insumoData?.insumoUltimaDataValor.ultimoValor || 0),
      ativo: insumo.ativo,
      row: insumo,
    };
    //TODO: fazer a verificação de se a operação é um ajuste de lote ou apenas um registro de compra
  });
};

const ajusteTamanhoTabela = (state: State, insumos: Insumo[]): void => {
  if (insumos.length) {
    if (insumos.length >= 50) {
      state.setPageSize(50);
    } else {
      state.setPageSize(25);
    }
  } else {
    state.setPageSize(0);
  }
};
