import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';

import { ModalBody, ModalHeader, Modal } from '../../../components/Modais/styles';
import { Flex } from '../../../components/NavBar/styles';
import Botao from '../../../components/Botoes/Botao';
import InputNumero from '../../../components/InputNumero';
import Select from '../../../components/Select';
import useStore from '../../../services/hooks/useStore';

type Form = {
  categoria: SelectRow | null;
  racao: SelectRow | null;
  descricao: string;
  valorTotal: number;
  quantidade: number;
  valorUnitario: number;
  controlaSc: boolean;
  qtdeKgPorSc: number;
};

type Props = {
  open: boolean;
  handleClose: () => void;
  handleAdd: (dados: DespesaItemCriacao) => void;
  handleUpdate: (id: number, dados: DespesaItemCriacao) => void;
  item: DespesaItemCriacao | null;
  categoria: string;
  valorFrete?: number;
  outrosValores?: number;
};

const numeroNaoExiste = -99;

export function ModalComprasItem({ open, handleClose, handleAdd, handleUpdate, item, categoria }: Props) {
  const store = useStore();
  const [sync, setSync] = useState(false);

  const categoriasRacoesNomes = ['Ração Alevinos', 'Ração Crescimento', 'Ração Engorda'];
  const categoriasOptions = store.categorias
    .filter((cat) => categoriasRacoesNomes.includes(cat.nome))
    .map((categoria) => {
      return { label: categoria.nome, value: categoria.id };
    });
  const racaoOptions = store.racoes.getSelectRows();

  const defaultCategoriaId = Number(categoria) || null;
  const categoriaDefault = categoriasOptions.find((cat) => cat.value === defaultCategoriaId) || null;

  const defaultForm: Form = {
    categoria: categoriaDefault,
    racao: null,
    descricao: '',
    quantidade: 0,
    valorTotal: 0,
    valorUnitario: 0,
    controlaSc: false,
    qtdeKgPorSc: 0,
  };
  const initialForm: Form = item
    ? {
        categoria: null,
        racao: null,
        descricao: item.descricao,
        quantidade: item.quantidade,
        valorTotal: item.valor_total_item,
        valorUnitario: item.valor_uni_item,
        controlaSc: item.controla_sc || false,
        qtdeKgPorSc: item.quantidade_kg_sc || 0,
      }
    : defaultForm;
  const [form, setForm] = useState<Form>(initialForm);

  function handleSubmit() {
    if (!form.categoria) store.notificar('Informe a categoria');
    else if (!form.descricao) store.notificar('Informe a descrição');
    else if (!form.quantidade || form.quantidade <= 0) store.notificar('Informe a quantidade');
    else if (!form.valorTotal || form.valorTotal <= 0) store.notificar('Informe o valor total');
    else if (!form.valorUnitario || form.valorUnitario <= 0)
      store.notificar('Não foi possível calcular o valor unitário');
    else {
      const dados: DespesaItemCriacao = {
        id: item ? item.id : new Date().getTime(),
        categoria: form.categoria?.value,
        descricao: form.descricao,
        quantidade: form.quantidade,
        valor_uni_item: form.valorUnitario,
        valor_total_item: form.valorTotal,
        racao: form.racao?.value,
        valor_total: form.controlaSc ? 0 : form.valorTotal,
        valor_unitario: form.controlaSc ? 0 : form.valorUnitario,
        valor_outros_item: form.controlaSc ? 0 : form.valorTotal,
        valor_frete_item: form.controlaSc ? 0 : form.valorTotal,
        controla_sc: form.controlaSc,
        quantidade_kg_sc: form.qtdeKgPorSc,
      };

      if (item) handleUpdate(item.id, dados);
      else handleAdd(dados);

      handleClose();
    }
  }

  function updateFormEvent(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    if (name) {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }

  useEffect(() => {
    setSync(true);
  }, []);

  useEffect(() => {
    setForm(initialForm);
  }, [open]);

  useEffect(() => {
    if (item) {
      const categoria = categoriasOptions.find((cat) => cat.value === item.categoria) ?? null;
      const racao = racaoOptions.find((cat) => +cat.value === Number(item?.racao || numeroNaoExiste)) ?? null;
      setForm((prev) => ({ ...prev, categoria, racao }));
    }
  }, [item]);

  useEffect(() => {
    if (form.quantidade > 0)
      setForm((prev) => ({
        ...prev,
        valorUnitario: +(prev.valorTotal / prev.quantidade).toFixed(2),
      }));
  }, [form.quantidade, form.valorTotal]);

  useEffect(() => {
    if (form.racao) {
      const racaoSelecionada = store.racoes.get(parseInt(form.racao.value));
      setForm(prev => ({
        ...prev,
        controlaSc: racaoSelecionada?.controla_sc || false,
        qtdeKgPorSc: racaoSelecionada?.quantidade_kg_sc || 0,
        descricao: form.racao?.label ?? '',
        valorTotal: 0,
        quantidade: 0,
        valorUnitario: 0
      }));
    }
  }, [form.racao]);

  if (!sync) return null;
  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{item ? 'Editar' : 'Novo'} Item</ModalHeader>
      <ModalBody style={{ width: 500 }}>
        <Box width="100%">
          <Select
            placeholder="Categoria"
            value={form.categoria}
            onChange={(e: any) => setForm((prev) => ({ ...prev, categoria: e }))}
            options={categoriasOptions}
          />
          <Select
            value={form.racao}
            placeholder="Nome da ração"
            onChange={(e: any) => setForm((prev) => ({ ...prev, racao: e }))}
            options={racaoOptions}
          />
          <TextField
            value={form.descricao}
            onChange={updateFormEvent}
            name="descricao"
            label="Descrição"
            inputProps={{ maxLength: 100 }}
          />
          <InputNumero
            value={form.valorTotal}
            onChange={(value) => setForm((prev) => ({ ...prev, valorTotal: value }))}
            precision="2"
            label="Valor Total"
          />
          {form.controlaSc ? (
            <>
              <InputNumero
                disabled={true}
                value={form.qtdeKgPorSc}
                precision="2"
                label="Qtde Kg por SC"
              />
              <InputNumero
                value={form.quantidade}
                onChange={(value) => setForm(prev => ({ ...prev, quantidade: value }))}
                precision="0"
                label="Quantidade Sacos"
              />
            </>
          ) : (
            <InputNumero
              value={form.quantidade}
              onChange={(value) => setForm(prev => ({ ...prev, quantidade: value }))}
              precision="2"
              label="Quantidade (kg)"
            />
          )}
          <InputNumero
            value={form.valorUnitario}
            disabled
            onChange={(value) => setForm((prev) => ({ ...prev, valorUnitario: value }))}
            precision="2"
            label="Valor Unitário"
          />

          <Flex>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={handleSubmit} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </Flex>
        </Box>
      </ModalBody>
    </Modal>
  );
}
