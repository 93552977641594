import React, { useEffect, useState } from 'react';
import { TextField, IconButton, FormControlLabel, Switch } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';

import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import { api } from '../../../services/utils';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import Confirmar from '../../../components/Modais/Confirmar';
import InputNumero from '../../../components/InputNumero';
import Formatter from '../../../services/Formatter';
import Select from '../../../components/Select';

type Props = ModalProps & {
  racaoID: number;
};

type Option = {
  label: string;
  value: string | number | undefined;
  uuid: string;
};

const AddRacao: React.FC<Props> = ({ open, handleClose, racaoID }) => {
  const store = useStore();
  const racao = store.racoes.get(racaoID);

  const [marcasOptions, setMarcasOptions] = useState<Option[]>([{ label: '⠀', value: undefined, uuid: '' }]);

  const state = useLocalStore(() => ({
    nome: racao ? racao!.nome : '',
    tamanho: racao ? racao!.tamanho : '',
    proteina_bruta: racao ? racao!.proteina_bruta : '',
    marca: (racao ? marcasOptions.find((op) => op.uuid === racao!.marca) || null : null) as SelectRow | null,
    ativo: racao ? racao!.ativo : true,
    controla_sc: racao ? racao!.controla_sc : false,
    racaoID: racaoID,
    data: !racao
      ? new Date()
      : racao.data_ini_estoque
        ? (new Date(
          racao.data_ini_estoque.substr(3, 2) +
          '/' +
          racao.data_ini_estoque.substr(0, 2) +
          '/' +
          racao.data_ini_estoque.substr(6, 4),
        ) as MaterialUiPickersDate)
        : null,
    estoque_ini: racao ? racao.estoque_inicial : 0,
    estoque_ini_sc: racao ? racao.estoque_inicial / racao.quantidade_kg_sc : 0,
    estoque_minimo: racao ? racao.estoque_minimo : 0,
    estoque_minimo_sc: racao ? racao.estoque_minimo / racao.quantidade_kg_sc : 0,
    peso_saco: racao ? racao.quantidade_kg_sc : 25,
   
    getEstoqueEmKgSC: (valorSacos: number) => {
      return state.controla_sc ? valorSacos * state.peso_saco : valorSacos;
    }
  }));
  const modalExclusao = useModal();

  async function cadastrar(): Promise<void> {
    const atualizarRacao = !!racao;
    if (state.nome === '') {
      store.notificar('Informe o nome da ração!');
      return;
    }
    if (state.data === null) {
      store.notificar('Informe uma data!');
      return;
    }
    if (!atualizarRacao && store.racoes.map((racao) => racao?.nome).includes(state.nome)) {
      store.notificar('Já existe uma ração com este nome, informe outro');
      return;
    }

    store.toggleLoader();
    if (atualizarRacao) {
      await api
        .put('/racao/' + racao?.id + '/', {
          nome: state.nome,
          data_ini_estoque: Formatter.dateToString(state.data),
          estoque_inicial: state.estoque_ini,
          estoque_minimo: state.estoque_minimo,
          propriedade: store.propriedade!.id,
          ativo: state.ativo,
          controla_sc : state.controla_sc,
          quantidade_kg_sc  : state.peso_saco,
          tamanho: state.tamanho ?? undefined,
          proteina_bruta: state.proteina_bruta ?? undefined,
          marca: state.marca?.value ?? undefined,
        })
        .then(() => {
          store.notificar('Ração editada com sucesso!');
          handleClose();
        })
        .catch((error) => {
          if (error?.response?.data?.tamanho[0]) {
            store.notificar('Certifique-se que o campo Tamanho não tenha mais de 8 caracteres.');
          } else {
            store.notificar('Ocorreu um erro na edição da ração!');
          }
        });
    } else {
      await api
        .post('/racao/', {
          nome: state.nome,
          data_ini_estoque: Formatter.dateToString(state.data),
          estoque_inicial: state.estoque_ini,
          estoque_minimo: state.estoque_minimo,
          propriedade: store.propriedade!.id,
          ativo: state.ativo,
          controla_sc : state.controla_sc,
          quantidade_kg_sc  : state.peso_saco,
          tamanho: state.tamanho ?? undefined,
          proteina_bruta: state.proteina_bruta ?? undefined,
          marca: state.marca?.value ?? undefined,
        })
        .then(() => {
          store.notificar('Ração cadastrada com sucesso!');
          handleClose();
        })
        .catch((error) => {
          if (error?.response?.data?.tamanho[0]) {
            store.notificar('Certifique-se que o campo Tamanho não tenha mais de 8 caracteres.');
          } else {
            store.notificar('Ocorreu um erro no cadastro da ração!');
          }
        });
    }
    await store.racoes.populate();
    store.toggleLoader();
  }

  async function deletar(): Promise<void> {
    if (store.comprasDeRacoes!.filter((compra) => compra.racao.id === racao!.id).length > 0) {
      store.notificar('Essa ração já possui movimentações!');
    } else {
      handleClose();
      store.toggleLoader();
      await api
        .delete(`/racao/${racao!.id}/?propriedade=${store.propriedade!.id}`)
        .then(() => store.notificar('Ração excluída com sucesso!'))
        .catch(() => store.notificar('Ocorreu um erro na exclusão da ração!'));
      await store.racoes.populate();
      store.toggleLoader();
    }
  }

  async function getMarcas() {
    if (store.marcas.arr?.length === 0) await store.marcas.populate();

    setMarcasOptions([
      { label: '⠀', value: undefined, uuid: '' },
      ...store.marcas
        .filter((marca) => marca.ativo === true && marca.removido === false)
        .map((marca) => ({ value: marca.id, label: marca.nome, uuid: marca.uuid })),
    ]);
  }

  useEffect(() => {
    getMarcas();
  }, []);

  // checa a marca selecionada sempre que a lista marcasOptions mudar
  useEffect(() => {
    state.marca = marcasOptions.find((op) => op.uuid === racao?.marca) || null;
  }, [marcasOptions]);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>
        {racaoID ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Editar ração</p>
            <IconButton onClick={modalExclusao.open}>
              <DeleteIcon />
            </IconButton>
          </div>
        ) : (
          'Cadastro de ração'
        )}
      </ModalHeader>
      <ModalBody>
        <TextField label="Nome da Ração" value={state.nome} onChange={(e) => (state.nome = e.target.value)} />
        <TextField
          label='Tamanho (mm) - "máx 8 caracter"'
          value={state.tamanho}
          onChange={(e) => (state.tamanho = e.target.value)}
        />
        <TextField
          label="Proteína Bruta (%)"
          type="number"
          InputProps={{
            inputProps: { min: 0 }
          }}
          value={state.proteina_bruta}
          onChange={(e) => (state.proteina_bruta = e.target.value ? Number(e.target.value) : '')}
        />
        <div style={{ width: '100%', marginTop: 10 }}>
          <Select
            value={state.marca}
            placeholder="Marca"
            onChange={(e: any) => (state.marca = e)}
            options={marcasOptions}
          />
        </div>

        <div style={{ width: '100%' }}>
          <FormControlLabel
            control={<Switch checked={state.ativo} onChange={() => (state.ativo = !state.ativo)} color="primary" />}
            label={state.ativo ? 'Ativa' : 'Desativa'}
          />

        </div>
        <div style={{ width: '100%' }}>
          <FormControlLabel
            control={<Switch checked={state.controla_sc} onChange={() => (state.controla_sc = !state.controla_sc)} color="primary" />}
            label={'Controla ração por saco'}
          />
        </div>

        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data de Início do Estoque"
          onChange={(e) => (state.data = e)}
          value={state.data}
        />

        {state.controla_sc && (
          <>
            <InputNumero
              value={state.peso_saco}
              onChange={(e) => {
                state.peso_saco = e;
                state.estoque_ini = state.getEstoqueEmKgSC(state.estoque_ini_sc)
                state.estoque_minimo = state.getEstoqueEmKgSC(state.estoque_minimo_sc)
              }}
              precision="3"
              label="Peso do Saco (Kg)"
            />
          </>
        )}
        
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        {state.controla_sc && (
          <InputNumero
            value={state.estoque_ini_sc}
            onChange={(e) => {
              state.estoque_ini_sc = e
              state.estoque_ini = state.getEstoqueEmKgSC(e)
            }}
            precision="0"

            label={"Estoque Inicial (SC)"}
          />
        )}
          <InputNumero
            value={state.estoque_ini}
            onChange={(e) => {
              state.estoque_ini = e;

            }}
            precision="3"
            disabled={state.controla_sc}
            label={"Estoque Inicial (Kg)"}
          />

        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          {state.controla_sc && (
            <InputNumero
              value={state.estoque_minimo_sc}
              onChange={(e) => {
                state.estoque_minimo_sc = e;
                state.estoque_minimo = state.getEstoqueEmKgSC(e)
              }}
              precision="0"

              label={"Estoque Mínimo (SC)"}
            />
          )}
          <InputNumero
            value={state.estoque_minimo}
            onChange={(e) => {
              state.estoque_minimo = e;
            }}
            precision="3"
            disabled={state.controla_sc}
            label={"Estoque Mínimo (Kg)"}
          />

        </div>

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={cadastrar} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </div>
      </ModalBody>
      <Confirmar
        open={modalExclusao.state}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
    </Modal>
  );
};

export default observer(AddRacao);
