import React from 'react';
import { TextField } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import InputNumero from '../../../components/InputNumero';
import SelecionarFornecedor from './SelecionarFornecedor';
import { apiV2 } from '../../../services/utils';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import add from 'date-fns/add';
import Formatter from '../../../services/Formatter';

import SelecionarLote from '../../../components/Modais/SelecionarLote';
import SelecionarLoteTanques from './SelecionarLoteTanques';

type State = {
  codigo: string;
  especie: SelectRow | null;
  fornecedor: SelectRow | null;
  fornecedorNome: string;
  fase: SelectRow | null;
  data: MaterialUiPickersDate;
  valor: number;
  pesoMedio: number;
  tamanho: number;
  tanques: SelectedTanque[];
  quantidade: number;
  diasPrevistos: number;
  dataPrevista: MaterialUiPickersDate;
  selectFornecedor: boolean;
};

type SelectedLotState = {
  lote: SelectRow | null;
  data: MaterialUiPickersDate;
  tanque: SelectRow | null;
  amostragens: object[];
  media: number;
  selectLote: boolean;
  lote_uuid: string;
  nome: string;
};

const startConfig = {
  state: (): State => ({
    codigo: '',
    especie: null,
    fornecedor: null,
    fornecedorNome: '',
    fase: null,
    valor: 0,
    data: new Date(),
    pesoMedio: 0,
    tamanho: 0,
    tanques: [],
    quantidade: 0,
    diasPrevistos: 0,
    dataPrevista: new Date(),
    selectFornecedor: false,
  }),
  state2: (): SelectedLotState => ({
    lote: null,
    data: new Date(),
    tanque: null,
    amostragens: [],
    media: 0,
    selectLote: true,
    lote_uuid: '',
    nome: '',
  }),
};

const AjustarLote: React.FC<ModalProps> = ({ open, handleClose }) => {
  const store = useStore();
  const state = useLocalStore(startConfig.state);
  const selectedLotState = useLocalStore(startConfig.state2);
  const modalTanques = useModal();
  const modalFornecedor = useModal();
  const modalSelecionarLote = useModal();

  async function checarCadastro(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    if (!state.especie) store.notificar('Selecione a espécie do lote');
    else if (!state.fase) store.notificar('Selecione a fase do lote');
    else if (state.pesoMedio === 0) store.notificar('Informe o peso médio do lote');
    else if (!selectedLotState.tanque) store.notificar('Selecione um tanque');
    else {
      ajustarLote();
    }
  }

  // function removerTanque(tanque: any): void {
  //   state.quantidade -= parseInt(tanque.quantidade);
  //   const newTanques = state.tanques.filter((tanqueArr) => tanqueArr !== tanque);
  //   state.tanques = newTanques;
  // }

  async function ajustarLote(): Promise<void> {
    store.toggleLoader();
    modalTanques.close();
    handleClose();

    const loteTanque: any = [
      {
        tanque: parseInt(selectedLotState.tanque!.value),
        quantidade: parseInt(state.quantidade.toString()),
        fase_lote: parseInt(state.fase!.value),
      },
    ];

    try {
      for (const tanque of loteTanque) {
        const dataAjuste = {
          especie_explorada: parseInt(state.especie!.value),
          codigo: state.codigo,
          peso_medio: state.pesoMedio,
          quantidade: tanque.quantidade,
          data: Formatter.dateToString(state.data),
          lote_tanque: tanque.tanque,
          fase_lote: tanque.fase_lote,
          propriedade: store.propriedade!.id,
        };

        await apiV2.post(`/ajuste/?propriedade=${store.propriedade!.id}`, dataAjuste);
      }

      store.notificar('Ajuste de lote realizado com sucesso!');
    } catch (err) {
      store.notificar('Ocorreu um erro ao ajustar o lote!');
      console.error(err);
    } finally {
      store.toggleLoader();
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Ajuste de Lote</ModalHeader>
      <ModalBody>
        <form onSubmit={checarCadastro}>
          {/* <Botao onClick={modalSelecionarLote.open}>Selecione um lote existente</Botao> */}
          <Botao onClick={modalTanques.open}>Selecione o lote e tanque</Botao>
          <TextField value={selectedLotState.nome} type="text" label="Código do Lote" disabled />

          <TextField value={selectedLotState.tanque?.label || ''} type="text" label="Tanque" disabled />

          <div style={{ width: '100%' }}>
            {store.especies.arr && (
              <Select
                value={state.especie}
                placeholder="Espécie"
                onChange={(e: any) => (state.especie = e)}
                options={store.especies.getSelectRows()}
                disabled
              />
            )}
          </div>

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data"
            value={state.data}
            disabled
            onChange={(date) => {
              state.data = date;

              state.dataPrevista = add(state.data!, { days: state.diasPrevistos });
            }}
          />

          <div style={{ width: '100%' }}>
            <Select
              key="faseKey"
              value={state.fase}
              placeholder="Fase"
              disabled
              onChange={(e: any) => (state.fase = e)}
              options={store.fasesLote.getSelectRows()}
            />
          </div>

          <InputNumero
            value={state.quantidade}
            onChange={(value) => (state.quantidade = value)}
            precision="0"
            label="Quantidade"
          />

          <InputNumero
            value={state.pesoMedio}
            onChange={(value) => (state.pesoMedio = value)}
            precision="3"
            label="Peso Médio (g)"
          />

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao type="submit" variant="contained" cor="#00C853">
              Cadastrar
            </Botao>
          </div>
        </form>
      </ModalBody>
      {modalTanques.state ? (
        <SelecionarLoteTanques
          open={modalTanques.state}
          handleClose={modalTanques.close}
          setTanque={(tanque) => console.log(tanque)}
          setLote={(lote: any) => {
            selectedLotState.nome = lote.lote.codigo;
            selectedLotState.lote_uuid = lote.uuid;
            state.codigo = lote.lote.codigo;
            state.data = new Date(lote.lote.data);
            state.fase = { label: lote.fase_lote.nome, value: lote.fase_lote.id };
            state.especie = { label: lote.especie_explorada.nome, value: lote.especie_explorada.id };
            state.pesoMedio = lote.peso_medio;
            selectedLotState.tanque = { label: lote.tanque.nome, value: lote.id };
          }}
          exclude={[]}
        />
      ) : null}
      {modalSelecionarLote.state && (
        <SelecionarLote
          open={modalSelecionarLote.state}
          handleClose={modalSelecionarLote.close}
          onItemClick={(lote) => {
            state.codigo = lote.codigo;
            state.especie = { label: lote.especie_explorada.nome, value: lote.especie_explorada.id };
            state.fornecedor = { label: lote.fornecedor.nome, value: lote.fornecedor.id };
            state.data = new Date(lote.data);
            state.fase = { label: lote.fase_lote.nome, value: lote.fase_lote.id };
            state.quantidade = lote.quantidade;
            state.valor = lote.valor_total;
            state.pesoMedio = lote.peso_medio;
            state.tamanho = lote.tamanho_medio;
            state.diasPrevistos = lote.dias_previstos;
            selectedLotState.lote_uuid = lote.uuid;
            selectedLotState.nome = lote.codigo;
          }}
          loteID={selectedLotState.lote ? parseInt(selectedLotState.lote.value) : null}
        />
      )}
      <SelecionarFornecedor
        onConfirm={(fornecedor: SelectRow) => {
          modalFornecedor.close();
          state.fornecedor = fornecedor;
        }}
        open={modalFornecedor.state}
        handleClose={modalFornecedor.close}
      />
    </Modal>
  );
};

export default observer(AjustarLote);
