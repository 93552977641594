/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import InputNumero from '../../../components/InputNumero';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Botao from '../../../components/Botoes/Botao';
import useModal from '../../../services/hooks/useModal';
import SelecionarLoteTanques from './SelecionarLoteTanques';
import { returnTanque } from '../../../services/utils/propriedade';
import { api } from '../../../services/utils';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Formatter from '../../../services/Formatter';
import { returnQuantidade } from '../../../services/utils/producao';
import NewSelect from '../../../components/Select/newSelect';
import QuantidadeModal from './QuantidadeModal';
import { Flex } from '../../../components/NavBar/styles';

type Props = ModalProps & {
  arracoamento?: Arracoamento;
  onConfirm(): void;
};
const FormArracoamento: React.FC<Props> = ({ handleClose, open, arracoamento, onConfirm }) => {
  const [selectedLoteTanque, setSelectedLoteTanque] = useState<any>(null);
  const store = useStore();
  const modalTanques = useModal();
  const modalQuantidade = useModal();
  const state = useLocalStore(() => ({
    sync: false,
    racao: arracoamento ? { value: arracoamento?.racao.id.toString(), label: arracoamento?.racao.nome } : null,
    quantidade: arracoamento?.vez_dia || 0,
    data: (arracoamento ? new Date(arracoamento?.data) : new Date()) as MaterialUiPickersDate,
    lancado: arracoamento ? arracoamento?.lancado : false,
    tanque_data: (arracoamento?.tanque_data.map((tanqueData: any) => {
      return {
        uuid: tanqueData.uuid,
        tanque: tanqueData.tanque.id,
        lote_tanque: tanqueData.lote_tanque.id,
        tipo: tanqueData.tipo,
        percentual_biomassa: tanqueData.percentual_biomassa,
        percentual_temperatura: tanqueData.percentual_temperatura,
        peso: tanqueData.peso,
        qtd_racao: arracoamento && arracoamento.lancado ? tanqueData.peso : tanqueData.qtd_racao,
        qtd_trato: tanqueData.qtd_trato,
        qtd_kg_trato: tanqueData.qtd_kg_trato,
        estoque: tanqueData.estoque,
        biomassa: tanqueData.biomassa,
      };
    }) || []) as TanqueData[],

    setRacao(e: SelectRow) {
      this.racao = e;
    },
  }));

  async function enviar(): Promise<void> {
    if (!state.racao) store.notificar('Informe a ração');
    else if (state.quantidade === 0) store.notificar('Informe a quantidade de vezes');
    else if (state.tanque_data.length === 0) store.notificar('Selecione um ou mais tanques');
    else {
      store.toggleLoader();
      handleClose();

      const tanque_data = state.tanque_data.map((tanqueData) => {
        if (state.lancado) {
          tanqueData.peso = tanqueData.qtd_racao;
        }

        return tanqueData;
      });

      const data = {
        vez_dia: state.quantidade,
        racao: parseInt(state.racao.value),
        lancado: state.lancado,
        data: Formatter.dateToString(state.data),
        tanque_data,
        editavel: true,
      };

      // Checa se o usuário edtá editando ou cadastrando
      if (arracoamento) {
        await api
          .patch(`/arracoamento/${arracoamento.id}/?propriedade=${store.propriedade!.id}`, { ...data })
          .then(async () => {
            store.notificar('Arraçoamento editado com sucesso!');
            await store.racoes.populate();
            onConfirm();
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.erro || 'Ocorreu um erro ao editar o arraçoamento!';
            store.notificar(errorMessage);
          });
      } else {
        await api
          .post(`/arracoamento/?propriedade=${store.propriedade!.id}`, data)
          .then(async () => {
            store.notificar('Arraçoamento cadastrado com sucesso!');
            await store.racoes.populate();
            onConfirm();
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.erro || 'Ocorreu um erro ao cadastrar o arraçoamento!';
            store.notificar(errorMessage);
          });
      }

      store.toggleLoader();
    }
  }

  async function sync(): Promise<void> {
    store.toggleLoader();
    await store.loteTanque.populate();
    await store.tanques.populate();
    await store.racoes.populate();
    state.sync = true;
    store.toggleLoader();
  }

  useEffect(() => {
    sync();
    // eslint-disable-next-line
  }, []);

  return state.sync ? (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{arracoamento ? 'Editar' : 'Novo'} arraçoamento</ModalHeader>
      <ModalBody style={{ width: '700px', overflowX: 'hidden' }}>
        <NewSelect
          value={state.racao}
          placeholder="Ração"
          onChange={state.setRacao}
          options={store.racoes.getSelectRows()}
        />

        <InputNumero
          value={state.quantidade}
          onChange={(val) => (state.quantidade = val)}
          label="Quantidade de Tratos"
          precision="0"
        />

        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data"
          value={state.data}
          onChange={(date) => (state.data = date)}
        />

        <div
          style={{
            width: '100%',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={state.lancado}
                onChange={(e) => {
                  state.lancado = e.target.checked;
                }}
                color="primary"
              />
            }
            label="Lançado"
          />
        </div>

        <Botao onClick={modalTanques.open}>Selecione o(s) tanque(s)</Botao>
        {state.tanque_data.length > 0
          ? state.tanque_data.map((tanqueData: any) => {
              const loteTanque = store.loteTanque.get(tanqueData.lote_tanque);
              const tanque = store.tanques.get(tanqueData.tanque);
              return (
                <div
                  key={tanqueData.lote_tanque}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <p>{returnTanque(tanque)}</p>
                  <p>{Formatter.formatNumber(0, returnQuantidade(loteTanque, false))} un</p>
                  <p>{Formatter.formatNumber(3, tanqueData.qtd_racao)} kg</p>
                  <p>{Formatter.formatNumber(0, tanqueData.qtd_trato)}x</p>
                  <p>{Formatter.formatNumber(3, tanqueData.qtd_kg_trato)}kg/1x</p>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        (state.tanque_data = state.tanque_data.filter(
                          (tanqueDataMap) => tanqueDataMap.lote_tanque !== tanqueData.lote_tanque,
                        ))
                      }
                    >
                      <DeleteIcon />
                    </IconButton>

                    <IconButton
                      id="editar"
                      onClick={() => {
                        setSelectedLoteTanque({
                          ...loteTanque,
                          estoque: tanqueData.estoque,
                          biomassa: tanqueData.biomassa,
                          qtd_trato: tanqueData.qtd_trato,
                          qtd_kg_trato: tanqueData.qtd_kg_trato,
                          qtd_racao: tanqueData.qtd_racao,
                        });
                        modalQuantidade.open();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })
          : null}
      </ModalBody>
      <Flex style={{ width: '90%', margin: 10 }}>
        <Botao onClick={handleClose} variant="contained" cor="#FC7467">
          Cancelar
        </Botao>
        <Botao onClick={enviar} variant="contained" cor="#00C853">
          Salvar
        </Botao>
      </Flex>
      {modalTanques.state ? (
        <SelecionarLoteTanques
          periodo={false}
          open={modalTanques.state}
          handleClose={modalTanques.close}
          onConfirm={(tanqueData) => {
            if (state.tanque_data.some((tqData) => tqData.lote_tanque === tanqueData.lote_tanque)) {
              state.tanque_data.map((tqData, indice) => {
                if (tqData.lote_tanque === tanqueData.lote_tanque) {
                  const uuid = state.tanque_data[indice].uuid || undefined;
                  state.tanque_data[indice] = { ...tanqueData, uuid };
                }

                return null;
              });
            } else {
              state.tanque_data.push(tanqueData);
            }
          }}
          exclude={state.tanque_data.map((tanqueData) => tanqueData.lote_tanque)}
          states={state}
        />
      ) : null}
      {modalQuantidade.state ? (
        <QuantidadeModal
          periodo={false}
          open={modalQuantidade.state}
          handleClose={modalQuantidade.close}
          onConfirm={(tanqueData) => {
            tanqueData.tanque = selectedLoteTanque!.tanque.id;
            tanqueData.lote_tanque = selectedLoteTanque!.id;
            if (state.tanque_data.some((tqData) => tqData.lote_tanque === tanqueData.lote_tanque)) {
              state.tanque_data.map((tqData, indice) => {
                if (tqData.lote_tanque === tanqueData.lote_tanque) {
                  const uuid = state.tanque_data[indice].uuid || undefined;
                  state.tanque_data[indice] = { ...tanqueData, uuid };
                }

                return null;
              });
            } else {
              state.tanque_data.push(tanqueData);
            }
          }}
          loteTanque={selectedLoteTanque!}
          states={state}
          diasDiferenca={0}
          quantidadeAnimais={0}
        />
      ) : null}
    </Modal>
  ) : null;
};

export default observer(FormArracoamento);
