/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import { LoteTanqueInfo } from '../../../components/LoteTanqueInfo';
import { observer, useLocalStore } from 'mobx-react-lite';
import { returnTanque } from '../../../services/utils/propriedade';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';
import { TextField } from '@material-ui/core';

type Props = ModalProps & {
  setTanque(tanque: SelectRow): void;
  setLote:any;
  exclude: number[];
  diasDiferenca?: number;
};
const SelecionarLoteTanques: React.FC<Props> = ({ open, handleClose, setTanque, setLote }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    sync: false,
  }));
  const [loteTanques, setLoteTanques] = useState<LoteTanque[]>([] as LoteTanque[]);
  const [, setSelectedLoteTanque] = useState<LoteTanque | null>(null);
  const [codigo, setCodigo] = useState<string>('');
  const [, setSelectedQuantidade] = useState<any | null>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      store.toggleLoader();
      await apiV2
        .get('/lote-tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
            nome: codigo,
          },
        })
        .then((res) => {
          setLoteTanques(res.data.results);

          state.sync = true;
        });

      store.toggleLoader();
    }
    searchTanque();

    // eslint-disable-next-line
  }, [codigo, setCodigo]);

  // function reunirDados(tanqueData: TanqueData): void {
  //   tanqueData.tanque = selectedLoteTanque!.tanque.id;
  //   tanqueData.lote_tanque = selectedLoteTanque!.id;

  //   onConfirm(tanqueData);
  //   handleClose();
  // }
  const LoteTanquesDisponiveis: React.FC = () => (
    <>
      {loteTanques
        ?.sort((a: any, b: any) => {
          const textA = a.tanque.nome ? a.tanque.nome.toUpperCase() : a.tanque.codigo;
          const textB = b.tanque.nome ? b.tanque.nome.toUpperCase() : b.tanque.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((loteTanque: any) => {
   
          if (loteTanque && loteTanque.fase_lote.nome !== 'FINALIZADO') {
            const PM = loteTanque.peso_medio_atual || loteTanque.peso_medio;
            return loteTanque.estoque > 0 ? (
              <LoteTanqueInfo
                onClick={() => {
                  setTanque({ label: returnTanque(loteTanque.tanque), value: loteTanque.tanque.id.toString() });
                  setSelectedQuantidade(loteTanque.estoque);
                  setSelectedLoteTanque(loteTanque);
                  setLote(loteTanque);
                  handleClose();
                }}
                key={loteTanque.id}
              >
                <p className="left">{returnTanque(loteTanque.tanque)}</p>
                <p className="right">
                  {loteTanque.lote.codigo || loteTanque.lote.nome} ({Formatter.formatNumber(0, loteTanque.estoque)})
                </p>
                <b className="left" style={{ color: '#00C853' }}>
                  {PM ? Formatter.formatNumber(2, (PM * loteTanque.estoque) / 1000) : '00'} Kg
                </b>
                <p className="right" style={{ color: '#00C853' }}>
                  {loteTanque.fase_lote.nome}
                </p>
              </LoteTanqueInfo>
            ) : null;
          } else {
            return null;
          }
        })}
    </>
  );

  return state.sync ? (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Selecione um Tanque</ModalHeader>
      <ModalBody>
        <div
          style={{ color: '#707070', width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-between' }}
        >
          <TextField
            label="Pesquisa Pelo Nome"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
          />
        </div>
        <LoteTanquesDisponiveis />
      </ModalBody>
    </Modal>
  ) : null;
};

export default observer(SelecionarLoteTanques);
