import { Button, IconButton, Tooltip } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import MaterialTable, { Column } from 'material-table';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link, useHistory } from 'react-router-dom';
import { Conteudo, NewBody } from '../../../components/Body';
import { Flex } from '../../../components/NavBar/styles';
import Select from '../../../components/Select';
import { config } from '../../../components/Tabela';
import Formatter from '../../../services/Formatter';
import useStore from '../../../services/hooks/useStore';
import { apiMain, apiV2, aqReports } from '../../../services/utils';
import { permissoes } from '../../../services/utils/permissoes';
import ApexCharts from 'apexcharts';
const ContaCorrente: React.FC = () => {
  const store = useStore();

  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.FINANCEIRO_PAINEL_FINANCEIRO_LEITURA, history);

  const state = useLocalStore(() => ({
    conta: null as SelectRow | null,
    contas: [] as SelectRow[],
    movimentacoes: [] as ContaCorrente[],
    periodos: [] as SelectRow[],
    periodo: '',
    rows: [] as object[],
    sync: false,
  }));
  const graficoDonut = useLocalStore(() => ({
    series: [0, 0, 0],
    options: {
      labels: ['Despesas', 'Vendas', 'Outras Entradas'],
      colors: ['#E71313', '#3ECC44', '#484848'],
      chart: {
        id: 'donut',
        type: 'donut',
      },
      legend: {
        show: false,
      },
    },

    update() {
      const total = state.rows.length;
      let despesas = 0;
      let vendas = 0;
      let outras = 0;

      state.rows.forEach((row: any) => {
        switch (row.tipo_categoria) {
          case 'despesas':
            despesas += 1;
            break;
          case 'vendas':
            vendas += 1;
            break;
          case 'outras':
            outras += 1;
            break;
          default:
            break;
        }
      });

      despesas = Number(((despesas / total) * 100).toFixed(2));
      vendas = Number(((vendas / total) * 100).toFixed(2));
      outras = Number(((outras / total) * 100).toFixed(2));

      if (despesas || vendas || outras) ApexCharts.exec('donut', 'updateSeries', [despesas, vendas, outras]);
    },
  }));
  const columns: Column<object>[] = [
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      render: (rowData: any) => {
        return rowData.data;
      },
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'descricao',
      title: 'Descrição',
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'categoria',
      title: 'Categoria',
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'debito',
      title: 'Débito (R$)',
      type: 'numeric',
      cellStyle: {
        color: 'red',
      },
      render: (rowData: any) => {
        return Formatter.formatNumber(2, rowData.debito);
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'credito',
      title: 'Crédito (R$)',
      type: 'numeric',
      cellStyle: {
        color: 'green',
      },
      render: (rowData: any) => {
        return Formatter.formatNumber(2, rowData.credito);
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'saldo',
      title: 'Saldo (R$)',
      type: 'numeric',
      render: (rowData: any) => {
        return Formatter.formatNumber(2, rowData.saldo);
      },
      ...(true && ({ width: 150 } as object)),
    },
  ];

  async function sync(): Promise<void> {
    store.toggleLoader();
    const contasRequest = await apiV2.get(`/conta-bancaria/periodos-conta/`, {
      params: { prop: store.propriedade!.id },
    });

    state.contas = contasRequest.data.map((conta: any) => {
      return {
        label: conta.titulo,
        value: conta.id.toString(),
      };
    });
    state.conta = state.conta || state.contas[0];

    state.periodos = contasRequest.data
      .find((conta: any) => conta.id === Number(state.conta?.value))
      .periodos.map((periodo: any) => {
        return {
          label: periodo,
          value: periodo,
        };
      });
    state.periodo = state.periodo || state.periodos[0].value;

    const response = await aqReports.get(
      `/relatorio/${store.propriedade!.id}/conta-corrente/${Number(state.conta?.value)}`,
      {
        params: { data: state.periodo },
      },
    );
    state.rows = response.data.results;
    state.sync = true;

    graficoDonut.update();
    store.toggleLoader();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getPDF() {
    try {
      const pdf = await apiMain.get(
        `/gerador/pdf/relatorio/${store.propriedade?.id}/conta-corrente/${state.conta!.value}/`,
        {
          params: {
            data: state.periodo.split('/')[0] + '/' + parseInt(state.periodo.split('/')[1]),
            authori: store.token,
          },
        },
      );
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getCSV() {
    try {
      const pdf = await apiMain.get(
        `/gerador/csv/relatorio/${store.propriedade?.id}/conta-corrente/${state.conta!.value}/`,
        {
          params: {
            data: state.periodo.split('/')[0] + '/' + parseInt(state.periodo.split('/')[1]),
            authori: store.token,
          },
        },
      );
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
    }
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Conta Corrente'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return state.sync ? (
    <NewBody>
      <Link
        to={'/financeiro/painel/relatorio-financeiro'}
        style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
      >
        <Flex>
          <img src={'/images/returnArrow.svg'} />
          <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
        </Flex>
      </Link>
      <div style={{ fontWeight: 'bold', fontSize: '24px', height: '30px', margin: '.5rem 0' }}>Conta Corrente</div>

      <Flex
        style={{
          width: '100%',
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <div style={{ width: 180, marginRight: 15 }}>
          <Select
            placeholder="Conta"
            value={state.conta}
            options={state.contas}
            onChange={(e: any) => {
              state.conta = e;
              sync();
            }}
          />
        </div>
        <div style={{ width: 180, marginRight: 15 }}>
          {state.conta && state.periodos && (
            <Select
              placeholder="Período"
              options={state.periodos}
              value={{ label: state.periodo, value: state.periodo }}
              onChange={(e: any) => {
                state.periodo = e.value;
                sync();
              }}
            />
          )}
        </div>

        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          <Button className="blue" onClick={getCSV}>
            <Flex>
              <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                Exportar Excel
              </div>
              <img src={'/images/exportCSV.svg'} />
            </Flex>
          </Button>
          <Button className="blue" onClick={getPDF}>
            <Flex>
              <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                Exportar PDF
              </div>
              <img src={'/images/exportPDF.svg'} />
            </Flex>
          </Button>
          <Tooltip title="Sincronizar">
            <IconButton className="blue" onClick={sync}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Flex>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 300px', gap: 10 }}>
        <Conteudo style={{ padding: 0 }}>
          {state.conta && (
            <MaterialTable
              columns={columns}
              data={state.rows}
              options={{
                ...config.options,
                paging: false,
              }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </Conteudo>

        <Conteudo overflowY>
          <ReactApexChart
            options={graficoDonut.options as any}
            series={graficoDonut.series}
            type="donut"
            height="300px"
          />
        </Conteudo>
      </div>
    </NewBody>
  ) : null;
};

export default observer(ContaCorrente);
